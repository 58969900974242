import React, { Component, Fragment } from "react";
import axios from "axios";

// STYLES
import "./index.scss";

// UTILITY
import { parseString } from "../../js-utilities/parse-codes.js";

// COMPONENTS
import DefaultLayout from "../../layouts/default.js";
import ItemsRow from "./components/itemsRow";


const query_map = {
    spotlights: {slug: "spotlights", title: "Spotlights", query: "https://simplyshopsmall.com/wp-json/wp/v2/posts?_fields=jetpack_featured_media_url,date,title,id", per_page: 6},
    diy: {slug: "diy", title: "DIY Tutorials", query: "https://simplyshopsmall.com/wp-json/wp/v2/posts?categories=80&_fields=jetpack_featured_media_url,date,title,id", per_page: 3},
    reviews: {slug: "reviews", title: "Reviews", query: "https://simplyshopsmall.com/wp-json/wp/v2/posts?categories=79&_fields=jetpack_featured_media_url,date,title,id", per_page: 3},
    favorites: {slug: "favorites", title: "Our Favorite Things", query: "https://simplyshopsmall.com/wp-json/wp/v2/posts?categories=265&_fields=jetpack_featured_media_url,date,title,id", per_page: 3}
};

const load_more_limit = 15;

export default class BlogIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            spotlights: false,
            spotlights_page: 0,
            diy: false,
            diy_page: 0,
            reviews: false,
            reviews_page: 0,
            favorites: false,
            favorites_page: 0,
            loading: true
        }
    }

    load_more_callback(state, slug, set_load_more, set_loading_more) {
        let {query} = query_map[slug];
        set_loading_more(true);
        axios.get(`${query}&per_page=${load_more_limit}&page=${this.state[`${slug}_page`] + 1}`).then(res => {
            let { has_more, new_posts, new_page_number } = get_results(state, slug, res.data)
            set_load_more(has_more);
            set_loading_more(false);
            this.setState({ [slug]: new_posts, [`${slug}_page`]: new_page_number });
        })
             .catch((e) => {
                 set_loading_more(false);
             });
    };

    componentDidMount() {
        axios.get(`${query_map.spotlights.query}&per_page=${query_map.spotlights.per_page}`).then(res => {
            this.setState({ spotlights: map_blogs_to_schema(res.data), loading: false });
        })

        axios.get(`${query_map.diy.query}&per_page=${query_map.diy.per_page}`).then(res => {
            this.setState({ diy: map_blogs_to_schema(res.data) });
        })

        axios.get(`${query_map.reviews.query}&per_page=${query_map.reviews.per_page}`).then(res => {
            this.setState({ reviews: map_blogs_to_schema(res.data) });
        })

        axios.get(`${query_map.favorites.query}&per_page=${query_map.favorites.per_page}`).then(res => {
            this.setState({ favorites: map_blogs_to_schema(res.data) });
        })
    }

    render() {
        const { loading } = this.state;

        return (
            <DefaultLayout>
                <div className="blog-index-cover">
                    <div className="blog-index-cover__title">
                        <h1>Behind the Seams</h1>
                    </div>
                </div>
                <div className="container">
                    {
                        Object.keys(query_map).map((key) => {
                            return <ItemsRow key={key} title={query_map[key].title} blogs={this.state[key]} load_more_callback={(set_load_more, set_loading_more) => this.load_more_callback(this.state, key, set_load_more, set_loading_more)} />
                        })
                    }
                </div>

                <br />
                <br />
            </DefaultLayout>
        );
    }
};

function map_blogs_to_schema(blogs) {
    return blogs.map((blog, index) => {
        return {
            date: blog.date,
            picture: blog.jetpack_featured_media_url,
            title: parseString(blog.title.rendered),
            action: { text: "Read More", to: `/blog/${encodeURI( parseString(blog.title.rendered) )}/${blog.id}`}
        }
    });
}

function get_results(state, slug, data) {
    let mapped_response = map_blogs_to_schema(data);
    let has_more = (mapped_response.length === load_more_limit);
    let new_posts = state[`${slug}_page`] === 0 ? mapped_response : [...state[slug], ...mapped_response];
    let new_page_number = state[`${slug}_page`] + 1;
    return {has_more: has_more, new_posts: new_posts, new_page_number: new_page_number};
};

export const BACKEND_URL = () => process.env.REACT_APP_SML_BACKEND;
export const CLOUDFRONT_PUBLIC = () => process.env.REACT_APP_SML_CLOUDFRONT_PUBLIC;
export const STRIPE_PUBLIC_KEY = () => process.env.REACT_APP_SML_STRIPE_PUBLIC_KEY;
export const BLOG_BACKEND = () => process.env.REACT_APP_BLOG_BACKEND;


export function image_url(path) {
    switch(path) {
        case "uploading":
        case "failed":
            return path;
        case undefined:
        case false:
        case null:
            return false;
        default:
            return `${CLOUDFRONT_PUBLIC()}/${path}`;
    }
};

export const product_placeholder = `${CLOUDFRONT_PUBLIC()}/main/product-placeholder.png`;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// STYLING

// UTILITY
import { BACKEND_URL }       from "../../../env/env.js";
import { request_with_token } from "../../../context/axios_requests.js";
import { useCtxProvider } from "../../../context/context.js";

// COMPONENTS
import Spinner from "../Spinner.js";
import ShortMessage from "../../../scenes/profile/messages/ShortMessage.js";

export default function MessageElementDropdown(_props) {

    const { user, user_channel, is_connected, unread_messages } = useCtxProvider();

    const [loading, set_loading] = useState(true);
    const [messages, set_messages] = useState([]);
    const [notifications_count, set_notifications_count] = useState(unread_messages.total);
    
    useEffect(() => set_notifications_count(unread_messages.total), [unread_messages.total]);

    useEffect(() => {
        if (is_connected) {
            user_channel.push("get_last_messages")
                        .receive("ok", ({messages: last_messages}) => {
                            set_messages(last_messages);
                            set_loading(false);
                        })
                        .receive("error", () => {
                            console.log(`Error fetching last messages for user`);
                            set_loading(false);
                        });
        }
    }, [user_channel, is_connected]);

    return (
        <div className="navigation-session-messages-dropdown">
            {
                loading ?
                <Spinner /> :
                <div className="navigation-session-messages-dropdown-content">
                    {messages.map((message) => <ShortMessage key={message.chat_id} message={message} your_id={user.id} />)}
                    <hr style={{height: "1px", backgroundColor: "rgba(0,0,0,.2)", border: "0", margin: "10px auto"}} />
                    {
                        (notifications_count > 0) ?
                        <Link to="/account/messages" style={{display: "block"}} className="link-text-primary t-center">
                            {`${notifications_count} unread message${notifications_count > 1 ? "s" : ""}`}
                        </Link> :
                        ""
                    }
                    {notifications_count > 0 ?  <hr style={{height: "1px", backgroundColor: "rgba(0,0,0,.2)", border: "0", margin: "10px auto"}} /> : ""}
                    <Link to="/account/messages">
                        <button className="btn btn-black color-white fx-100">Go to Messages</button>
                    </Link>
                </div>
            }
        </div>
    );
};

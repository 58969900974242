import React from "react";

// STYLING
import "./length_tip.scss";
// UTILITY

// COMPONENTS

export default function LengthTip({ max, current_value }) {

    let current_length = current_value ? current_value.length : 0;

    return (
        <span className="length-tip">{current_length}/{max}</span>
    );
};

import React, { useEffect } from "react";

// STYLES

// UTILITY

// COMPONENTS

export default function Step1({ dispatch }) {

    useEffect(() => dispatch({type: "set_step", step: 1}), []);

    function goto_type(type) {
        dispatch({type: "select_type", value: type});
    };
    
    return (
        <div className="signup-container-step">
            <div className="signup-step-title">
                <h2><span>Step 1</span> Are you selling or buying?</h2>
            </div>
            <div className="signup-step-1-buttons">
                <div className="signup-step-1-button signup-step-button-seller" onClick={() => goto_type("seller")}>
                    <p>I'm Selling</p>
                </div>
                <div className="signup-step-1-button signup-step-button-buyer" onClick={() => goto_type("buyer")}>
                    <p>I'm Buying</p>
                </div>
            </div>
        </div>
    );
};
                

import React from "react";

// STYLING
import "./admin_user_table.scss";

// UTILITY

// COMPONENTS
import AdminUserRow from "./AdminUserRow.js";

export default function AdminUserTable({ users }) {

    return (
        <div className="admin-user-table">
            <div className="admin-user-row header-row">
                <div className="column">Name</div>
                <div className="column">Phone</div>
                <div className="column">Email</div>
                <div className="column">Is Verified</div>
                <div className="column">Account Type</div>
            </div>
            {users.map((user) => <AdminUserRow key={user.id} user={user} />)}
        </div>
    );
};

import React, { Fragment, useState } from 'react';

import Item from "./item";
import Spinner from "../../../components/global/Spinner.js";

export default function ItemsRow({ title, blogs, load_more_callback }) {

    const [load_more, set_load_more] = useState(true);
    const [loading_more, set_loading_more] = useState(false);
    
    return (
        <Fragment>
            <div className="row"><h1>{title}</h1></div>
            {!blogs ? (
                <Spinner />
            ) : (
                <div className="row">
                    {blogs.map(blog => <Item
                                           key={`${blog.title}-${blog.date}`}
                                           title={blog.title}
                                           picture={blog.picture}
                                           action={blog.action}
                                           date={blog.date}
                    />)}
                </div>
            )}
        <div className="fx-row justify-content-center align-items-center my-2">
            {
                blogs && load_more && !loading_more ?
                <button type="button" className="btn primary-gradient fx-1-0-0 mx-auto" style={{fontSize: "18px", textAlign: "center"}} onClick={() => load_more_callback(set_load_more, set_loading_more)}>Load more {title} Articles...</button> :
                loading_more ?
                <Spinner /> :
                ""
            }
        </div>
        </Fragment>
    );
};

import React from "react";
import { Link } from "react-router-dom";
// STYLING

// UTILITY
import { get_display_name } from "../../../context/user_helpers.js";

// COMPONENTS

export default function AdminUserRow({ user, history }) {

    
    
    return (
        <Link className="admin-user-row" to={`/admin/user/${user.id}`}>
            <div className="column">{get_display_name(user)}</div>
            <div className="column">{user.phone_number}</div>
            <div className="column">{user.email}</div>
            <div className="column">{user.is_verified ? "True" : "False"}</div>
            <div className="column">{user.type}</div>
        </Link>
    );
};

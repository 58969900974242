import React, { useState, useEffect }  from "react";

// STYLES
import "./index.scss";

// UTILITY
import { image_url, BACKEND_URL } from "../../../env/env.js";
import { useCtxProvider, get_category } from "../../../context/context.js";
import { get_display_name } from "../../../context/user_helpers.js";
import { short_names_map } from "../../../js-utilities/states.js";

// COMPONENTS
import DefaultLayout from "../../../layouts/default.js";
import Spinner       from "../../../components/global/Spinner.js";

import ProfileAvatar from "../../../scenes/profile/shared/ProfileAvatar.js";
import ItemFeed from "../../../components/sections/feeds/item_feed.js";
import VerifiedBadge from "../../../components/svg_elements/VerifiedBadge.js";

import ItemCard from "../../../components/sections/feeds/item_card.js";

const axios = require("axios");

export default function Public({ match: { params: { slug } }, history }) {

    const [loading, set_loading] = useState(true);
    const [request_loading, set_request_loading] = useState(false);
    const [seller, set_seller] = useState(false);
    const [products, set_products] = useState([]);
    const [seller_categories, set_seller_categories] = useState([]);
    const [products_by_category, set_products_by_category] = useState({});
    const [display_name, set_display_name] = useState("");
    const [header_style, set_header_style] = useState({});
    const [location, set_location] = useState(false);
    const [limit, set_limit] = useState(0);

    const [selected_category, set_selected_category] = useState(false);
    const [category_products_map, set_category_products_map] = useState({});

    const { categories } = useCtxProvider();

    useEffect(() => {
        if ((!seller || slug !== seller.slug) && !request_loading) {
            get_seller_page(slug, set_seller, set_products, set_limit, set_loading, set_request_loading)
        }
    }, [slug, seller, request_loading]);

    useEffect(() => {
        if (categories) {
            set_seller_categories(products.reduce((acc, { category }) => {
                let category_obj = get_category(category, categories, true);
                return acc.some(({slug}) => category_obj.slug === slug) ? acc : [...acc, category_obj];
            }, []));
        }
    }, [categories, products]);

    useEffect(() => {

        if (categories) {
            set_products_by_category(
                products.reduce((acc, product) => {
                    let category = product.category;
                    let acc_cat = acc[category];
                    let n_product = {...product, seller: seller};
                    acc[category] = acc_cat ? [...acc_cat, n_product] : [n_product];
                    return acc;
                }, {})
            )
        }
        
    }, [products, categories, seller]);

    useEffect(() => {
        if (seller) {
            set_display_name(get_display_name(seller));
            set_header_style(get_header_style(seller));
            set_location(get_location(seller));
            if (seller.slug !== slug) { history.replace(`/seller/${seller.slug}`); }
        }
    }, [seller, slug, history]);


    useEffect(() => {
        if (selected_category) {
            if (!category_products_map[selected_category.slug]) {
                let new_category_products = category_products_map;
                new_category_products[selected_category.slug] = {
                    products: products_by_category[selected_category.slug],
                    has_more: (products_by_category[selected_category.slug].length === limit),
                    loading_more: false
                };
                set_category_products_map({...new_category_products});
            } 
        } else {
            Object.keys(products_by_category).forEach((key) => {
                if (!category_products_map[key]) {
                    let new_category_products = category_products_map;
                    new_category_products[key] = {
                        products: products_by_category[key],
                        has_more: (products_by_category[key].length === limit),
                        loading_more: false
                    };
                    set_category_products_map({...new_category_products});
                } 
            });
        }
    }, [selected_category.slug, slug, seller.id, products_by_category]);

    function get_header_style({ company_cover }) {
        if (company_cover && !["uploading", "failed"].includes(company_cover)) {
            return {backgroundImage: `url(${image_url(seller.company_cover)})`};
        } else {
            return {};
        }
    };

    function get_location(seller) {
        if (!seller) { return false; }
        else {
            let full_location = seller.city ? seller.city : false;
            if (full_location && seller.state) {
                full_location = `${full_location}, ${short_names_map[seller.state]}`;
            } else if (seller.state) {
                full_location = short_names_map[seller.state];
            }
            return full_location;
        }
    };

    function get_products_for_category(seller_id, category, last_datetime, last_ids) {
        return axios({
            method: "POST",
            url: `${BACKEND_URL()}/api/v1/categories/${category}`,
            data: {
                last_datetime: last_datetime,
                except: last_ids,
                seller: seller_id
            }
        });
    };

    function load_next(category_to_load) {
        category_to_load = category_to_load || selected_category.slug;
        let { products: existing_products } = category_products_map[category_to_load];
        let new_category_products = category_products_map;
        new_category_products[category_to_load].loading_more = true;
        set_category_products_map({...new_category_products});

        let last_product = existing_products[existing_products.length - 1],
            last_datetime = last_product.inserted_at,
            last_ids;
        
        last_ids = existing_products.reduce((acc, {inserted_at, id}) => {
            if (inserted_at === last_datetime) {
                acc.push(id);
            }
            return acc
        }, []);

        get_products_for_category(seller.id, category_to_load, last_datetime, last_ids)
            .then(({data}) => {
                
                if (data.success) {
                    new_category_products[category_to_load].products = [...existing_products, ...data.products];
                    new_category_products[category_to_load].has_more = data.has_more;
                }

                new_category_products[category_to_load].loading_more = false;
                set_category_products_map({...new_category_products});
                set_products([...products, ...data.products]);
            })
            .catch((e) => {
                new_category_products[category_to_load].loading_more = false;
                set_category_products_map({...new_category_products});
            });
    };
    
    return (
        loading ?
        <Spinner classes="spinner-full" /> :
        <DefaultLayout>
            <div className="seller-cover" style={header_style}>

            </div>
            <div className="seller-categories">
                <ProfileAvatar customClassName="seller-profile-avatar" user={seller} /> 
                <div className="seller-display-name">
                    <h1>
                        {display_name}
                        <br/>
                        {
                            location ?
                            <span style={{display: "block", textTransform: "capitalize", fontSize: "0.8em"}}>
                                {location}
                            </span>
                            : ""
                        }
                    </h1>
                    {seller.is_verified ? <VerifiedBadge styles={{width: "40px", height: "40px", margin: "auto 10px"}} /> : ""}
                </div>
                <button
                    className={`seller-categories__item ${!selected_category ? "active" : ""}`}
                    onClick={(_) => set_selected_category(false)}
                >All Products</button>
                {seller_categories.map(category => <button
                                                       className={`seller-categories__item ${category.slug === selected_category.slug ? "active" : ""}`}
                                                       key={category.title}
                                                       onClick={(_) => set_selected_category(category)}
                                                   >{category.title}</button>)
                }
            </div>
            <div className="section-divider secondary-divider"></div>
            {
            selected_category ?
            <div className="products-list-container">
                <div className="products-list-top">
                    <div className="products-list-title">
                        <img className="products-list-title-icon" alt={selected_category.title} src={image_url(selected_category.image)} />
                        <h4 className="products-list-title-header">{selected_category.title}</h4>
                    </div>
                </div>
                <div className="section-divider full-divider mt-2 mb-4"/>
                <div className="products-list mb-4 mt-6 children-mx-2">
                    {
                    category_products_map[selected_category.slug] ? (
                    category_products_map[selected_category.slug].products.map((product) => {
                    return <ItemCard
                               product={product}
                               key={product.id}
                               card_type="display"
                    />
                    })) :
                    <div className="fx-column justify-content-center align-items-center mt-4">
                        <h1 style={{fontSize: "20px"}} className="my-2">No products yet available for this category!</h1>                                                                                               </div>
                    }
                </div>
                <div className="fx-row justify-content-center align-items-center m-2">
                    {
                    category_products_map[selected_category.slug] && category_products_map[selected_category.slug].has_more && !category_products_map[selected_category.slug].loading_more ?
                    <button type="button" className="btn primary-gradient fx-1-0-0 mx-auto" style={{fontSize: "18px", textAlign: "center"}} onClick={() => load_next()}>Load more products...</button> :
                    category_products_map[selected_category.slug] && category_products_map[selected_category.slug].loading_more ?
                    <Spinner /> :
                    ""
                    }
                </div>
            </div> :
        (categories ?
         Object.keys(products_by_category).map((key) => {
             let cat_products = products_by_category[key];
             let cat = get_category(key, categories, true);
             
             return (
                 <div className="products-list-container" key={key}>
                     <div className="products-list-top">
                         <div className="products-list-title">
                             <img className="products-list-title-icon" alt={cat.title} src={image_url(cat.image)} />
                             <h4 className="products-list-title-header">{cat.title}</h4>
                         </div>
                     </div>
                     <div className="products-list mb-4 mt-6 children-mx-2">
                         {cat_products.map((product) => {
                             return <ItemCard
                                        product={product}
                                        key={product.id}
                                        card_type="display"
                             />
                         })}
                     </div>
                     <div className="fx-row justify-content-center align-items-center my-2">
                         {
                             category_products_map[cat.slug] && category_products_map[cat.slug].has_more && !category_products_map[cat.slug].loading_more ?
                             <button type="button" className="btn primary-gradient fx-1-0-0 mx-auto" style={{fontSize: "18px", textAlign: "center"}} onClick={() => load_next(cat.slug)}>Load more {cat.title} products...</button> :
                             category_products_map[cat.slug] && category_products_map[cat.slug].loading_more ?
                             <Spinner /> :
                             ""
                         }
                     </div>
                     <div className="section-divider"></div>
                 </div>
             )
                 }) :
                 "")
            }
        </DefaultLayout>
    );
};


function get_seller_page(slug_or_id, set_seller, set_products, set_limit, set_loading, set_request_loading) {
    set_request_loading(true);
    set_loading(true);
    axios({
        method: "GET",
        url: `${BACKEND_URL()}/api/v1/seller/page/${slug_or_id}`
    })
        .then(({data}) => {
            if (data.success) {
                set_seller(data.seller);
                set_products(data.products);
                set_limit(data.limit)
            } else {

            }
            set_loading(false);
            set_request_loading(false);
        })
        .catch((e) => {
            set_loading(false);
            set_request_loading(false);
        });
};

import React from "react";

// STYLES
import "./index.scss";

// COMPONENTS
import DefaultLayout from "../../layouts/default.js";


export default function shippingAndSafety(_props) {

    return (
        <DefaultLayout>
          <div className="legal-content">
            <p>Making sure our marketplace isn’t just easy to use, but easy to use safely, if of the utmost importance to
              Simply Made Local.
              That’s why we offer features like phone number verification and messaging within our site so you can
              connect with confidence.</p>

            <p>&nbsp;</p>
            <h1 style={{marginBottom: '10px'}}>Tested Safety Tips</h1>
            <p>We want you to be safe, so whether you’re buying or selling, these tips will help you do it safely:</p>

            <ul>
              <li className="shippingTerms">Be careful if you receive a message from someone overseas. We are a nationwide company only
                at this time.</li>
              <li className="shippingTerms">Please report users on the site who are behaving inappropriately. Please notify us at
                support@simplymadelocal.com.</li>
              <li className="shippingTerms">Please familiarize yourself with your local government’s current restrictions, and take the proper
                precautions to protect yourself and others. For safety, and if local restrictions allow, we
                recommend meeting in a public place during daytime hours.</li>
              <li className="shippingTerms">Bring a friend with you, especially if the item you’ve arranged to purchase or sell is of high value or
                too large for one person to carry.</li>
              <li className="shippingTerms">Stick to the agreed-upon method of payment. We recommend Venmo, Paypal, or Zelle. Don’t
                accept or attempt to pay with wire transfers, cashier’s checks, personal checks, money orders, or
                prepaid debit/gift cards.</li>
              <li className="shippingTerms">Trust your gut if anything raises a red flag. If someone insists you come alone, changes the
                meeting place at the last minute, or refuses to meet in a public space, cancel the meeting.</li>
            </ul>

            <p>&nbsp;</p>
            <h1 style={{marginBottom: '10px'}}>Buying Safely</h1>
            <ul>
              <li className="shippingTerms">Be wary of significant discounts on high-ticket items with a story about the owner being overseas,
                in the military, or involved in an accident.</li>
              <li className="shippingTerms">Carefully inspect the item before paying the seller.</li>
            </ul>

            <p>&nbsp;</p>
            <h1 style={{marginBottom: '10px'}}>Selling Safely</h1>
            <ul>
              <li className="shippingTerms">Beware of common check scams, where a buyer offers to pay by check. Frequently, check
                scammers will offer more than the listed price and may ask that you return the difference
                between the check and the agreed-upon price. Unfortunately, these checks are often fraudulent.</li>
              <li className="shippingTerms">Shipping is up to your discretion, but we recommend receiving payment for shipments before
                shipping the item.</li>
              <li className="shippingTerms">If accepting cash in person, count and inspect the money while you and the buyer are present at
                the exchange.</li>
            </ul>

            <p>&nbsp;</p>
            <h1 style={{marginBottom: '10px'}}>Shipping</h1>
            <ul>
              <li className="shippingTerms"><strong>Shipping Options:</strong>The specific carrier used is determined by the individual seller from whom the
                item is being purchased.</li>
              <li className="shippingTerms"><strong>Cost of Shipping:</strong>The cost of shipping is determined by the carrier, distance, weight, and height of
                the item being shipped.</li>
              <li className="shippingTerms"><strong>Duration of Shipping:</strong> This depends on the carrier and customization. Please discuss the lead time
                for the creation of your item and the shipping method they will be using.</li>
              <li className="shippingTerms"><strong>Restrictions:</strong> Shipping is limited to the United States of America.</li>
            </ul>

          </div>
        </DefaultLayout>
    );
}

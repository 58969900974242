import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

// STYLES
import "./avatar-uploader.scss";

// UTILITY
import { image_url } from "../../env/env.js";

// COMPONENTS
import Spinner from "../../components/global/Spinner.js";

const placeholder_image = "main/empty-avatar.jpg";

export default function AvatarUploader({ upload_callback, file, multiple, classes, placeholder }) {

    const URL = window.URL || window.webkitURL;
    
    const defaults_drop = {
        onDrop: useCallback(acceptedFiles => {
            upload_callback(acceptedFiles);
        }, [upload_callback]),
        accept: "image/*",
        multiple: multiple
    };

    const [loading, set_loading] = useState(file === "uploading");
    const [preview, set_preview] = useState(decide_preview(file, placeholder));

    useEffect(() => set_preview(decide_preview(file, placeholder)), [file, placeholder]);

    useEffect(() => () => {
        if (preview && file instanceof File) { URL.revokeObjectURL(preview) };
    }, [file, preview, URL]);

    useEffect(() => set_loading(file === "uploading"), [file]);

    let style = preview ? {backgroundImage: `url(${preview})`} : {};
        
    const {getRootProps, getInputProps, isDragActive} = useDropzone(defaults_drop);
    
    return (
        loading ?
        <div className={`avatar-uploader ${classes ? classes : ""}`}>
            <Spinner />
        </div> :
        <div {...getRootProps({className: `avatar-uploader ${isDragActive ? "drag-active" : ""} ${classes ? classes : ""}`, style: style})}>
            <input {...getInputProps()} />
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80.34 80.34"><g><g><line x1="40.57" y1="19.14" x2="40.57" y2="61.14"/><line x1="19.56" y1="40.14" x2="61.57" y2="40.14"/><circle cx="40.17" cy="40.17" r="38.67"/></g></g></svg>
        </div>
    );
};

function decide_preview(file, placeholder) {
    return file ? (file instanceof File ? URL.createObjectURL(file) : image_url(file)) : (placeholder ? image_url(placeholder) : false);
};

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

// STYLING
import "./chat_thread.scss";

// UTILITY
import { useCtxProvider }       from "../../../context/context.js";
import { get_display_name }     from "../../../context/user_helpers.js";
import { image_url }            from "../../../env/env.js";

import { create_error_element } from "../../../js-utilities/error_arrays.js";

// COMPONENTS
import Spinner from "../../../components/global/Spinner.js";

import Message from "./Message.js";
import ProfileAvatar from "../shared/ProfileAvatar.js";


export default function ChatThread({ match: { params: { chat_id } } }) {

    const [loading, set_loading]   = useState(true);
    const [product, set_product]   = useState(false);
    const [seller, set_seller]     = useState(false);
    const [buyer,  set_buyer]      = useState(false);
    const [messages, set_messages] = useState([]);
    const [parties, set_parties]   = useState({});
    const [with_who, set_with_who] = useState(false);
    const [status, set_status]     = useState(false);
    const [message, set_message]   = useState("");
    const [load_more, set_load_more] = useState(false);
    const [loading_more, set_loading_more] = useState(false);
    const [sending_message, set_sending_message] = useState(false);
    
    const [sorted_messages, set_sorted_messages] = useState(sort_messages(messages));
    const { mobile, user_channel, user, is_connected, ctx_dispatch } = useCtxProvider();

    const avatarClassName = mobile ? "mobile-chat-avatar" : null
    useEffect(() => {
        if (is_connected) {
            user_channel.push("get_chat_thread", {chat_id: chat_id})
                        .receive("ok", ({chat, has_more}) => {
                            set_details(chat);
                            set_load_more(has_more);
                            set_loading(false);
                            user_channel.push("mark_thread_as_read", {chat_id: chat_id});
                            ctx_dispatch({type: "mark_as_read", value: {chat_id: chat_id}});
                        })
                        .receive("error", (error) => {
                            set_loading(false);
                        });
        }
    }, [user_channel, is_connected, ctx_dispatch, chat_id]);

    useEffect(() => {
        if (product) {
            set_status(product.published ? "available" : "unavailable")
        }
    }, [product]);


    useEffect(() => {
        if (is_connected) {
            // we use a specific eventListener and not the default channel.on..., because phoenix only allows one "on" listener and we already use the "on" on the context.js file to allow receiving messages there for the notifications, so we keep that one and when it receives it emits its own event that we can listen to here, this allows us to keep the notification flow working there and also add the message to the thread immediately here if it's for the same chat thread that is being seen.
            window.addEventListener("ctx_new_message", add_to_messages);
        }
        return () => window.removeEventListener("ctx_new_message", add_to_messages);
    }, [user_channel, is_connected, messages]);
    

    useEffect(() => set_sorted_messages(sort_messages(messages)), [messages]);

    function set_details(chat) {
        set_product(chat.product);
        set_seller(chat.seller);
        set_buyer(chat.buyer);
        set_messages(chat.messages);

        let parties_inner = {};
        parties_inner[chat.seller.id] = chat.seller;
        parties_inner.seller = chat.seller.id;
        parties_inner[chat.buyer.id] = chat.buyer;
        parties_inner.buyer = chat.buyer.id;
        set_parties({...parties_inner});
        
        set_with_who(user.id === chat.seller.id ? chat.buyer : chat.seller);
    };

    function add_to_messages({detail: msg}) {
        if (msg && msg.to_id === user.id && msg.chat_id === parseInt(chat_id)) {
            set_messages([msg, ...messages]);
            user_channel.push("mark_thread_as_read", {chat_id: chat_id});
            ctx_dispatch({type: "mark_as_read", value: msg});
        }
    };

    function send_message(event) {
        event.preventDefault();
        var error;
        if (is_connected) {
            if (message && message.length > 0) {
                set_sending_message(true);
                let payload = {msg: message, product_id: product.id, in_chat: true};
                
                if (with_who.id === seller.id) {
                    payload.seller_id = seller.id;
                } else if (with_who.id === buyer.id) {
                    payload.buyer_id = buyer.id;
                }
                
                
                user_channel.push("send_message", payload)
                            .receive("ok", (message) => {
                                set_messages([message, ...messages]);
                                set_message("");
                                set_sending_message(false);
                                
                            }).receive("error", (payload) => {
                                if (payload.errors) {
                                    payload.errors.forEach((e) => {
                                        e = create_error_element(e);
                                        ctx_dispatch({type: "show_error", value: e, dispatcher: ctx_dispatch});
                                    });
                                } else {
                                    error = create_error_element({msg: "There was a problem sending your message, please try again in a while."});
                                    ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
                                }
                                set_sending_message(false);
                            }).receive("timeout", () => {
                                error = create_error_element({msg: "Your request is taking to long, try to refresh this page."});
                                ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
                                set_sending_message(false);
                            });
            }
        } else {
            error = create_error_element({msg: "It seems you aren't connected, please refresh this page or try again in a while."});
            ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
        }
    };

    function sort_messages(messages) {
        return [...messages].sort((a, b) => {
            return (a.inserted_at > b.inserted_at) ? 1 : (b.inserted_at > a.inserted_at ? -1 : 0);
        });
    };

    function load_previous() {
        let last_id = sorted_messages[0].id;
        set_loading_more(true);
        user_channel.push("get_chat_previous", {chat_id: chat_id, last_id: last_id})
                    .receive("ok", ({messages: previous_messages, has_more}) => {
                        set_messages([...previous_messages, ...messages]);
                        set_load_more(has_more);
                        set_loading_more(false);
                    })
                    .receive("error", (error) => {
                        set_loading_more(false);
                    });
    };
    
    return (
        loading ?
        <Spinner classes="spinner-full" /> :
        <div className="chat">
        <ScrollToTop />

            <div className="chat__header">
                <h1>Talking with <span className="bold">{get_display_name(with_who)}</span></h1>
            </div>

            <div className="section-divider"></div>
            <br />

            <div className="chat__product">
                <div className="chat__product__seller">
                    <div className="chat__product__seller__icon">
                        <ProfileAvatar customClassName={avatarClassName} user={seller} />
                    </div>
                    <div className="chat__product__seller__name">
                        <h3>{get_display_name(seller)}</h3>
                    </div>
                </div>

                <Link to={`/product/${product.id}`} className="chat__product__item">
                <div className="chat__product__item__container">

                    <div className="chat__product__item__image">
                        <img alt="Product" src={image_url(product.main_image)} />
                    </div>
                    <div className="chat__product__item__description">
                        <p className="chat__product__item__description__title">
                            {product.title}
                        </p>
                        <p className="chat__product__item__description__price">
                            <span className="currency">$</span>{(product.price / 100).toFixed(2)}
                        </p>
                    </div>
                    </div>

                </Link>

                <div className="chat__product__status">
                    <div className={`chat__product__status__value ${status}`}>{status}</div>
                </div>
            </div>

            <div className="chat__messages" id="chat__messages">
                {
                    load_more && !loading_more ?
                    <button type="button" className="link color-secondary mx-auto chat-message-row" style={{fontSize: "18px", textAlign: "center"}} onClick={() => load_previous()}>Load previous messages...</button> :
                    loading_more ?
                    <Spinner /> :
                    "" 
                }
                {sorted_messages.map((msg) => <Message key={msg.id} message={msg} user={user} parties={parties} />)}

            </div>
            <form className="send-message-field" onSubmit={send_message}>
                <ProfileAvatar user={user} />
                <button type="submit" className="btn primary-gradient btn-size-normal ml-auto send-message-button" disabled={sending_message}>Send</button>
                <textarea
                    value={message}
                    autoFocus={true}
                    onChange={(event) => set_message(event.target.value) }
                    placeholder={'Send a message'}
                    className="send-message-textarea"
                />
            </form>
        </div>
    );
};

function ScrollToTop() {
  var objDiv = document.getElementById("chat__messages"); // scroll to the bottom of the chat messages div
  if (objDiv) {
    objDiv.scrollTop = objDiv.scrollHeight;
  }
  return null;
}

import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

// STYLES
import "./product_image_uploader.scss";

// UTILITY
import { image_url } from "../../env/env.js";

// COMPONENTS
import Spinner from "../../components/global/Spinner.js";

const placeholder_image = "main/product-placeholder.png";

export default function ProductImageUploader({ upload_callback, delete_callback, id, file_type, file, multiple, classes, children, placeholder }) {

    const URL = window.URL || window.webkitURL;
    
    const defaults_drop = {
        onDrop: useCallback(acceptedFiles => {
            upload_callback(file_type, acceptedFiles[0]);
        }, [upload_callback, file_type]),
        accept: "image/*",
        multiple: multiple
    };

    const [loading, set_loading] = useState(file === "uploading");
    const [has_delete, set_has_delete] = useState(decide_delete(delete_callback, file, id));
    const [preview, set_preview] = useState(decide_preview(file, placeholder));

    useEffect(() => set_preview(decide_preview(file, placeholder)), [file, placeholder]);

    useEffect(() => {
        if (preview && file instanceof File) { return () => URL.revokeObjectURL(preview); };
        
    }, [file, preview, URL]);

    useEffect(() => set_loading(file === "uploading"), [file]);

    useEffect(() => set_has_delete(decide_delete(delete_callback, file, id)), [delete_callback, file, id]);
    
    const {getRootProps, getInputProps, isDragActive} = useDropzone(defaults_drop);
    
    return (
        loading ?
        <div className={`product-image-uploader ${classes ? classes : ""}`}>
            <Spinner />
        </div> :
        <div {...getRootProps({className: `product-image-uploader ${isDragActive ? "drag-active" : ""} ${classes ? classes : ""}`, style: {backgroundImage: `url('${preview}')`}})}>
            <input {...getInputProps()} />
            {children}
            {
                has_delete ?
                <button type="button" className="input-clear" onClick={(event) => {
                    event.stopPropagation();
                    delete_callback(id);
                }} /> :
                ""
            }
        </div>
    );
};

function decide_delete(callback, file, id) {
    return id && callback && file && !(file instanceof File) && file !== "uploading";
};


function decide_preview(file, placeholder) {
    return file ? (file instanceof File ? URL.createObjectURL(file) : image_url(file)) : (placeholder ? placeholder : image_url(placeholder_image));
};

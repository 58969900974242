import React from "react";
import { Link } from "react-router-dom";

// STYLES
import "./item_card.scss";

// UTILITY
import { CLOUDFRONT_PUBLIC } from "../../../env/env.js";

import { get_display_name } from "../../../context/user_helpers.js";
import { format_price } from "../../../js-utilities/currency.js";
import { useCtxProvider }    from "../../../context/context.js";
import { font_sizing }       from "../../../js-utilities/text_helpers.js";

// COMPONENTS
import ProfileAvatar from "../../../scenes/profile/shared/ProfileAvatar.js";
import DoubleArrow from "../../svg_elements/DoubleArrow.js";
import VerifiedBadge from "../../svg_elements/VerifiedBadge.js";

export default function ItemCard({ product, card_type, unfavorite_callback, toggle_contact}) {

    let {seller, main_image, title, id, price, slug} = product;

    const { user, history, mobile } = useCtxProvider();

    let style_font_size_name = {fontSize: `${font_sizing(14, 28, get_display_name(seller))}px`}

    return (
        <div className="item-card">
            <Link to={`/seller/${seller.slug}`}>
                <div className="item-card-seller">
                    <ProfileAvatar user={seller} />
                    <p className="item-card-seller-name" style={style_font_size_name}>{get_display_name(seller)}</p>
                    {seller.is_verified ? <VerifiedBadge /> : ""}
                </div>
            </Link>
            <Link to={`/product/${slug}`} target="_blank">
                <div className="item-card-image">
                    <img alt={title} src={`${CLOUDFRONT_PUBLIC()}/${main_image}`} />
                </div>
                <div className="item-card-info">
                    <h6 className="item-card-info-title">
                        {title}
                    </h6>
                    <p className="item-card-info-price">
                        <span className="currency-sym">$</span> {format_price(price)}
                    </p>
                </div>
            </Link>
            <div className="item-card-contact-footer">
                {
                    card_type === "favorite" ?
                    <button className="btn primary-gradient" onClick={() => unfavorite_callback(id) }>Unfavorite</button> :
                    ""
                }
                <button type="button" className="color-primary" onClick={(_) => toggle_contact(product, user, history)}>
                    Contact Seller
                    <DoubleArrow />
                </button>
            </div>
        </div>
    );
};

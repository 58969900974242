import React, { useEffect, useState } from "react";


// UTILITY
import { BACKEND_URL } from "../../../env/env.js";

import { create_error_element, response_error } from "../../../js-utilities/error_arrays.js";
import { check_requirements, requirements } from "../shared_account_details.js";
import { useCtxProvider } from "../../../context/context.js";


// COMPONENTS
import Spinner from "../../../components/global/Spinner.js";

// STRIPE SPECIFIC
import {
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
    injectStripe
} from "react-stripe-elements";

const axios = require("axios");

function StripeForm({ dispatch, state, stripe }) {

    const [loading, set_loading] = useState(false);

    const [expiry_ready, set_expiry_ready] = useState(false);
    const [number_ready, set_number_ready] = useState(false);
    const [cvc_ready, set_cvc_ready] = useState(false);

    const [expiry_complete, set_expiry_complete] = useState(false);
    const [number_complete, set_number_complete] = useState(false);
    const [cvc_complete, set_cvc_complete] = useState(false);

    const { ctx_dispatch } = useCtxProvider();
    

    function handle_change(event) {
        switch(event.elementType) {
            case "cardNumber":
                set_number_complete(event.complete);
                return;
            case "cardExpiry":
                set_expiry_complete(event.complete);
                return;
            case "cardCvc":
                set_cvc_complete(event.complete);
                return;
            default:
                return;
        };
    };

    function handle_ready(event) {
        switch(event._componentName) {
            case "cardExpiry":
                set_expiry_ready(true);
                return;
            case "cardNumber":
                set_number_ready(true);
                return;
            case "cardCvc":
                set_cvc_ready(true);
                return;
            default:
                return;
        };
    };

    function handle_submit(event) {
        event.preventDefault();
        set_loading(true);
        try_payment_flow(dispatch, state, stripe, ctx_dispatch, set_loading);
    };

    useEffect(() => {
        if (!state.cc_name) {
            dispatch({type: "set_field", field: "cc_name", value: `${state.first_name} ${state.last_name}`.trim()});
        }
    }, []);


    const styling = {
        style: {
            base: {
                fontSize: "24px",
                color: "#424770",
                fontFamily: "Zilla Slab, Arial",
                '::placeholder': {
                    color: '#cecee7',
                }
            },
            invalid: {
                color: "#ff2100",
            },
        },
    };
    
    return (
        <form className="signup-container-form stripe-form mt-4" onSubmit={handle_submit}>
            {loading || !expiry_ready || !number_ready || !cvc_ready ? <Spinner classes="spinner-full" /> : ""}
            <p className="signup-label my-2 text-center" style={{fontWeight: 300}}>Enter your payment information to begin your subscription.</p>
            <div className="fx-column">
                <div className="fx-row justify-content-between">
                    <input
                        type="text"
                        id="cc-name"
                        name="cc-name"
                        className="text-center"
                        autoFocus={true}
                        autoComplete="cc-name"
                        value={state.cc_name}
                        onChange={(event) =>  dispatch({type: "set_field", field: "cc_name", value: event.target.value})}
                        placeholder="Name on Card"
                        required
                    />
                    <label className="card-number">
                        Card number
                        <CardNumberElement
                            onChange={handle_change}
                            onReady={handle_ready}
                            {...styling}
                        />
                    </label>
                    
                    <label className="card-expiry">
                        Expiration date
                        <CardExpiryElement
                            onChange={handle_change}
                            onReady={handle_ready}
                            {...styling}
                        />
                    </label>
                    <label className="card-cvc">
                        CVC
                        <CardCvcElement
                            onChange={handle_change}
                            onReady={handle_ready}
                            {...styling}
                        />
                    </label>
                </div>
                <p style={{fontSize: "12px", marginTop: "-20px", marginLeft: "5px"}}>powered by Stripe</p>
                <div className="fx-row">
                    <button
                        type="submit"
                        className="btn btn-size-large btn-len-normal primary-gradient mx-auto"
                        disabled={!expiry_complete || !number_complete || !cvc_complete || !state.cc_name}
                    >Pay Now</button>
                </div>
            </div>
        </form>
    );
};

const InjectedCheckoutForm = injectStripe(StripeForm);

export default InjectedCheckoutForm;


function try_payment_flow(dispatch, state, stripe, ctx_dispatch, set_loading) {
    let [valid, errors] = check_requirements(requirements().required_fields.seller, state, false);

    if (valid) {
        stripe.createToken({type: "card", name: state.cc_name}).then((result) => {
            if (result.token) {
                let valid_fields = requirements().valid_fields.seller;
                let form_data = new FormData();

                valid_fields.forEach((key) => {
                    form_data.append(key, state[key]);
                });
                
                form_data.set("token", JSON.stringify(result.token));
                form_data.set("type", "seller");

                if (state.coupon) { form_data.set("coupon", state.coupon); }
                
                axios({
                    method: "POST",
                    url: `${BACKEND_URL()}/api/v1/payments/subscription`,
                    data: form_data,
                    headers: {"Content-Type": "multipart/form-data"}
                }).then(({data}) => {
                    
                    if (data.success) {
                        ctx_dispatch({type: "signin", value: data});
                        dispatch({type: "set_step", step: "finished", account_type: "seller"});
                        
                    } else if (data.errors) {
                        
                        data.errors.forEach((error) => {
                            error = response_error(error);
                            ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
                        });
                        set_loading(false);
                    }
                });
            } else if (result.error) {
                
                let stripe_error = create_error_element({msg: result.error.message});
                ctx_dispatch({type: "show_error", value: stripe_error, dispatcher: ctx_dispatch});
                set_loading(false)
            }
        });
    } else {
        errors.forEach((error) => {
            ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
            set_loading(false);
            return false;
        });
    }
};


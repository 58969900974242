export function create_error_element({field, msg, ttl, id}) {
    id = id ? id : `${new Date().getTime()}-${generate_random(0, 1000)}`;
    ttl = ttl === "no_ttl" ? false : (ttl ? ttl : 5000);
    
    return {
        msg: msg,
        ttl: ttl,
        id: id,
        field: field
    };
};


export function add_error_to(error_array, error, field, dispatcher) {
    error_array.push(error);
    if (error.ttl) { 
        setTimeout(() => {
            dispatcher({type: "remove_error", field: field, id: error.id});
        }, error.ttl);
    }
    return error_array;
};

export function remove_error_from(error_array, id) {
    return error_array.filter((element) => {
        return element.id !== id;
    });
};

export function response_error({field, msg}) {
    console.log("error", field, msg);
    return create_error_element({msg: msg, ttl: 7500, field: field});
};

function generate_random(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
};



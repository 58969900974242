import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// STYLES
import "./index.scss";


// UTILITY
import { BACKEND_URL } from "../../env/env.js";
import { useCtxProvider } from "../../context/context.js";
import { response_error, create_error_element } from "../../js-utilities/error_arrays.js";

// COMPONENTS
import Logo from "../../components/global/Logo.js";
import Spinner from "../../components/global/Spinner.js";
import { request_with_token } from "../../context/axios_requests.js";


const axios = require("axios");

export default function Login({ history }) {

    const [phone_number, set_phone_number] = useState("");
    const [remember_me, set_remember_me] = useState(false);
    const [code, set_code] = useState("");

    const [step, set_step] = useState("phone_number");

    const [temp_token, set_temp_token] = useState('');

    const [loading, set_loading] = useState(false);

    const { user, session_token, ctx_dispatch } = useCtxProvider();

    //email login
    const [ email, set_email ] = useState("");
    const [ password, set_password ] = useState("");

    function toggle_remember_me() {
        set_remember_me(!remember_me)
    }

    useEffect(() => {
        if (user) {
            history.push("/account/profile");
        }
    }, [user, history]);

    function handle_phone_submit(event) {
        event.preventDefault();
        set_loading(true);

        axios({
            method: "POST",
            url: `${BACKEND_URL()}/api/v1/accounts/login/start/phone_number`,
            data: {phone_number: phone_number}
        }).then(({data}) => {

            if (data.success) {
                set_step("verification");
            } else if (data.errors) {
                data.errors.map((error) => {
                    error = response_error(error);
                    ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
                    set_loading(false);
                    return null;
                });
            }
            set_loading(false);
        }).catch((e) => {
            let error = create_error_element({msg: "Unable to process your request", ttl: 5000});
            ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
            set_loading(false);
        });

    };

    function handle_verification_submit(event) {
        event.preventDefault();
        set_loading(true);

        axios({
            method: "POST",
            url: `${BACKEND_URL()}/api/v1/accounts/login/check/phone_number`,
            data: {phone_number, code},
        }).then(({data}) => {
            if (data.success) {
                if(data.has_password){
                    data['remember_me'] = remember_me;
                    ctx_dispatch({type: "signin", value: data});
                    history.push("/account/profile");
                }else{
                    set_temp_token(data.token)
                    set_step('verification_confirmed')
                }
                set_loading(false);
            } else {
                if (data.errors) {
                    data.errors.map((error) => {
                        error = response_error(error);
                        ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
                        return null;
                    });
                }
                ctx_dispatch({type: "signin", value: {user: false, token: false}});
                set_loading(false);
            }
        }).catch((e) => {
            let error = create_error_element({msg: "Unable to process your request", ttl: 5000});
            ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
            ctx_dispatch({type: "signin", value: {user: false, token: false}});
            set_loading(false);
        });
    };

    function handle_password_update_submit(event) {
        event.preventDefault();
        set_loading(true);

        axios({
            method: "POST",
            url: `${BACKEND_URL()}/api/v1/accounts/update_password`,
            data: {password, email},
            headers: { Authorization: `Bearer ${temp_token}` }
        }).then(({data}) => {
            if (data.success) {
                data['remember_me'] = remember_me;
                ctx_dispatch({type: "signin", value: data});
                history.push("/account/profile");
                set_loading(false);
            } else {
                if (data.errors) {
                    data.errors.map((error) => {
                        error = response_error(error);
                        ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
                        return null;
                    });
                }
                ctx_dispatch({type: "signin", value: {user: false, token: false}});
                set_loading(false);
            }
        }).catch((e) => {
            let error = create_error_element({msg: "Unable to process your request", ttl: 5000});
            ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
            ctx_dispatch({type: "signin", value: {user: false, token: false}});
            set_loading(false);
        });
    };

    function handle_email_login_submit(event) {
        event.preventDefault();
        if (email == '' || password == ''){

            let error = create_error_element({msg: `${email == ''? 'Email' : 'Password'} can't be  empty!`, ttl: 5000});
            ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
            return
        }
        set_loading(true);

        axios({
            method: "POST",
            url: `${BACKEND_URL()}/api/v1/accounts/login/email_login`,
            data: {email, password}
        }).then(({data}) => {
            if (data.success) {
                data['remember_me'] = remember_me;
                ctx_dispatch({type: "signin", value: data});
                history.push("/account/profile");

            } else {
                if (data.errors) {
                    data.errors.map((error) => {
                        error = response_error(error);
                        ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
                        return null;
                    });
                }
                ctx_dispatch({type: "signin", value: {user: false, token: false}});
                set_loading(false);
            }
        }).catch((e) => {
            let error = create_error_element({msg: "Unable to process your request", ttl: 5000});
            ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
            ctx_dispatch({type: "signin", value: {user: false, token: false}});
            set_loading(false);
        });
    };

    return (
      <div className="login-container">
          {loading ? (<Spinner classes="spinner-full" />) : ""}
          <div className="login-container-form-wrapper">
              <div className="login-container-form-inner">
                  <div className="login-logo mb-8">
                      <Link to="/">
                          <Logo width="100%" height="auto" />
                      </Link>
                  </div>
                  <p className="label-title mb-1">Login</p>
                  {step === "phone_number" &&
                  <form className="login-container-form mb-4" onSubmit={handle_phone_submit}>
                      <input
                        type="text"
                        id="telephone"
                        name="telephone"
                        autoFocus={true}
                        autoComplete="telephone"
                        value={phone_number}
                        onChange={(event) => set_phone_number(event.target.value)}
                        placeholder="Telephone Number"
                      />
                      <div className="login-contain-form__remember-me">
                          <input
                            type="checkbox"
                            name="remember-me"
                            id="remember-me"
                            className="login-contain-form__remember-me__checkbox"
                            onChange={toggle_remember_me}
                          />
                          <label htmlFor="remember-me" className="login-contain-form__remember-me__label">Remember
                              Me</label>
                      </div>
                      <input type="submit" value="Login" className="primary-gradient btn btn-len-small"/>

                  </form>
                  }
                  { step === 'verification' &&
                  <div>
                      <p>If that phone is registered with us you'll soon receive a message.</p>
                      <p>Enter the code you received to login</p>
                      <form className="login-container-form mb-4" onSubmit={handle_verification_submit}>
                          <input
                            type="text"
                            id="code"
                            name="code"
                            autoFocus={true}
                            value={code}
                            onChange={(event) => set_code(event.target.value)}
                            placeholder="6-Digit Verification code"
                          />
                          <input type="submit" value="Confirm" className="primary-gradient btn btn-len-small"/>
                      </form>
                      <button
                        type="button"
                        className="btn primary-gradient"
                        onClick={(event) => set_step("phone_number") }
                      >Back</button>
                  </div>
                  }
                  { step === 'verification_confirmed' &&
                  <div>
                      <p>As a part of our new login system please enter your email & password.</p>
                      <p>Old login system is going away soon.</p>
                      <form className="login-container-form mb-4" onSubmit={handle_password_update_submit}>
                          <input
                            type="text"
                            id="email"
                            name="email"
                            autoFocus={true}
                            value={email}
                            onChange={(event) => set_email(event.target.value)}
                            placeholder="Email"
                          />
                          <input
                            type="password"
                            id="password"
                            name="password"
                            autoFocus={true}
                            value={password}
                            onChange={(event) => set_password(event.target.value)}
                            placeholder="Password"
                          />
                          <input type="submit" value="Confirm" className="primary-gradient btn btn-len-small"/>
                      </form>
                      <button
                        type="button"
                        className="btn primary-gradient"
                        onClick={(event) => set_step("phone_number") }
                      >Back</button>
                  </div>
                  }
              </div>
          </div>

          { step === 'phone_number' &&
          <div className="login-container-form-wrapper">
              <div style={{marginTop: '35wh'}} className="login-container-form-inner">
                  <div  className="login-logo mb-8">
                  </div>
                  <p className="label-title mb-1">Login With Email</p>
                  <form className="login-container-form mb-4" onSubmit={handle_email_login_submit}>
                      <input
                        type="email"
                        id="email"
                        name="telephone"
                        autoFocus={true}
                        autoComplete="email"
                        value={email}
                        onChange={(event) => set_email(event.target.value)}
                        placeholder="Email"
                      />

                      <input
                        type="password"
                        id="password"
                        name="password"
                        value={password}
                        onChange={(event) => set_password(event.target.value)}
                        placeholder="Password"
                      />
                      <div>
                          <Link style={{float: 'right'}} className="color-primary" to="/forget-password">Forget Password?</Link>
                      </div>
                      <div className="login-contain-form__remember-me">
                          <input
                            type="checkbox"
                            name="remember-me"
                            id="remember-me"
                            className="login-contain-form__remember-me__checkbox"
                            onChange={toggle_remember_me}
                          />
                          <label htmlFor="remember-me" className="login-contain-form__remember-me__label">Remember Me</label>
                      </div>
                      <input type="submit" value="Login" className="primary-gradient btn btn-len-small"/>

                  </form>
              </div>
          </div>
          }

          <div style={{zIndex: 9}} className="login-container-cta">
              <div className="login-cta-element">
                  <h2 className="login-cta-title">Begin selling unique handmade goods and crafts locally.</h2>
                  <ul className="my-3 login-cta-list">
                      <li><span className="check-marker">Find new customers</span></li>
                      <li><span className="check-marker">Join a community of entrepreneurs</span></li>
                      <li><span className="check-marker">Let us promote you</span></li>
                  </ul>
                  <Link className="btn btn-size-normal btn-len-normal btn-white color-primary mx-auto" to="/signup">Sign Up</Link>
              </div>
          </div>
      </div>
    );
};

export function convert_file(file, quality, file_type, max_side, minimums) {
    
    return get_orientation(file)
        .then((orientation) => {
            let img_url = URL.createObjectURL(file);
            let img = new Image();
            return new Promise((resolve, reject) => {
                
                img.onload = () => {
                    let orientation_keep = orientation_remains(orientation);
                    if (minimums) {
                        // if we pass any minimums as an array [width, height] then we test those to
                        let [width, height] = minimums;
                        
                        if (orientation_keep) {
                            
                            if (img.width < width) {
                                reject({error: "min_size", side: "width", minimum: width});
                            } else if (img.height < height) {
                                reject({error: "min_size", side: "height", minimum: height});
                            }
                        } else {
                            if (img.height < width) {
                                reject({error: "min_size", side: "width", minimum: width});
                            } else if (img.width < height) {
                                reject({error: "min_size", side: "height", minimum: height});
                            }
                        }
                        
                    } 
                    let canvas = document.createElement("canvas");
                    
                    // set the defaults to use if the image is on the longest side <= max_side
                    let ratio = 1;
                    
                    let canvas_dimensions = {
                        width: img.width,
                        height: img.height
                    };
                    
                    if (img.width > max_side || img.height > max_side) {
                        // if any of the sides is bigger than max_side, define which one is bigger
                        // calculate the new sizes keeping proportion
                        // calculate the ratio
                        let [main, other] = img.width > img.height ? ["width", "height"] : ["height", "width"];
                        
                        let resized_other = max_side * img[other] / img[main];
                        
                        canvas_dimensions[main] = max_side;
                        canvas_dimensions[other] = resized_other
                        
                        ratio = max_side / img[main];
                    }
                    
                    let { width, height } = canvas_dimensions;
                    
                    canvas.width = width;
                    canvas.height = height;
                    
                    let context = canvas.getContext("2d");
                    context.fillStyle = "white";
                    context.fillRect(0, 0, width, height);
                    
                    switch(orientation) {
                        case 2:
                            context.transform(-1, 0, 0, 1, width, 0);
                            break;
                        case 3:
                            context.transform(-1, 0, 0, -1, width, height);
                            break;
                        case 4:
                            context.transform(1, 0, 0, -1, 0, height);
                            break;
                        case 5:
                            context.transform(0, 1, 1, 0, 0, 0);
                            break;
                        case 6:
                            context.transform(0, 1, -1, 0, height, 0);
                            break;
                        case 7:
                            context.transform(0, -1, -1, 0, height, width);
                            break;
                        case 8:
                            context.transform(0, -1, 1, 0, 0, width);
                            break;
                        default:
                            break;
                    }
                    
                    context.drawImage(img, 0, 0, width, height);
                    context.scale(ratio, ratio);
                    URL.revokeObjectURL(img_url);
                    resolve(canvas);
                    
                };
                img.onerror = (e) => reject(e);
                img.src = img_url;
            });
        });
};


// ripped off from https://stackoverflow.com/a/32490603/5113690 and converted to promise version
export function get_orientation(file) {
    
    return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.onload = function(e) {

            var view = new DataView(e.target.result);
            if (view.getUint16(0, false) != 0xFFD8)
            {
                resolve(-2);
            }
            var length = view.byteLength, offset = 2;
            while (offset < length) 
            {
                if (view.getUint16(offset+2, false) <= 8) resolve(-1);
                var marker = view.getUint16(offset, false);
                offset += 2;
                if (marker == 0xFFE1) 
                {
                    if (view.getUint32(offset += 2, false) != 0x45786966) 
                    {
                        resolve(-1);
                    }

                    var little = view.getUint16(offset += 6, false) == 0x4949;
                    offset += view.getUint32(offset + 4, little);
                    var tags = view.getUint16(offset, little);
                    offset += 2;
                    for (var i = 0; i < tags; i++)
                    {
                        if (view.getUint16(offset + (i * 12), little) == 0x0112)
                        {
                            resolve(view.getUint16(offset + (i * 12) + 8, little));
                        }
                    }
                }
                else if ((marker & 0xFF00) != 0xFF00)
                {
                    break;
                }
                else
                { 
                    offset += view.getUint16(offset, false);
                }
            }
            resolve(-1);
        };
        reader.readAsArrayBuffer(file);
    });
};


// this is just to understand if the image has the minimums when a minimum is passed, if the image is just going to be flipped then it makes no sense as the width and height will still be the same (just flipped) it only matter when the image will be rotated since then width will be height and height will be width
function orientation_remains(orientation) {
    switch(orientation) {
        case 6:
        case 8:
        case 5:
        case 7:
            return false;
        default:
            return true;
    };
};

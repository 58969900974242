import React from "react"

import { Link } from "react-router-dom";
import { FaFacebookF, FaInstagram, FaPinterestP, FaYoutube, FaTwitter } from 'react-icons/fa';

// STYLES
import "./footer.scss";

// UTILITY
import { useCtxProvider } from "../../context/context.js";

// COMPONENTS
import Logo from "../global/Logo.js";

const FACEBOOK_LINK = "https://www.facebook.com/wearesimplymadelocal/";
const INSTAGRAM_LINK = "https://www.instagram.com/simplymadelocal/";
const PINTEREST_LINK = "https://www.pinterest.com/art9438/";
const YOUTUBE_LINK = "https://www.youtube.com/channel/UCyeF3ByyTYT2Jv9TQVg_xaQ?view_as=subscriber";
const TWITTER_LINK = "https://twitter.com/simplymadelocal";

export default function Footer(_props) {

    /* TO REMOVE after discussing the number of categories, etc
    const number_of_categories_per_section = Math.floor(default_categories.length / 2);
    const first_section_cats = default_categories.slice(0, number_of_categories_per_section);
    const second_section_cats = default_categories.slice(number_of_categories_per_section);
    */

    const { categories, mobile, user } = useCtxProvider();

    return (
      mobile && user ?
      null
      :
        <footer className="footer">
            <div className="footer-main">
                <div className="footer-section footer-section-first">
                    <Logo width="90%" height="auto" />
                    <Link to="/about">About Simply Made Local</Link>
                    <Link to="/how-it-works">How It Works</Link>
                    <Link to="/contact">Contact Us</Link>
                    <Link to="/faqs">FAQs</Link>
                    <Link to="/blog">Blog</Link>
                    <Link to="/shipping-and-safety">Shipping & Safety</Link>
                </div>
                <div className="footer-section justify-content-center align-items-center">
                    <p className="footer-section-title">Shop Local Collections</p>
                </div>
                <div className="footer-section footer-section-links">
                    {categories ? categories.sort((a, b) => a.title > b.title ? 1 : -1).map(({title, slug}) => {
                        return <Link to={`/categories/${slug}`} key={slug} className="my-1">{title}</Link>;
                    }) : ""}
                </div>
            </div>
            <div className="footer-social-links-container">
              <a href={FACEBOOK_LINK} target="_blank" className="footer-social-link">
                <FaFacebookF style={{height: 21, width: 21, color: 'white'}} />
              </a>
              <a href={INSTAGRAM_LINK} target="_blank" className="footer-social-link">
                <FaInstagram style={{height: 24, width: 24, color: 'white'}} />
              </a>
              <a href={PINTEREST_LINK} target="_blank" className="footer-social-link">
                <FaPinterestP style={{height: 24, width: 24, color: 'white'}} />
              </a>
              <a href={YOUTUBE_LINK} target="_blank" className="footer-social-link footer-social-link__youtube">
                <FaYoutube style={{height: 28, width: 28, color: 'white'}} />
              </a>
              <a href={TWITTER_LINK} target="_blank" className="footer-social-link">
                <FaTwitter style={{height: 21, width: 21, color: 'white'}} />
              </a>
            </div>
            <div className="footer-notice">
                <p>© Copyright 2020 Simply Made Local</p>
                <Link to="/terms-of-service">Terms of Service</Link>
                <Link to="/privacy-policy">Privacy Policy</Link>
            </div>
        </footer>
    );
}

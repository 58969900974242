import React from "react";
import { Link } from "react-router-dom";

import { buyer_success_image, seller_success_image } from "./base64_images.js";

// STYLING

// UTILITY

// COMPONENTS

export default function Finished({ dispatch, state }) {

    var image, text;
    
    if (state.type === "buyer") {
        image = buyer_success_image;
        text = "You're ready to buy!";
    } else {
        image = seller_success_image;
        text = "You're ready to start selling.";;
    }

    return (
        <div className="signup-container-step bg-white">
            <div className="signup-step-title color-primary">
                <h2>All Done.</h2>
            </div>
            <div className="signup-finished-container">
                <p>{text}</p>
                <img src={image} alt="Success" />
                <Link className="btn primary-gradient" to="/account/profile" >Go to Profile</Link>
            </div>
        </div>
    );
};

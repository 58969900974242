import React, { useReducer, useEffect, useState } from "react";
import { FaTrashAlt, FaSave, FaHandPaper, Facanc } from 'react-icons/fa';
import { TiCancel } from "react-icons/ti";

// STYLING
import "./edit_listing.scss";

// UTILITY
import { useCtxProvider, get_category, get_subcategory } from "../../../context/context.js";
import { create_error_element } from "../../../js-utilities/error_arrays.js";
import { create_notice_element } from "../../../js-utilities/notice_arrays.js";

import {
    empty_start_ctx,
    listing_reducer,
    number_of_placeholders,
    maybe_upload_image,
    valid_fields,
    max_length_title,
    max_length_subtitle,
    max_length_description
} from "./edit_listing/shared.js";

// COMPONENTS
import Spinner from "../../../components/global/Spinner.js";
import ProductImageUploader from "../../../components/upload/ProductImageUploader.js";

import OptionSection from "./edit_listing/OptionSection.js";
import LengthTip     from "../../../components/inputs/LengthTip.js";


export default function EditListing({ match: { params: { slug } }, history }) {

    const [loading, set_loading] = useState(true);
    const [joined, set_joined] = useState(false);
    const [show_delete, set_show_delete] = useState(false);
    const [subcategories_select, set_subcategories_select] = useState(false);
    
    const [ctx, dispatch] = useReducer(listing_reducer, {...empty_start_ctx, images: [...number_of_placeholders(8)]});
    

    const { categories, subcategories, ctx_dispatch, user_channel, session_token } = useCtxProvider();

    const {
        id,
        ready,
        main_image,
        images,
        title,
        subtitle,
        description,
        options,
        price,
        published,
        subcategories: subcategory,
        categories: category,
    } = ctx;

    // the `joined` should be moved to the context.js because it will be needed in other parts
    // it seems it's possible to have workers manage websockets but at the same time they don't survive tab reloads and is more complex, not ready to dive into that rabbit hole just yet
    useEffect(() => {
        if (joined) {
            if (slug) {
                user_channel.push("get_product", {slug: slug})
                            .receive("ok", ({product}) => {
                                console.log("get_product", product);
                                dispatch({type: "set_product", value: product});
                                dispatch({type: "check_ready"});
                                set_loading(false);
                            })
                            .receive("error", ({error}) => {
                                if (error === "non_existing") {
                                    error = create_error_element({msg: "This product doesn't exist."});
                                    ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
                                    history.push("/account/listings");
                                }
                                console.log("error get_product", error);
                            });
            } else {
                user_channel.push("start_placeholder", {})
                            .receive("ok", ({product}) => {
                                
                                dispatch({type: "set_product", value: product});
                                history.replace(`/account/product/${product.id}`);
                                set_loading(false);
                                
                            });
            }

            user_channel.on("updated_product", ({product_id, field, value}) => {
                if (product_id === ctx.id) {
                    dispatch({type: "set", field: field, value: value});
                }
            });

            return () => user_channel.off("updated_product");
            
        } else {
            connected(0);
        }
        
    }, [user_channel, joined]);

    useEffect(() => dispatch({type: "check_ready"}), [
        ctx.id,
        ctx.main_image,
        ctx.title,
        ctx.subtitle,
        ctx.description,
        ctx.categories,
        ctx.subcategories
    ]);

    useEffect(() => {
        if (ctx.categories && subcategories) {
            set_subcategories_select(subcategories[ctx.categories.slug]);
        }
    }, [ctx, ctx.categories, subcategories]);

    // this should be extracted to the context.js
    function connected(count) {
        if (user_channel.state === "joined") {
            set_joined(true);
            
        } else {

            if (count >= 25) {
                history.push("/account");
                
            } else {
                
                if (user_channel.state === "closed") {
                    ctx_dispatch({type: "rejoin_user_channel"});
                    
                } else if (user_channel.state === "joining") {
                    setTimeout(() => connected(count + 1), 500);
                    
                }
            }
        }
    };
    
    function try_save_draft() {
        if (!title || title.trim() === "") {
            let error = create_error_element({msg: "You need to add at least a title!"});
            ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
            
        } else if (joined) {
            set_loading(true);
            save_draft(ctx, dispatch, user_channel, set_loading, ctx_dispatch);
            
        } else {
            // TODO warn non-existing connection
            connected(0);
        }
    };

    function image_upload(type, file) {
        if (type !== "main_image") {
            user_channel.push("check_images_limit")
                        .receive("ok", () => {
                            wrap_maybe_upload_image(type, file, ctx, dispatch, session_token, set_loading);
                        })
                        .receive("error", ({errors}) => {
                            if (errors) {
                                errors.forEach((e) => {
                                    e = create_error_element(e);
                                    ctx_dispatch({type: "show_error", value: e, dispatcher: ctx_dispatch});
                                });
                            }
                        });
        } else {
            wrap_maybe_upload_image(type, file, ctx, dispatch, session_token, set_loading);
        }
    };

    function wrap_maybe_upload_image(type, file, ctx, dispatch, session_token, set_loading) {
        maybe_upload_image(type, file, ctx, dispatch, session_token, set_loading)
            .catch(({errors}) => {
                if (errors) {
                    errors.forEach((e) => {
                        e = create_error_element(e);
                        ctx_dispatch({type: "show_error", value: e, dispatcher: ctx_dispatch});
                    });
                }
            });
    };

    function delete_image(id) {
        set_loading(true);
        user_channel.push("delete_image", {slug: slug, image_id: id})
                    .receive("ok", ({product}) => {
                        let notice = create_notice_element({msg: "Image Deleted!"});
                        ctx_dispatch({type: "show_notice", value: notice, dispatcher: ctx_dispatch});
                        dispatch({type: "set_product", value: product});
                        dispatch({type: "check_ready"})
                        set_loading(false);
                    })
                    .receive("error", () => {
                        let error = create_error_element({msg: "Unable to delete the chosen image, please check your connection and try again"});
                        ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
                        set_loading(false);
                    });
    };

    function delete_product() {
        user_channel.push("delete_product", {slug: slug})
                    .receive("ok", () => {
                        let notice = create_notice_element({msg: "Product Deleted!"});
                        ctx_dispatch({type: "show_notice", value: notice, dispatcher: ctx_dispatch});
                        history.push("/account/listings");
                    });
    };

    function pause_product() {
        user_channel.push("pause_product", {id: id})
                    .receive("ok", ({product}) => {
                        dispatch({type: "set_product", value: product});
                        dispatch({type: "check_ready"});
                        set_loading(false);
                        
                        let notice = create_notice_element({msg: "Product listing has been paused."});
                        ctx_dispatch({type: "show_notice", value: notice, dispatcher: ctx_dispatch});
                    }).receive("error", (error) => {
                        error = create_error_element(error);
                        ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
                        set_loading(false);
                    });
    };

    function publish() {

        let data = valid_fields.reduce((acc, key) => {
            acc[key] = ctx[key];
            return acc;
        }, {});
        
        user_channel.push("publish_product", {product: {...data}, id: id})
                    .receive("ok", ({product}) => {
                        dispatch({type: "set_product", value: product});
                        dispatch({type: "check_ready"})
                        set_loading(false);
                        
                        let notice = create_notice_element({msg: "Product listing has been published!"});
                        ctx_dispatch({type: "show_notice", value: notice, dispatcher: ctx_dispatch});
                        history.push("/account/listings");
                        
                    }).receive("error", ({errors}) => {
                        if (errors) {
                            errors.forEach((e) => {
                                e = create_error_element(e);
                                ctx_dispatch({type: "show_error", value: e, dispatcher: ctx_dispatch});
                            });
                        }
                        set_loading(false);
                    });
    };
    
    return (
        loading ?
        <Spinner classes="spinner-full" /> :
        <div className="profile-self-content">
            {
                show_delete ?
                <div className="listing-popup">
                    <div className="listing-popup-content">
                        <p>You can't undo this action. Are you sure you want to delete this product and listing?</p>
                        <div className="fx-row justify-content-center my-2">
                            <button
                                className="btn success-gradient btn-size-normal color-white"
                                onClick={() => set_show_delete(false)}
                            ><TiCancel style={{height: 22, width: 22, marginRight: 1, marginTop: -1.5}} />Cancel</button>
                            <button
                                className="btn primary-gradient btn-size-normal color-white"
                                onClick={() => delete_product()}
                            ><FaTrashAlt style={{height: 14, width: 14, marginRight: 4}} />Delete</button>
                        </div>
                    </div>
                </div>:
                ""
            }
            <div className="listing-container">

                <div className="listing__action">
                    {
                        id ?
                        <h1 className="listing-header">Edit your listing</h1> :
                        <h1 className="listing-header">Create a new Listing</h1>
                    }
                    
                    <button
                        type="button"
                        className="btn primary-gradient btn-size-normal color-white"
                        onClick={() => set_show_delete(true) }
                    ><FaTrashAlt style={{height: 14, width: 14, marginRight: 5}} />Delete</button>
                    
                    <button
                        type="button"
                        className="btn primary-gradient btn-size-normal color-white"
                        onClick={(_) => try_save_draft()}
                    ><FaSave style={{height: 14, width: 14, marginRight: 5}} />Save Draft</button>
                    {
                        published ?
                        <div title="Come back later to publish the listing.">
                            <button
                                type="button"
                                className="btn primary-gradient btn-size-normal color-white"
                                onClick={(_) => pause_product()}
                            ><FaHandPaper style={{height: 14, width: 14, marginRight: 5}} />Pause</button></div> :
                        (ready ?
                         <button
                             type="button"
                             className="listing__action__button__svg ready ml-2"
                             onClick={(_) => publish()}
                         /> :
                         <button
                             type="button"
                             className="listing__action__button__svg ml-2"
                             disabled={true}
                         />
                        )
                    }
                </div>
                <h3 className="listing__section__header">Photos</h3>
                <div className="listing__gallery">
                    <ProductImageUploader
                        classes="listing__gallery__main"
                        file_type="main_image"
                        file={main_image}
                        upload_callback={image_upload}
                        multiple={false}
                    >
                        <button className="btn btn-size-normal primary-gradient">
                            + {main_image ? "Change" : "Add"} Photo
                        </button>
                    </ProductImageUploader>
                    <div className="listing__gallery__items">
                        {images.map((img, index) => {
                            return <ProductImageUploader
                                       classes="listing__gallery__item"
                                       file_type={index}
                                       file={img ? img.path : false}
                                       upload_callback={image_upload}
                                       delete_callback={delete_image}
                                       id={img ? img.id : false}
                                       multiple={false}
                                       key={`extra-image-${index}`}
                            />
                        })}
                    </div>
                </div>
                <h3 className="listing__section__header">Price</h3>
                <div className="listing__input__area">
                    <div className="listing__input__form price-field">
                        <input
                            type="text"
                            name="product-price"
                            placeholder="Ex: “95.5“ "
                            className="large-text"
                            value={price || ""}
                            onChange={(event) => dispatch({type: "set", field: "price", value: event.target.value})}
                        />
                    </div>
                </div>
                <h3 className="listing__section__header">Details</h3>
                <div className="listing__input__area">
                    <div className="listing__input__area__header">
                        <h3>Title</h3>
                        <p><span className="bold">Must be: </span>Up to 40 characters long</p>
                        <LengthTip max={max_length_title} current_value={title} />
                    </div>
                    <div className="listing__input__form">
                        <input
                            type="text"
                            name="product-title"
                            placeholder="Ex: “Hand-Crafted Leather Tote“"
                            className="large-text"
                            value={title || ""}
                            maxLength={max_length_title}
                            onChange={(event) => dispatch({type: "set", field: "title", value: event.target.value})}
                        />
                    </div>
                </div>
                <div className="listing__input__area">
                    <div className="listing__input__area__header">
                        <h3>Short Description</h3>
                        <p><span className="bold">Must be: </span>Up to 300 characters long</p>
                        <LengthTip max={max_length_subtitle} current_value={subtitle} />
                    </div>
                    <div className="listing__input__form">
                        <input
                            type="text"
                            name="product-short-description"
                            placeholder="Ex: “Available while inventory lasts. Contact before Sunday Novemeber 2nd, for special pricing...”"
                            value={subtitle || ""}
                            maxLength={max_length_subtitle}
                            onChange={(event) => dispatch({type: "set", field: "subtitle", value: event.target.value})}
                        />
                    </div>
                </div>
                <div className="listing__input__multiple">
                    <div className="listing__input__area">
                        <div className="listing__input__area__header">
                            <h3>Product Description</h3>
                            <p/>
                            <LengthTip max={max_length_description} current_value={description} />
                        </div>
                        <div className="listing__input__form">
                            <textarea
                                rows="10"
                                style={{width: "100%"}}
                                name="product-long-description"
                                placeholder="Ex: “Beautifully hand-stitched leather tote...”"
                                value={description || ""}
                                maxLength={max_length_description}
                                onChange={(event) => dispatch({type: "set", field: "description", value: event.target.value})}
                            />
                        </div>
                    </div>
                    <div className="listing__input__area">
                        <div className="listing__input__area__header">
                            <h3>Primary Category</h3>
                        </div>
                        <div className="listing__input__form">
                            <div className="select-dropdown-wrapper">
                                <select
                                    name="categories"
                                    id="categories"
                                    className="select-dropdown mx-auto"
                                    value={category ? category.slug : ""}
                                    onChange={(event) => dispatch({type: "set_primary_category", value: get_category(event.target.value, categories)})}
                                >
                                    <option>Choose One</option>
                                    {(categories || []).map(({title, slug}) => <option key={`category-${slug}`} value={slug}>{title}</option> )}
                                </select>
                            </div>
                        </div>
                        <div className="listing__input__area__header mt-3">
                            <h3>Secondary Category</h3>
                        </div>
                        <div className="listing__input__form">
                            <div className="select-dropdown-wrapper">
                                <select
                                    name="subcategories"
                                    id="subcategories"
                                    className="select-dropdown mx-auto"
                                    value={subcategory ? subcategory.slug : ""}
                                    onChange={(event) => dispatch({type: "set_subcategory", value: get_subcategory(event.target.value, category, subcategories)})}
                                >
                                    <option>Choose One</option>
                                    {(subcategories_select || []).map(({title, slug, category_id}) => <option key={`subcategory-${slug}-${category_id}`} value={slug}>{title}</option> )}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="section-divider"></div>
                
                <h3 className="listing__section__header">Product Options</h3>
                <div className="listing__options">
                    <button
                        className="btn btn-size-normal primary-gradient"
                        onClick={(_) => dispatch({type: "create_option_section"})}
                    >
                        + Add Option Section
                    </button>
                </div>
                <div className="listing__options__elements">
                    {options.map(({ section, options }, index, initial) => {
                        return <OptionSection
                                   key={`option-section-${index}`}
                                   section={section}
                                   options={options}
                                   sections_length={initial.length}
                                   index={index}
                                   callback={(field, value) => {
                                       dispatch({type: "set_option", value: value, field: field, index: index});
                                   }}
                        />
                    })}
                </div>
            </div>
        </div>
    );
};


function save_draft(ctx, dispatch, user_channel, set_loading, ctx_dispatch) {

    let data = valid_fields.reduce((acc, key) => {
        acc[key] = ctx[key];
        return acc;
    }, {});

    user_channel.push("save_product", data, 10000)
                .receive("ok", ({product}) => {
                    dispatch({type: "set_product", value: product});
                    dispatch({type: "check_ready"})
                    set_loading(false);
                    let notice = create_notice_element({msg: "Saved!"});
                    ctx_dispatch({type: "show_notice", value: notice, dispatcher: ctx_dispatch});
                })
                .receive("error", ({error}) => {
                    if (error) {
                        error.forEach((e) => {
                            e = create_error_element(e);
                            ctx_dispatch({type: "show_error", value: e, dispatcher: ctx_dispatch});
                        });
                    }
                    set_loading(false);
                })
                .receive("timeout", () => {
                    let error = create_error_element({msg: "Unable to contact server, check your internet connection and refresh this page"});
                    ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
                    set_loading(false);
                });
};

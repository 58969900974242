import React from "react";

// STYLES

// UTILITY

// COMPONENTS
import DefaultLayout from "../../layouts/default.js";

export default function Support(_props) {

    return (
        <DefaultLayout>
            <div>Customer Support</div>
        </DefaultLayout>
    );
};

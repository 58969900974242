import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// UTILITY
import { BACKEND_URL } from "../../env/env.js";
import { plans_object } from "../../env/plans.js";
import { request_with_token } from "../../context/axios_requests.js";

import { create_error_element, response_error } from "../../js-utilities/error_arrays.js";
import { create_notice_element } from "../../js-utilities/notice_arrays.js";
import { useCtxProvider } from "../../context/context.js";
import { check_requirements } from "../signup/shared_account_details.js";

// COMPONENTS
import Spinner from "../../components/global/Spinner.js";

const PAYMENT_OPTIONS = {
    EXISTING: "EXISTING",
    NEW: "NEW"
};

export default function SellerManage({ state, dispatch, stripe }) {

    const [loading, set_loading] = useState(false);
    const [ready, set_ready] = useState(state.plan !== state.existing_plan);
    const [is_downgrade, set_is_downgrade] = useState(check_if_is_downgrade(state));

    const { ctx_dispatch, session_token } = useCtxProvider();

    useEffect(() => {
        set_is_downgrade(check_if_is_downgrade(state));
        set_ready(state.plan !== state.existing_plan);
    }, [state]);

    function handle_submit(event) {
        event.preventDefault();
        if (is_downgrade && !state.downgrade_agree) {
            let error = create_error_element({msg: "You need to agree to the changes resulting from downgrading your plan", ttl: 7500, field: "Downgrade Agreement"});
            ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
            return false;
        } else {
            set_loading(true);
            try_upgrade(dispatch, state, null, ctx_dispatch, session_token, set_loading);
        }
    };

    function check_if_is_downgrade({ plan, existing_plan }) {
        if (plan === existing_plan) { return false; }
        else {
            if (plans_object[plan].level === 1) {
                return true;
            } else {
                return false;
            }
        }
    };
    
    
    return (
        <form className="fx-row my-4 mx-auto justify-content-center" onSubmit={handle_submit}>
            <div className="fx-column justify-content-center align-items-center">
                <label style={{fontSize: "14px"}}>
                    I confirm I've read and agree to Simply Made Local's <Link to="/seller-agreement" target="_blank" className="color-primary">Terms of Service</Link> and <Link to="/privacy-policy" target="_blank" className="color-primary">Privacy Policy</Link>:</label>
                    <input
                        name="tos_pp"
                        type="checkbox"
                        className="mx-auto"
                        checked={state.tos_pp}
                        required
                        onChange={(event) => dispatch({type: "set_field", field: "tos_pp", value: event.target.value})}
                        disabled={loading}
                    />
            </div>
            {
                is_downgrade ?
                <div className="fx-column justify-content-center align-items-center my-2">
                    <p>You're downgrading to a basic membership plan, remember that:</p>
                    <hr/>
                    <ul>
                        <li>You'll only have at most 5 active listings, others will be paused</li>
                        <li>Only the main image of your listings will be visible</li>
                        <li>If you have a verified badge it will no longer show</li>
                    </ul>
                    <input
                        name="downgrade_agree"
                        type="checkbox"
                        className="mx-auto"
                        checked={state.downgrade_agree}
                        required
                        onChange={(event) => dispatch({type: "set_field", field: "downgrade_agree", value: event.target.value})}
                        disabled={loading}
                    />
                </div> :
                ""
            }
            <div className="signup-container-form stripe-form mt-4">
                {loading ? <Spinner classes="spinner-full" /> : ""}
                <div className="fx-column">
                    <div className="fx-row mt-2">
                        <button
                            type="submit"
                            className="btn btn-size-large btn-len-normal primary-gradient mx-auto"
                            disabled={loading || !ready}
                        >Proceed</button>
                    </div> 
                </div>
            </div>
        </form>
    );
};


function try_upgrade(dispatch, state, stripe, ctx_dispatch, session_token, set_loading) {
    let [valid, errors] = check_requirements(required_fields, state);
    var notice,
        error;

    if (valid) {    
        request_with_token({
            method: "POST",
            url: `${BACKEND_URL()}/api/v1/payments/upgrade`,
            data: {
                source: PAYMENT_OPTIONS.EXISTING,
                new_plan: state.plan,
                tos: state.tos_pp,
                downgrade_agree: state.downgrade_agree
            }
        }, session_token)
            .then(({data}) => {
                if (data.success) {
                    notice = create_notice_element({msg: "Your subscription type was upgraded!"})
                    ctx_dispatch({type: "show_notice", value: notice, dispatcher: ctx_dispatch});
                    ctx_dispatch({type: "signin", value: data});
                } else if (data.errors) {
                    data.errors.forEach((error) => {
                        ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
                        return false;
                    });
                }
                
                set_loading(false);
            })
            .catch((e) => {
                error = create_error_element({msg: "There was an error communicating with the server. Please try again in a while or contact support if the problem persists"});
                ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
                set_loading(false);
            });
        
    } else {
        errors.forEach((error) => {
            ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
            return false;
        });
        set_loading(false);
    }
};

const required_fields = [
    {
        field: "tos_pp",
        fun: (value) => value === true || value === "on",
        error: (value) => {
            return create_error_element({msg: "You need to agree to Simply Made Local's Terms of Service and Privacy Policy", ttl: 7500, field: "Tos & PP"});
        }
    }
]

const valid_fields = required_fields.map(({field}) => field);

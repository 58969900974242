import React from "react";

import CheckMark from "../components/svg_elements/CheckMark.js";
import ComingSoon from "../components/svg_elements/ComingSoon.js";

export const plan_basic = {
    name: "basic",
    price: 5,
    price_display: () => ({__html: 'FREE'}),
    description: [
        ["5 active product listings within a 30 day period", ""],
        ["1 product photo only per listing", ""],
        ["5 max conversations at one time", ""],
        ["Standard Seller Profile Page", ""],
        ["Access to Personal Boards/Gallery", "coming-soon-overlay"],
        ["Access to Craft events listings", ""],
        ["Access to Blog", ""],
        ["Access to basic level tutorials", ""]
    ],
    icon: <CheckMark background_color="#ff8683" />,
    level: 1
};

export const plan_premium = {
    name: "premium",
    price: 25,
    price_display: () => ({__html: '$25.00 monthly'}),
    description: [
        ["Unlimited Listings - unlimited photos", ""],
        ["Unlimited Conversations", ""],
        ["Customizable sellers profile page", ""],
        ["Verified Shop Badge", ""],
        ["Advertise shop through Simply Made Local endorsed events", ""],
        ["Basic analytics for shop", ""],
        ["Access to Blog", ""],
        ["Access to Personal Boards/Gallery", "coming-soon-overlay"],
        ["Premium exclusive tutorials", ""],
        ["Community and Education", ""]
    ],
    icon: <CheckMark background_color="#00a0c2" />,
    level: 2
};

export const plan_elite = {
    name: "elite",
    price: 50,
    price_display: () => ({__html: "Coming Soon"}),
    description: [
        ["All the features on premium plus:", ""],
        ["Exclusive Advertising Packages", ""],
        ["Fully customized seller page", ""],
        ["And Much more.", ""]
    ],
    icon: <ComingSoon />,
    disabled: true,
    level: 3
};

export const the_locals_plan = {
    name: "The Locals Membership",
    price: 5,
    price_display: () => ({__html: '$5 MONTHLY'}),
    description: [
        ["Unlimited Listings"],
        ["Unlimited Product Photos"],
        ["Unlimited Messaging with Customers"],
        ["Customizable vendor Profile Page"],
        ["Basic Shop Analytics"],
        ["Personalized Direct Shop Link"],
        ["Promote Local Craft Events"],
        ["Product Advertising on Social Media"],
        ["No Listing  Commission Fees"],
    ],
    icon: <CheckMark background_color="#00a0c2" />,
    level: 2
};

export const plans = [the_locals_plan];

export const plans_object = plans.reduce((acc, plan) => {
    acc[plan.name] = plan;
    return acc;
}, {});

import React, { useState, useEffect, useReducer } from "react";

// STYLING
import "../profile/index.scss";
import "./index.scss";

// UTILITY
import { plans } from "../../env/plans.js";
import { STRIPE_PUBLIC_KEY } from "../../env/env.js";
import { useCtxProvider } from "../../context/context.js";
import { create_error_element } from "../../js-utilities/error_arrays.js";

// COMPONENTS
import DefaultLayout from "../../layouts/default.js";
import Spinner       from "../../components/global/Spinner.js";
import SignupPlanCard from "../signup/steps/SignupPlanCard.js";

import InjectedCheckoutForm from "./StripeForm.js";
import SellerManage from "./SellerManage.js";

// STRIPE
import { StripeProvider, Elements } from "react-stripe-elements";

const stripe_snippet_id = "stripe-js-snippet";

export default function Upgrade({ history }) {

    const [loading, set_loading] = useState(true);

    const { user, is_verifying_user, ctx_dispatch } = useCtxProvider();

    const [user_type, set_user_type] = useState(user && user.type);

    const [is_stripe_loaded, set_stripe_loaded] = useState(false);

    const [state, dispatch] = useReducer(stripe_reducer, {
        first_name: user.first_name || "",
        last_name: user.last_name || "",
        email: user.email || "",
        phone_number: user.phone_number || "",
        profile_image: user.profile_image,
        cc_name: "",
        coupon: false,
        plan: false,
        existing_plan: false,
        tos_pp: false,
        downgrade_agree: false
    });

    useEffect(() => {
        if (user) {
            set_user_type(user && user.type);
            dispatch({type: "set_user", value: user});

            if (user.type === "buyer") {
                let script;
                if (!document.getElementById(stripe_snippet_id)) {
                    script = document.createElement("script");
                    script.src = "https://js.stripe.com/v3/";
                    script.id = stripe_snippet_id;
                    script.onload = () => set_stripe_loaded(true);
                    
                    document.head.appendChild(script);
                    
                } else {
                    document.getElementById(stripe_snippet_id).remove();
                    script = document.createElement("script");
                    script.src = "https://js.stripe.com/v3/";
                    script.id = stripe_snippet_id;
                    script.onload = () => set_stripe_loaded(true);
                    document.head.appendChild(script);
                }
            }
            
            set_loading(false);
            
        } else if (is_verifying_user) {
            set_loading(true);
            
        } else {
            let error = create_error_element({msg: "You need to login to access that."});
            ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});

            history.push("/login");
        }
    }, [user, is_verifying_user, ctx_dispatch, history]);


    function stripe_reducer(state, action) {
        switch(action.type) {
            case "set_field":
                state[action.field] = action.value;
                return {...state};
            case "set_user":
                let existing_plan = action.value.type === "seller" ? action.value.plan : false;
                return {...state, ...action.value, existing_plan: existing_plan};
            case "upgraded":
                history.push("/account/profile");
                window.location.reload();
                return state;
            default:
                return state;
        }
    };

    function select_plan(plan) {
        dispatch({type: "set_field", field: "plan", value: plan});
    };


    return (
        loading ?
        <Spinner classes="spinner-full" /> :
        <DefaultLayout>
            <div className="upgrade-container">
                {
                    user_type === "buyer" ?
                    <div className="profile-self-content pb-4">
                        <div className="profile-view-title-row">

                            <h3 className="mx-auto mt-2">Upgrade to Become a Maker!</h3>
                        </div>
                        <div className="section-divider mt-2" style={{height: "40px"}} />
                        <div className="signup-plan-container">
                            {plans.map(({name, price_display, description, icon, disabled}) => {
                                return <SignupPlanCard
                                           plan_name={name}
                                           plan_price_display={price_display}
                                           plan_description={description}
                                           plan_icon={icon}
                                           plan_disabled={disabled}
                                           select_plan={select_plan}
                                           classes={state.plan === name ? "selected-plan" : ""}
                                           key={name}
                                />;
                            })}
                        </div>
                        <div className="section-divider mt-2" style={{height: "40px"}} />
                        <div className="fx-row mt-4 mx-auto justify-content-center">
                            <h3>Required fields</h3>
                        </div>
                        {
                            is_stripe_loaded ?
                            <StripeProvider apiKey={STRIPE_PUBLIC_KEY()}>
                                <Elements>
                                    <InjectedCheckoutForm dispatch={dispatch} state={state}/>
                                </Elements>
                            </StripeProvider> :
                            <Spinner classes="spinner-full" />
                        }
                    </div> :
                    <div className="profile-self-content pb-4">
                        <div className="profile-view-title-row">
                            <h3 className="mx-auto mt-2">Manage your Subscription</h3>
                        </div>
                        <div className="section-divider mt-2" style={{height: "40px"}} />
                        <div className="signup-plan-container">
                            {plans.map(({name, price_display, description, icon, disabled}) => {
                                return <SignupPlanCard
                                           plan_name={name}
                                           plan_price_display={price_display}
                                           plan_description={description}
                                           plan_icon={icon}
                                           plan_disabled={disabled || name === state.existing_plan}
                                           select_plan={select_plan}
                                           classes={(state.plan === name) && (state.existing_plan !== state.plan) ? "selected-plan" : (state.existing_plan === name ? "existing-plan" : "")}
                                           key={name}
                                           existing_plan={state.existing_plan === name}
                                />;
                            })}
                        </div>
                        <div className="section-divider mt-2" style={{height: "40px"}} />
                        <SellerManage dispatch={dispatch} state={state}/>
                    </div>
                }
            </div>
        </DefaultLayout>
    );
};

import React from "react";
import { NavLink, Route, Switch } from "react-router-dom";

// STYLING

// UTILITY

// COMPONENTS
import ProfileAvatar    from "../shared/ProfileAvatar.js";

import ProfileView      from "./ProfileView.js";
import ProfileMessages  from "../messages/ProfileMessages.js";
import ChatThread        from "../messages/ChatThread.js";
import ProfileSettings  from "../ProfileSettings.js";
import ProfileFavorites from "../ProfileFavorites.js";


export default function BuyerProfile({ user }) {

    return (
        <div className="profile-self-grid">
            <div className="profile-self-sidebar">
                <ProfileAvatar user={user} />
                <div className="profile-self-name">{`${user.first_name} ${user.last_name || ""}`.trim()}</div>
                <div className="profile-self-navigation">
                    <NavLink exact={true} activeClassName="active-link" to="/account/profile">Profile</NavLink>
                    <NavLink exact={true} activeClassName="active-link" to="/account/messages">Messages</NavLink>
                    {/*}<NavLink exact={true} activeClassName="active-link" to="/account/favorites">Favorites</NavLink>*/}
                    <NavLink exact={true} activeClassName="active-link" to="/account/settings">Settings</NavLink>
                </div>
                <button type="button" className="btn btn-white color-primary font-size-600 my-1 mx-auto">Log Out</button>
                <NavLink className="upgrade-cta-card mx-auto mt-4" to="/upgrade">
                    <button className="btn font-size-600 primary-gradient">Become a Maker ></button>
                </NavLink>
            </div>
            <Switch>
                <Route path="/account/messages/:chat_id" component={ChatThread} />
                <Route path="/account/messages" component={ProfileMessages} />
                <Route path="/account/favorites" render={(_) => <ProfileFavorites user={user} /> } />
                <Route path="/account/settings" render={(_) => <ProfileSettings user={user} />} />
                <Route path="/account" render={(_) => <ProfileView user={user} />} />
            </Switch>
        </div>
    );
};

import React, { useEffect, useState } from "react";

// STYLING

// UTILITY
import { useCtxProvider } from "../../context/context.js";
import { create_error_element } from "../../js-utilities/error_arrays.js";
import { create_notice_element } from "../../js-utilities/notice_arrays.js";

// COMPONENTS
import Spinner from "../../components/global/Spinner.js";

export default function AdminSettings(_) {

    const { admin_state, ctx_dispatch } = useCtxProvider();

    const [loading, set_loading] = useState(true);

    const [password, set_password] = useState("");
    const [new_password, set_new_password] = useState("");
    const [new_password_confirmation, set_new_password_confirmation] = useState("");

    useEffect(() => {
        if (admin_state.user) {
            set_loading(false);
        } else {
            set_loading(true);
        }
    }, [admin_state.user])

    function submit(event) {
        event.preventDefault();
        if (fields_present()) {
            admin_state.channel.push("change_password", {password: password, new_password: new_password, new_password_confirmation: new_password_confirmation})
                       .receive("ok", () => {
                           let notice = create_notice_element({msg: "Successfully updated your password"});
                           ctx_dispatch({type: "show_notice", value: notice, dispatcher: ctx_dispatch});
                       })
                       .receive("error", (payload) => {
                           if (payload.errors) {
                               payload.errors.forEach((error) => {
                                   let n_error = create_error_element({...error, ttl: 5000, field: "Profile Image"});
                                   ctx_dispatch({type: "show_error", value: n_error, dispatcher: ctx_dispatch});
                               });
                           }
                       });
        }
    };

    function fields_present() {
        return password &&
               password.length > 0 &&
               new_password &&
               new_password.length > 0 &&
               new_password_confirmation &&
               new_password_confirmation.length > 0;
    };

    return (
        <div className="admin-user-container admin-settings">
            {loading ? <Spinner classes="spinner-full" /> : ""}
            <div className="admin-user">
                {
                    admin_state.user ?
                    <form className="admin-user-fields" onSubmit={submit}>
                        <div className="admin-field-row" >
                            <label>New Password</label>
                            <input
                                type="password"
                                id="new-password"
                                name="new-password"
                                value={new_password}
                                required={true}
                                onChange={(event) => set_new_password(event.target.value)}
                            />
                        </div>
                        <div className="admin-field-row" >
                            <label>Confirm Password</label>
                            <input
                                type="password"
                                id="confirmation-password"
                                name="confirmation-password"
                                value={new_password_confirmation}
                                required={true}
                                onChange={(event) => set_new_password_confirmation(event.target.value)}
                            />
                        </div>
                        <hr />
                        <div className="admin-field-row" >
                            <label>Current Password</label>
                            <input
                                type="password"
                                id="password"
                                name="password"
                                value={password}
                                required={true}
                                onChange={(event) => set_password(event.target.value)}
                            />
                        </div>
                        <button className="btn btn-primary color-white">Change</button>
                    </form> :
                    ""
                }
            </div>
        </div>
    );
};

import React, { useState, useReducer, useEffect } from "react";
import { FaTrashAlt, FaSave, FaHandPaper, Facanc } from 'react-icons/fa';
import { TiCancel } from "react-icons/ti";

// STYLING
import "../profile/seller/edit_listing.scss";
import "./edit_event.scss";

// UTILITY
import { image_url, BACKEND_URL }      from "../../env/env.js";
import { useCtxProvider } from "../../context/context.js";
import { convert_file }   from "../../js-utilities/image_helpers.js";
import { create_error_element } from "../../js-utilities/error_arrays.js";
import { create_notice_element } from "../../js-utilities/notice_arrays.js";
import { request_with_token } from "../../context/axios_requests.js";
import { get_offset_seconds } from "../../js-utilities/dates_and_times.js";

// COMPONENTS
import Spinner from "../../components/global/Spinner.js";
import ProductImageUploader from "../../components/upload/ProductImageUploader.js";
import LengthTip from "../../components/inputs/LengthTip.js";

import { event_upload_background } from "./misc.js";
const background = image_url("main/events-header.jpg");

const max_length_title = 60;
const max_length_address = 500;
const max_length_description = 5000;

const empty_start_ctx = {
    id: false,
    title: "",
    description: "",
    address: "",
    date: "",
    main_image: "",
    time_start: "",
    time_end: "",
    published: false,
    draft: true,
    passed: false,
    ready: false
};

const valid_fields = Object.keys(empty_start_ctx).reduce((acc, key) => {
    if (["id", "draft", "passed", "ready", "published"].includes(key)) {
        return acc;
    } else {
        acc.push(key);
        return acc;
    }
}, []);


export default function EditEvent({ match: { params: { edit_id } }, history }) {

    const [loading, set_loading] = useState(edit_id ? true : false);
    const [show_delete, set_show_delete] = useState(false);
    const [changed_image, set_changed_image] = useState(false);

    // EVENT
    const [ctx, dispatch] = useReducer(event_reducer, {...empty_start_ctx});

    const { categories, ctx_dispatch, user, session_token, is_verifying_user, user_channel, is_connected } = useCtxProvider();

    useEffect(() => {
        if (!user && !is_verifying_user) {
            let error = create_error_element({msg: "You need to login to access that."});
            ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});

            history.push("/login");
        }
    }, [user, is_verifying_user, ctx_dispatch, history]);

    useEffect(() => {
        if (edit_id && !ctx.id) {
            if (session_token) {
                request_with_token({
                    method: "GET",
                    url: `${BACKEND_URL()}/api/v1/events/edit/${edit_id}`
                }, session_token)
                    .then(({data}) => {
                        if (data.success) {
                            dispatch({type: "set_event", value: data.event});
                            set_loading(false);
                        }
                    });
            }
        } else if (!edit_id) {
            set_loading(false);
        }
    }, [edit_id, session_token]);

    useEffect(() => {
        if (is_connected) {
            user_channel.on("updated_event", (payload) => {
                if (payload.event_id === ctx.id) {
                    dispatch({type: "set", field: payload.field, value: payload.value});
                };
            });
        }
    }, [is_connected, user_channel, ctx.id]);

    useEffect(() => dispatch({type: "check_ready"}), [
        ctx.id,
        ctx.main_image,
        ctx.title,
        ctx.description,
        ctx.date,
        ctx.time_start,
        ctx.time_end
    ]);


    function try_save_draft() {
        set_loading(true);

        let form_data = get_form_data(ctx, changed_image);
        
        return new Promise((resolve, reject) => {
            request_with_token({
                method: "POST",
                url: `${BACKEND_URL()}/api/v1/events/${ctx.id ? "update/" + ctx.id : "create"}`,
                data: form_data,
                headers: {"Content-Type": "multipart/form-data"}
            }, session_token)
                .then(({data}) => {
                    if (data.success) {

                        set_changed_image(false);
                        if (history.location.pathname === "/events/create") {
                            history.replace(`/events/edit/${data.event.id}`);
                            
                            if (form_data.has("main_image")) {
                                dispatch({type: "set", field: "main_image", value: "loading"});
                            }
                        } else {
                            dispatch({type: "set_event", value: data.event});
                        }

                        let notice = create_notice_element({msg: "Event details saved!", ttl: 5000});
                        ctx_dispatch({type: "show_notice", value: notice, dispatcher: ctx_dispatch});
                    } else if (data.errors) {
                        data.errors.forEach((error) => {
                            let n_error = create_error_element({...error, ttl: 5000, field: error.field});
                            ctx_dispatch({type: "show_error", value: n_error, dispatcher: ctx_dispatch});
                        });
                    }
                    set_loading(false);
                    resolve(data);
                })
                .catch((e) => {
                    let error = create_error_element({msg: "There was an error saving this event.", ttl: 7500});
                    ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
                    set_loading(false);
                    reject(e);
                });
        });
    }



    function publish() {
        set_loading(true);

        try_save_draft()
            .then((response) => {
                set_loading(true);
                request_with_token({
                    method: "POST",
                    url: `${BACKEND_URL()}/api/v1/events/publish/${ctx.id}`
                }, session_token)
                    .then(({data}) => {
                        if (data.success) {
                            let notice = create_notice_element({msg: "Event published!", ttl: 5000});
                            ctx_dispatch({type: "show_notice", value: notice, dispatcher: ctx_dispatch});
                            dispatch({type: "set_event", value: data.event});
                        } else if (data.errors) {
                            data.errors.forEach((error) => {
                                let n_error = create_error_element({...error, ttl: 5000, field: error.field});
                                ctx_dispatch({type: "show_error", value: n_error, dispatcher: ctx_dispatch});
                            });
                        }
                        set_loading(false);
                    })
                    .catch((e) => {
                        let error = create_error_element({msg: "Unable to publish your event.", ttl: 7500});
                        ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
                        set_loading(false);
                    });
            })
            .catch((e) => {
                let error = create_error_element({msg: "Unable to publish your event.", ttl: 7500});
                ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
                set_loading(false);
            });
    };

    function unpublish() {
        set_loading(true);
        request_with_token({
            method: "POST",
            url: `${BACKEND_URL()}/api/v1/events/unpublish/${ctx.id}`
        }, session_token)
            .then(({data}) => {
                if (data.success) {
                    let notice = create_notice_element({msg: "Event unpublished!", ttl: 5000});
                    ctx_dispatch({type: "show_notice", value: notice, dispatcher: ctx_dispatch});
                    dispatch({type: "set_event", value: data.event});

                } else if (data.errors) {
                    data.errors.forEach((error) => {
                        let n_error = create_error_element({...error, ttl: 5000, field: error.field});
                        ctx_dispatch({type: "show_error", value: n_error, dispatcher: ctx_dispatch});
                    });
                }
                set_loading(false);
            })
            .catch((e) => {
                let error = create_error_element({msg: "Unable to unpublish your event.", ttl: 7500});
                ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
                set_loading(false);
            });
    };

    function delete_product() {
        if (ctx.id) {
            set_loading(true);

            request_with_token({
                method: "POST",
                url: `${BACKEND_URL()}/api/v1/events/delete`,
                data: {id: ctx.id}
            }, session_token)
                .then(({data}) => {
                    let notice = create_notice_element({msg: "Event Deleted!"});
                    ctx_dispatch({type: "show_notice", value: notice, dispatcher: ctx_dispatch});
                    history.push("/events");
                })
                .catch((e) => {
                    let error = create_error_element({msg: "There was an error deleting this event.", ttl: 7500});
                    ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
                    set_loading(false);
                });
        }
    };

    function add_image(field, file) {

        let quality = 0.80;
        let file_type = "image/jpeg";
        convert_file(file, quality, file_type, 1920, [1100, 200])
            .then((canvas) => {
                canvas.toBlob((blob) => {
                    set_changed_image(true);
                    let n_file = new File([blob], "event_image", {type:"image/jpeg", lastModified: new Date()});
                    dispatch({type: "set", field: field, value: n_file});
                }, file_type, quality);
            })
            .catch((error) => {
                if (error.error === "min_size") {
                    error = create_error_element({msg: `The image you chose needs to have a minimum of ${error.minimum}px ${error.side}.`, ttl: 7500, field: "Profile Image"});
                } else {
                    error = create_error_element({msg: `The image you chose seems to be invalid. Please choose a valid JPG or PNG file.`, ttl: 7500, field: "Profile Image"});
                }
                ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
            });
    };

    return (
        loading ?
        <Spinner classes="spinner-full" /> :
        <div className="listing-container" style={{maxWidth: "unset", margin: "0 auto 40px", padding: "0 0 20px"}}>
            {
                show_delete && ctx.id ?
                <div className="listing-popup">
                    <div className="listing-popup-content">
                        <p>You can't undo this action. Are you sure you want to delete this event?</p>
                        <div className="fx-row justify-content-center my-2">
                            <button
                                className="btn success-gradient btn-size-normal color-white"
                                onClick={() => set_show_delete(false)}
                            ><TiCancel style={{height: 22, width: 22, marginRight: 1, marginTop: -1.5}} />Cancel</button>
                            <button
                                className="btn primary-gradient btn-size-normal color-white"
                                onClick={() => delete_product()}
                            ><FaTrashAlt style={{height: 14, width: 14, marginRight: 4}} />Delete</button>
                        </div>
                    </div>
                </div>:
                ""
            }
            <div className="banner banner-events-small" style={{backgroundImage: `url(${background})`}}>
                <div className="element-fill"/>
                <p>Events</p>
            </div>
            <div style={{padding: "40px"}}>
                <div className="listing__action">
                    {
                        ctx.id ?
                        <h1 className="listing-header">Edit your Event</h1> :
                        <h1 className="listing-header">Create a new Event</h1>
                    }

                    <button
                        type="button"
                        className="btn primary-gradient btn-size-normal color-white"
                        onClick={() => set_show_delete(true) }
                    ><FaTrashAlt style={{height: 14, width: 14, marginRight: 5}} />Delete</button>

                    <button
                        type="button"
                        className="btn primary-gradient btn-size-normal color-white"
                        onClick={(_) => try_save_draft()}
                    ><FaSave style={{height: 14, width: 14, marginRight: 5}} />Save {ctx.published ? "Event" : "Draft"}</button>
                    {
                        ctx.published ?
                        <div title="Come back later to publish the listing.">
                            <button
                                type="button"
                                className="btn primary-gradient btn-size-normal color-white"
                                onClick={(_) => unpublish()}
                            ><FaHandPaper style={{height: 14, width: 14, marginRight: 5}} />Unpublish</button></div> :
                        (ctx.ready ?
                         <button
                             type="button"
                             className="listing__action__button__svg ready ml-2"
                             onClick={(_) => publish()}
                         /> :
                         <button
                             type="button"
                             className="listing__action__button__svg ml-2"
                             disabled={true}
                         />
                        )
                    }
                </div>
                <h3 className="listing__section__header">Event Cover Photo</h3>
                <div className="listing__gallery">
                    <ProductImageUploader
                        classes="listing__gallery__main event-cover"
                        file_type="main_image"
                        file={ctx.main_image}
                        upload_callback={add_image}
                        placeholder={event_upload_background}
                        multiple={false}
                    >
                        <button className="btn btn-size-normal primary-gradient">
                            + {ctx.main_image ? "Change" : "Add"} Photo
                        </button>
                    </ProductImageUploader>
                </div>
                <h3 className="listing__section__header">Details</h3>
                <div className="listing__input__area">
                    <div className="listing__input__area__header">
                        <h3>Title</h3>
                        <p><span className="bold">Must be: </span>Up to 60 characters long</p>
                        <LengthTip max={max_length_title} current_value={ctx.title} />
                    </div>
                    <div className="listing__input__form">
                        <input
                            type="text"
                            name="product-title"
                            placeholder="Ex: “Annual Craft Show...”"
                            className="large-text"
                            maxLength={max_length_title}
                            value={ctx.title}
                            onChange={(event) => dispatch({type: "set", field: "title", value: event.target.value})}
                        />
                    </div>
                    <div className="listing__input__area">
                        <div className="listing__input__area__header">
                            <h3>Location Address</h3>
                            <p/>
                            <LengthTip max={max_length_address} current_value={ctx.address} />
                        </div>
                        <div className="listing__input__form">
                            <input
                                type="text"
                                name="product-title"
                                placeholder="Ex: “151 Simply Made Local“"
                                className="large-text"
                                maxLength={max_length_address}
                                value={ctx.address}
                                onChange={(event) => dispatch({type: "set", field: "address", value: event.target.value})}
                            />
                        </div>
                    </div>
                    <div className="fx-row">
                        <div className="listing__input__area fx-1-0 mr-1">
                            <div className="listing__input__area__header">
                                <h3>Date</h3>
                            </div>
                            <div className="listing__input__form">
                                <input
                                    type="date"
                                    name="event-date"
                                    placeholder="MM / DD / YYYY"
                                    className="large-text"
                                    value={ctx.date || ""}
                                    onChange={(event) => dispatch({type: "set", field: "date", value: event.target.value})}
                                />
                            </div>
                        </div>
                        <div className="listing__input__area fx-1-0 ml-1">
                            <div className="listing__input__area__header">
                                <h3>Times of Operation</h3>
                            </div>
                            <div className="time-interval-row">
                                <label htmlFor="event-time-start">Start</label>
                                <div className="listing__input__form">
                                    <input
                                        type="time"
                                        name="event-time-start"
                                        placeholder="10:00"
                                        className="large-text"
                                        value={ctx.time_start || ""}
                                        onChange={(event) => dispatch({type: "set", field: "time_start", value: event.target.value})}
                                    />
                                </div>
                                <label htmlFor="event-time-end">End</label>
                                <div className="listing__input__form">
                                    <input
                                        type="time"
                                        name="event-time-end"
                                        placeholder="10:00"
                                        className="large-text"
                                        value={ctx.time_end || ""}
                                        onChange={(event) => dispatch({type: "set", field: "time_end", value: event.target.value})}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="listing__input__area">
                        <div className="listing__input__area__header">
                            <h3>Description</h3>
                            <p/>
                            <LengthTip max={max_length_description} current_value={ctx.description} />
                        </div>
                        <div className="listing__input__form">
                            <textarea
                                rows="10"
                                style={{width: "100%"}}
                                name="event-long-description"
                                placeholder="Ex: “There are free Mojitos so you probably don't need to know anything else...”"
                                maxLength={max_length_description}
                                value={ctx.description}
                                onChange={(event) => dispatch({type: "set", field: "description", value: event.target.value})}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

function event_reducer(state, action) {
    switch(action.type) {
        case "set_event":
            console.log("action_value", action.value, state);
            return {...state, ...action.value};
        case "set":
            state[action.field] = action.value;
            return {...state}
        case "check_ready":
            let image_ready = typeof state.main_image === "string" && state.main_image !== "loading" ? true : false;
            if (state.id &&
                image_ready &&
                state.title &&
                state.description &&
                state.date &&
                state.time_start &&
                state.time_end) {
                return {...state, ready: true}
            } else {
                return {...state, ready: false}
            }
        default:
            return state;
    }
};


function get_form_data(ctx, changed_image) {

    let form_data = new FormData();

    if (changed_image && ctx.main_image && ctx.main_image instanceof File) {
        form_data.append("main_image", ctx.main_image);
    }

    valid_fields.forEach((key) => {
        if (ctx[key]) {
            form_data.append(key, ctx[key]);
        }
    });

    form_data.append("timezone_offset", get_offset_seconds());

    return form_data;
}

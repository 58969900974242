import React, { useState } from "react";

// STYLING
import "./contact.scss";

// UTILITY
import { CLOUDFRONT_PUBLIC } from "../../env/env.js";
import { get_display_name }  from "../../context/user_helpers.js";
import { format_price }      from "../../js-utilities/currency.js"
import { useCtxProvider }    from "../../context/context.js";

import { create_error_element } from "../../js-utilities/error_arrays.js";
import { create_notice_element } from "../../js-utilities/notice_arrays.js";

// COMPONENTS
import ProfileAvatar from "../profile/shared/ProfileAvatar.js";
import Spinner       from "../../components/global/Spinner.js";

export default function Contact({ product, user, history, toggle }) {

    const [message, set_message] = useState("");
    const [sending_message, set_sending_message] = useState(false);

    const availability = product.published ? "available" : "unavailable";

    const { is_connected, user_channel, ctx_dispatch } = useCtxProvider();
 
    function send_message(event) {
        event.preventDefault();
        var error, notice;
        if (is_connected) {
            if (message && message.length > 0) {
                set_sending_message(true);
                user_channel.push("send_message", {
                    msg: message,
                    product_id: product.id,
                    seller_id: product.seller.id
                }).receive("ok", (message) => {
                    set_sending_message(false);
                    notice = create_notice_element({msg: "Your message was sent!"});
                    ctx_dispatch({type: "show_notice", value: notice, dispatcher: ctx_dispatch});
                    toggle();
                }).receive("error", () => {
                    set_sending_message(false);
                    error = create_error_element({msg: "There was a problem sending your message, please try again in a while."});
                    ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
                }).receive("timeout", () => {
                    error = create_error_element({msg: "Your request is taking too much time, please refresh this page."});
                    ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
                });
            }
        }
    };

    function goto_signin() {
        localStorage.setItem("sml_redirect_signin", `/product/${product.slug}`);
        history.push("/login");
    };
    
    return (
        <div className="contact-popup">
            <div className="seller-details-container">
                <div className="seller-details-wrapper">
                    <ProfileAvatar user={product.seller} />
                    <p>{get_display_name(product.seller)}</p>
                </div>
            </div>
            <div className="product-details-container">
                <div className="product-details-image">
                    <img src={`${CLOUDFRONT_PUBLIC()}/${product.main_image}`} alt={product.title} />
                    <p className={`message-product-status status-${availability}`}>{availability}</p>
                </div>
                <div className="product-details-description">
                    <p className="title">{product.title}</p>
                    <p className="price">${format_price(product.price)}</p>
                </div>
            </div>
            <div className="product-contact-actions">
                {
                    user ?
                    (sending_message ?
                    <Spinner /> :
                    <form className="send-message-field" onSubmit={send_message}>
                        <ProfileAvatar user={user} />
                        <textarea
                            value={message}
                            autoFocus={true}
                            onChange={(event) => set_message(event.target.value) }
                            placeholder={`Send ${get_display_name(product.seller)} a message about this product...`}
                        />
                        
                        <button type="submit" className="btn btn-size-normal primary-gradient ml-auto contact-send-button">Send</button>
                    </form>) :
                    <div className="fx-column align-items-center children-m-1">
                        <p>You need to sign-in in order to contact a seller</p>
                        <button
                            type="button"
                            className="btn btn-size-normal primary-gradient"
                            onClick={goto_signin}
                            disabled={sending_message}
                        >Sign In</button>
                    </div>
                }
            </div>
        </div>
    );
};

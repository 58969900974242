import React from "react";

// STYLING
import "./popup.scss";

// UTILITY

// COMPONENTS

export default function Popup({ children, toggle }) {

    return (
        <div className="popup-window">
            <div className="popup-content">
                <button type="button" className="input-clear" onClick={toggle} />
                {children}
            </div>
        </div>
    );
};

import React from "react";

import { Link } from "react-router-dom";

// STYLING

// UTILITY
import { month_day_year, time_am_pm } from "../../../js-utilities/dates_and_times.js";
import { image_url } from "../../../env/env.js";

// COMPONENTS
import ProfileAvatar from "../shared/ProfileAvatar.js";

export default function ShortMessage({ message: {chat_id, product, inserted_at, message, from, to, is_read, sender_type }, your_id }) {
    
    let product_owner = product.seller_id === from.id ? from : to;
    let buyer = product.seller_id === from.id ? to : from;
    
    let is_from_seller = product_owner.id === from.id;
    let from_type = is_from_seller ? "seller" : "buyer";
    let is_from_you = from.id === your_id ? true : false;

    let [is_from, from_class] = is_from_you ? ["you", "you"] : [from.name, from_type];
    let status = product.published ? "available" : "unavailable";
    let unread_status = !is_from_you && !is_read ? "unread" : "read";

    let seller = {...product_owner};

    let full_msg = `${is_from} ${message}`;
    let trimmed_msg = (full_msg.length > 65 ? `${full_msg.substr(0, 64)}...` : full_msg).replace(`${is_from} `, ""); 
    
    return (
        <Link to={`/account/messages/${chat_id}`} className={`message-row ${unread_status}`} key={`${chat_id}-${inserted_at}`}>
            <div className="message-row-first-half">
                <div className="message-product-image-container">
                    <div className="message-product-image" style={{backgroundImage: `url(${image_url(product.main_image)})`}}>

                    </div>
                    <div className={`message-product-status status-${status}`}>{status}</div>
                </div>
                <div className="message-seller-image">
                    <ProfileAvatar user={your_id === product_owner.id ? buyer : seller} />
                </div>
                <div className="message-seller-name">{your_id === product_owner.id ? buyer.name : seller.name}</div>
            </div>
            <div className="message-row-last-half">
                <div className="fx-1-0">
                    <p className="message-reply-date">{month_day_year(inserted_at)} at {time_am_pm(inserted_at)}</p>
                    <p className="message-reply"><span className={`from-${from_class}`}>{is_from}</span>: {trimmed_msg}</p>
                </div>
                <button
                    type="button"
                    className="btn-svg-primary"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" style={{alignSelf: "center", transform: "rotate(180deg)"}}><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
                </button>
            </div>
        </Link>
    );
};

import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

// STYLING

// UTILITY
import { BACKEND_URL } from "../../../env/env.js";
import { useCtxProvider } from "../../../context/context.js";
import { request_with_token } from "../../../context/axios_requests.js";

// COMPONENTS
import Spinner from "../../../components/global/Spinner.js";
import ListingTable from "./profile_listings/ListingTable.js";

export default function ProfileListings({ user, history }) {

    const [loading, set_loading] = useState(true);
    const [listings, set_listings] = useState([]);
    const [has_listings, set_has_listings] = useState(listings.length > 0);

    const { session_token, mobile } = useCtxProvider();

    useEffect(() => {
        if (user && session_token) {
            get_listings(user, session_token, set_listings, set_loading);
        } else {
            set_listings([]);
        }
    }, [user, session_token]);

    useEffect(() => set_has_listings(listings.length > 0), [listings]);

    return (
        loading ?
        <Spinner classes="spinner-full" /> :
        <div className="profile-self-content">
            <div className="profile-view-title-row">
                <h3>Listings</h3>
            </div>
            <div className="section-divider mt-2" style={{height: "40px"}} />
            {mobile ? <NavLink className="btn primary-gradient p-1 mx-auto btn-len-normal" to="/account/create-listing" ><span className="profile__list-item-plus-sign">+</span>List An Item</NavLink> : ""}
            <br/>
            {
                has_listings ?
                <ListingTable listings={listings} mobile={mobile} /> :
                <div className="fx-row justify-content-center align-items-center">
                    <h2>You have no listings yet!</h2>
                    <NavLink className="btn primary-gradient btn-size-normal ml-2" to="/account/create-listing">Create your first listing!</NavLink>
                </div>
            }
        </div>
    );
};

function get_listings(user, session_token, set_listings, set_loading) {
    request_with_token({
        method: "GET",
        url: `${BACKEND_URL()}/api/v1/products/seller/listings`,
    }, session_token)
        .then(({data}) => {
            if (data.success) {
                set_listings(data.listings);
            }
            set_loading(false);
        })
        .catch((e) => {
            console.log(`Error fetching user listings`, e);
            set_loading(false);
        });
};

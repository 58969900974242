export function create_notice_element({field, msg, ttl, id}) {
    id = id ? id : `${new Date().getTime()}-${generate_random(0, 1000)}`;
    ttl = ttl === "no_ttl" ? false : (ttl ? ttl : 5000);
    
    return {
        msg: msg,
        ttl: ttl,
        id: id,
        field: field
    };
};


export function add_notice_to(notice_array, notice, field, dispatcher) {
    notice_array.push(notice);
    if (notice.ttl) { 
        setTimeout(() => {
            dispatcher({type: "remove_notice", field: field, id: notice.id});
        }, notice.ttl);
    }
    return notice_array;
};

export function remove_notice_from(notice_array, id) {
    return notice_array.filter((element) => {
        return element.id !== id;
    });
};

function generate_random(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
};



import React, { useState, useEffect, useReducer } from "react";

// STYLING

// UTILITY
import { useCtxProvider }   from "../../../context/context.js";
import { BACKEND_URL } from "../../../env/env.js";
import { create_error_element } from "../../../js-utilities/error_arrays.js";


// COMPONENTS
import Spinner from "../../../components/global/Spinner.js";
import StateSelect from "../../../components/inputs/StateSelect.js";
import { image_url } from "../../../env/env.js";

const valid_update_fields = [
    "phone_prefix",
    "phone_number",
    "first_name",
    "last_name",
    "email",
    "company_name",
    "city",
    "state"
];

export default function AdminUserView({ match: { params: { id } }, history }) {

    const [loading, set_loading] = useState(true);
    const [original_user, set_original_user] = useState(false);
    const [user, user_dispatch] = useReducer(user_reducer, false);

    // this is for future work
    //const [field_types, set_field_types] = useState(false);
    
    const { admin_state, admin_dispatch, ctx_dispatch } = useCtxProvider();

    useEffect(() => {
        if (admin_state.is_connected) {
            set_loading(true);
            admin_state.channel.push("get_user", {id: id})
                       .receive("ok", (payload) => {
                           console.log(payload);
                           set_original_user(payload.user);
                           user_dispatch({type: "set_user", value: payload.user});
                           //this is for future work
                           //set_field_types(payload.schema);
                           set_loading(false);
                       })
                       .receive("error", (e) => {
                           console.log("error retrieving user for admin");
                           set_original_user(false);
                           user_dispatch({type: "unset"});
                           set_loading(false);
                       });
        }
    }, [id, admin_state.is_connected]);

    function impersonate(evt) {
        if (admin_state.is_connected) {
            set_loading(true);
            admin_state.channel.push("impersonate_user", {id: id})
                       .receive("ok", (payload) => {
                           console.log(payload);
                           if (payload.success) {
                               ctx_dispatch({type: "signin", value: payload});
                               history.push("/account/profile");
                           }
                           set_loading(false);
                       })
                       .receive("error", (e) => {
                           console.log("error retrieving user for admin");
                           set_loading(false);
                       });
        }
    };

    function toggle_user(toggle) {
        if (admin_state.is_connected) {
            set_loading(true);
            admin_state.channel.push(`${toggle}_user_toggle`, {id: id})
                       .receive("ok", (payload) => {
                           console.log(payload);
                           if (payload.success) {
                               user_dispatch({type: "set_field", field: toggle, value: payload[toggle]});
                               let new_original_user = {...original_user};
                               new_original_user[toggle] = payload[toggle];
                               set_original_user({...new_original_user});
                           }
                           set_loading(false);
                       })
                       .receive("error", (e) => {
                           console.log("error retrieving user for admin");
                           set_loading(false);
                       });
        }
    };

    function delete_image(which_image) {
        if (admin_state.is_connected) {
            set_loading(true);
            admin_state.channel.push("delete_image", {id: id, type: which_image})
                       .receive("ok", (payload) => {
                           if (payload.success) {
                               user_dispatch({type: "set_field", field: which_image, value: payload[which_image]});
                               let new_original_user = {...original_user};
                               new_original_user[which_image] = payload[which_image];
                               set_original_user({...new_original_user});
                           }
                           set_loading(false);
                       })
                       .receive("error", (e) => {
                           console.log("error retrieving user for admin");
                           set_loading(false);
                       });
        }
    };

    function submit(event) {
        event.preventDefault();
        set_loading(true);
        let data = valid_update_fields.reduce((acc, key) => {
            if (user[key] !== original_user[key]) {
                acc[key] = user[key];
            }
            return acc;
        }, {});

        if (Object.keys(data).length > 0) {
            admin_state.channel.push("update_user", {...data, id: id})
                       .receive("ok", (payload) => {
                           Object.keys(payload.user).forEach((key) => {
                               user_dispatch({type: "set_field", field: key, value: payload.user[key]});
                           });
                           set_original_user({...original_user, ...payload.user});
                           set_loading(false);
                       })
                       .receive("error", () => {
                           set_loading(false);
                       });
        } else {
            set_loading(false);
        }
    }
    
    return (
        loading ?
        <Spinner classes="spinner-full" /> :
        <div className="admin-user-container">
            {
                user ?
                <form className="admin-user" onSubmit={submit}>
                    <div className="fx-row justify-content-between">
                        <p className="fx-1-0 t-capitalize">{user.type} - ID: {user.id}</p>
                        <div className="fx-1-0 fx-row justify-content-end">
                            <button
                                type="submit"
                                className="btn color-white btn-success mx-2"
                            >Save
                            </button>
                            <button
                                type="button"
                                className={`btn color-white ${user.is_verified ? "btn-error" : "btn-success"}`}
                                onClick={(_) => toggle_user("is_verified")}
                            >{user.is_verified ? "Unverify" : "Verify"}</button>
                            <button
                                type="button"
                                className={`btn color-white ${user.is_locked ? "btn-success" : "btn-error"}`}
                                onClick={(_) => toggle_user("is_locked")}
                            >{user.is_locked ? "Unlock" : "Lock"}</button>
                            <button
                                type="button"
                                className="btn color-white btn-secondary"
                                onClick={impersonate}
                            >Impersonate</button>
                        </div>
                    </div>
                    <hr/>
                    <div className="admin-user-fields">
                        <div className="admin-field-row image">
                            <label>Profile Image</label>
                            {
                                user.profile_image ?
                                <div className="fx-row fx-1-0">
                                    <img src={image_url(user.profile_image)} />
                                    <button
                                        type="button"
                                        className="btn color-white btn-error"
                                        onClick={(_) => delete_image("profile_image")}
                                        disabled={true}
                                    >Delete Image</button>
                                </div> :
                                ""
                            }
                        </div>
                        <div className="admin-field-row">
                            <label>Phone Prefix</label>
                            <input
                                type="tel"
                                id="telephone-prefix"
                                name="telephone-prefix"
                                value={user.phone_prefix || ""}
                                onChange={(evt) => user_dispatch({type: "set_field", field: "phone_prefix", value: evt.target.value})}
                            />
                            <p className="ml-1">Original: {original_user.phone_prefix}</p>
                        </div>
                        <div className="admin-field-row">
                            <label>Phone Number</label>
                            <input
                                type="tel"
                                id="telephone"
                                name="telephone"
                                value={user.phone_number || ""}
                                pattern="^[0-9]{3}[-\s]?[0-9]{3}[-\s]?[0-9]{4}$"
                                onChange={(evt) => user_dispatch({type: "set_field", field: "phone_number", value: evt.target.value})}
                            />
                            <p className="ml-1">Original: {original_user.phone_number}</p>
                        </div>
                        <div className="admin-field-row">
                            <label>First Name</label>
                            <input
                                type="text"
                                id="given-name"
                                name="given-name"
                                value={user.first_name || ""}
                                onChange={(evt) => user_dispatch({type: "set_field", field: "first_name", value: evt.target.value})}
                            />
                            <p className="ml-1">Original: {original_user.first_name}</p>
                        </div>
                        <div className="admin-field-row">
                            <label>Last Name</label>
                            <input
                                type="text"
                                id="family-name"
                                name="family-name"
                                value={user.last_name || ""}
                                onChange={(evt) => user_dispatch({type: "set_field", field: "last_name", value: evt.target.value})}
                            />
                            <p className="ml-1">Original: {original_user.last_name}</p>
                        </div>
                        <div className="admin-field-row">
                            <label>Email</label>
                            <input
                                type="text"
                                id="email"
                                name="email"
                                value={user.email || ""}
                                onChange={(evt) => user_dispatch({type: "set_field", field: "email", value: evt.target.value})}
                                pattern=".+?[@].+?"
                                title="Your email should contain at least a @"
                                placeholder="Email"
                            />
                            <p className="ml-1">Original: {original_user.email}</p>
                        </div>
                    </div>
                    {
                        user.type === "seller" ?
                        <div className="admin-user-fields">
                            <hr/>
                            <div className="admin-field-row">
                                <label>Company Name</label>
                                <input
                                    type="text"
                                    id="company"
                                    name="company"
                                    value={user.company_name || ""}
                                    onChange={(evt) => user_dispatch({type: "set_field", field: "company_name", value: evt.target.value})}
                                    placeholder="Company Name"
                                />
                                <p className="ml-1">Original: {original_user.company_name}</p>
                            </div>
                            <div className="admin-field-row image">
                                <label>Company Logo</label>
                                {
                                    user.company_logo ?
                                    <div className="fx-row fx-1-0">
                                        <img src={image_url(user.company_logo)} />
                                        <button
                                            type="button"
                                            className="btn color-white btn-error"
                                            onClick={(_) => delete_image("company_logo")}
                                            disabled={true}
                                        >Delete Image</button>
                                    </div> :
                                    ""
                                }
                            </div>
                            <div className="admin-field-row image">
                                <label>Company Cover</label>
                                {
                                    user.company_cover ?
                                    <div className="fx-row fx-1-0">
                                        <img src={image_url(user.company_cover)} />
                                        <button
                                            type="button"
                                            className="btn color-white btn-error"
                                            onClick={(_) => delete_image("company_cover")}
                                            disabled={true}
                                        >Delete Image</button>
                                    </div> :
                                    ""
                                }
                            </div>
                            <div className="admin-field-row">
                                <label>City</label>
                                <input
                                    type="text"
                                    id="city"
                                    name="city"
                                    value={user.city || ""}
                                    onChange={(evt) => user_dispatch({type: "set_field", field: "city", value: evt.target.value})}
                                    placeholder="City"
                                />
                                <p className="ml-1">Original: {original_user.city}</p>
                            </div>
                            <div className="admin-field-row">
                                <label>State</label>
                                <StateSelect callback={(state_selected) => user_dispatch({type: "set_field", field: "state", value: state_selected})} name="state" value={user.state || ""} />
                                <p>Original: {original_user.state}</p>
                            </div>
                            <hr/>
                            <div className="admin-field-row">
                                <label>Seller Slug</label>
                                <p>{user.slug}</p>
                            </div>
                            <div className="admin-field-row">
                                <label>Stripe Plan</label>
                                <p>{user.plan}</p>
                            </div>
                            <div className="admin-field-row">
                                <label>Stripe Customer ID</label>
                                <p>{user.stripe_customer_id}</p>
                            </div>
                        </div> :
                        ""
                    }
                </form> :
                <div className="color-warning p-5">A user with id {id} doesn't exist</div>
            }
        </div>
    );
};

function user_reducer(state, action) {
    switch(action.type) {
        case "set_user":
            return {...action.value};
        case "set_field":
            if (state) {
                state[action.field] = action.value;
                return {...state};
            } else {
                return state;
            }
        case "unset":
            return false;
        default:
            return state;
    };
};

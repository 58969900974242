import React, { useState, Fragment } from "react";
import { FaPlus } from 'react-icons/fa';

// STYLES
import "./index.scss";

// UTILITY

// COMPONENTS
import DefaultLayout from "../../layouts/default.js";

export default function Faq(_props) {
    return (
        <DefaultLayout>
            <div className="faq-cover">
              <div className="faq-cover__title">
                <h1>Frequently Asked Questions</h1>
              </div>
            </div>
            <div className="faq-container">
              <div className="faq-about-us-faq-container">
                <h1 className="faq-header">About Us FAQs</h1>
                <FaqToggle questionText={q1} answerText={a1} />
                <FaqToggle questionText={q2} answerText={a2} />
                <FaqToggle questionText={q3} answerText={a3} />
                <FaqToggle questionText={q4} answerText={a4} />
                <FaqToggle questionText={q5} answerText={a5} />
                <FaqToggle questionText={q6} answerText={a6} />
                <FaqToggle questionText={q7} answerText={a7} />
                <FaqToggle questionText={q8} answerText={a8} />
              </div>
              <div className="faq-maker-faq-container">
                <h1 className="faq-header">Maker FAQs</h1>
                <FaqToggle questionText={q9} answerText={a9} />
                <FaqToggle questionText={q10} answerText={a10} />
                <FaqToggle questionText={q11} answerText={a11} />
                <FaqToggle questionText={q12} answerText={a12} />
                <FaqToggle questionText={q13} answerText={a13} />
                <FaqToggle questionText={q14} answerText={a14} />
                <FaqToggle questionText={q15} answerText={a15} />
                <FaqToggle questionText={q16} answerText={a16} />
                <FaqToggle questionText={q17} answerText={a17} />
                <FaqToggle questionText={q18} answerText={a18} />
              </div>
            </div>
        </DefaultLayout>
    );
};

function FaqToggle({questionText, answerText}) {
  const [isOpen, setIsOpen] = useState(false);
  const [rotate, setRotate] = useState(false);
  function handleClick() {
    setIsOpen(!isOpen);
    setRotate(!rotate);
  }
  return (
    <Fragment>
      <button onClick={handleClick} className="faq-question">{questionText}<FaPlus className={rotate ? "rotate" : ""} style={{height: 20, width: 20, marginBottom: -2, marginLeft: 5}} /></button>
      {isOpen && <p className="faq-answer" dangerouslySetInnerHTML={{__html: answerText}}></p>}
    </Fragment>
  )

}

// About Us Faqs
const q1 = "Who is Simply Made Local?";
const a1 = "Simply Made Local is the future of handmade eCommerce with an authentic local spin, the new premier website for shopping unique handmade products. Made by makers for makers, Simply Made Local is a new hybrid platform that brings your local marketplace home. Like similar platforms that offer the ability to resell your used goods in person, we have created an elite marketplace, specifically for handmade products, where people can connect in-person for pick-up and delivery. We also offer an expansive blog that features the best of handmade products each week, and on-trend DIY tutorial videos. For premium members, we provide elite content that is centered around enhancing your small business. We have also included a marketplace events locator that will allow you to stay connected to the craft events near you or while traveling.";
const q2 = "Why should I switch to Simply Made Local from the online marketplace I already use?";
const a2 = "We have strived to make Simply Made Local as user-friendly as possible. Using our mobile version, you can complete product uploads in 3 simple clicks, and our messaging features are convenient and easy to use. Our goal has always been to allow you to spend less of your time online so you can spend more time doing what you love, creating. The best part is, Simply Made Local is available for an affordable monthly subscription with no hidden fees. You can use it in addition to other online marketplaces that are available today. Who says you only have to use one? Having your handmade products available to as many people as possible is the idea, right?";
const q3 = "Why would I use Simply Made Local when I already have my own website?";
const a3 = "To increase your audience! Having more than one platform can only increase your customer base; allow us to advertise your shop for you. With Simply Made Local, you will receive a maker profile direct link that will be available for you to market to your customers. One of the best features of Simply Made Local is our Marketplace Locator. As a maker, you can create an event. As a premium member, you can add your shop to the event so patrons can view your shop ahead of time and seek out your booth specifically. Our Marketplace locator will help you find the craft events in your area or any destination when traveling. Imagine visiting a new city and using Simply Made Local to find all the local farmer's markets to shop for handmade goods.";
const q4 = "How much does it cost to become a member at Simply Made Local?";
const a4 = "Unlike other current handmade marketplaces, Simply Made Local does not charge listing fees, and doesn't take a commission on your hard-earned sales. There is also no requirement that you offer free shipping. We simply ask for a small monthly membership fee to allow us the ability to advertise the site to get your products in front of as many eyes as possible. Also, Simply Made Local will not be offering paid boosting options. Instead, your ranking will be determined by the accumulation of badges each store receives for outstanding performance (i.e., reviews, accountability, photo quality, and profile completion, etc.) Our badge system ensures an elite experience for your customers.";
const q5 = "How do I sign up?"; 
const a5 = "Signing up and choosing a membership that is right for your small business is as easy as 3 clicks! We wanted to make our process as user-friendly and straightforward as possible. The new website will launch to the general public on January 25, 2020. On launch day, makers will create a custom maker profile, upload product photos, instantly connect with local buyers and makers, and find local marketplace events. Artists can upgrade their monthly membership at any time to experience exclusive benefits that fit the needs of their small business. Simply Made Local puts the artist entirely in charge of their small business and creates a relationship between buyer and seller through its unique messaging platform, and intuitive design."; 
const q6 = "How do I get regular updates?";
const a6 = "Subscribe now to our newsletter! Currently, we offer weekly updates regarding the big launch on January 25, 2020. After the launch, we will offer business tips and education and feature our best vendors. Periodically we also like to give out digital freebies because we love to give back to our tribe."; 
const q7 = "Will there be shipping, and how much will it cost?";
const a7 = "We will be encouraging a local movement with a sophisticated take on connecting with your local community. Finalizing a sale is completed through our messaging platform within the site. How the product reaches the customer, whether by local delivery or shipping, is ultimately up to you. At Simply Made Local, you are in complete control of your small business.";
const q8 = "I still have more questions not covered here, who do I contact?";
const a8 = "Submit all questions and inquiries to: <a href='mailto:Support@simplymadelocal.com'>support@simplymadelocal.com</a>";

// Maker Faqs
const q9 = "What happens if someone places an order out of my local area?";
const a9 = "If personal delivery is not a possibility, it is up to the buyer and seller to decide how they want to arrange to ship the item(s) purchased. For your convenience, we have made available tools from Stamps.com to assist with shipping. Click here to sign up for a discounted account and ship from the comfort of your own home.";
const q10 = "Are there any hidden fees associated with a sale?";
const a10 = "Not with Simply Made Local! You, as the seller, pay your monthly membership fee, and the rest is up to you. We want to make sure you keep as much of your hard-earned money as possible. You earned it!";
const q11 = "I am currently a Buyer and want to upgrade to a Seller, how do I do this?";
const a11 = "Select the 'Signup' button located on the homepage. Choose a membership that works for your small business and start preparing to connect with customers in your local area today.";
const q12 = "How do I upgrade to premium membership?";
const a12 = "It's easy. We are happy to provide you with a new marketplace and a community of like-minded artistic entrepreneurs, absolutely free. However, if you are interested in taking your business to the next level, then take advantage of the helpful tools you need with our premium membership. You can upgrade your membership buy selecting to upgrade in 'settings' on your profile. From there, you will be charged monthly for the premium service, but don’t worry, you can downgrade at any time.";
const q13 = "How do I request a Simply Made Local Badge for my shop?"
const a13 = "The Simply Made Local Badge is an exclusive reward given to the most elite shops with excellent customer service and superior photos. Think of it as our favorite shops that we recommend. The Simply Made Local Badge is awarded on a review basis, and not all applicants will qualify. To apply, please email <a href='mailto:Support@simplymadelocal.com'>support@simplymadelocal.com</a>. The Simply Made Local Badge is only available to premium members.";
const q14 = "What are your handmade guidelines?";
const a14 = "Simply Made Local proudly provides an online marketplace for handmade, one-of-a-kind, custom, designed, or repurposed goods or crafts.";
const q15 = "How do I set up my Seller Shop?"
const a15 = "After a couple of easy clicks, you will choose your membership tier, upload your photo/logo and business banner, input your credit card info and start listing your products. Product listing is quick and straightforward. Add a photo and price for a minimal listing, or continue with your description and options for a full listing.";
const q16 = "How do I offer variations of my product?";
const a16 = "After you have uploaded the photo and set the price for your item, scroll down the page. The last option will be a product options section. Click the +Add Option Section and list your custom options."; 
const q17 = "Once I list, where will items appear on Simply Made Local?";
const a17 = "Your products will be displayed by category and available through the search bar. Products will display to the 'Popular Items' on the homepage determined by badge ownership and the number of views or inquiries of a listing.";
const q18 = "How do I relist items?"
const a18 = "We don't charge for listings, so they stay as long as you have an account with us. We want to save you some time and work! The only limitation is the amount you can list at one time based on the membership tier you purchase.";

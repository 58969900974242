import React from 'react';
import moment from 'moment';

import "./item.scss";

export default function Item({ title, picture, action, date }) {
  return (
    <div className="blog-card">
      <div className="blog-card-image" style={{backgroundImage: `url(${picture})`}}></div>
      <div className="blog-card-content">
        <div className="blog-card-date">{moment(date).format('ll')}</div>
        <div className="blog-card-title">{title}</div>
        <div className="blog-card-footer">
          <a className="blog-card-action" href={action.to} target="_blank" rel="noopener noreferrer">{action.text} <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.42 12.7"><g><g><polyline className="arrow-primary" points="6.17 0.71 11.81 6.35 6.17 11.99"/><polyline className="arrow-primary" points="14.37 0.71 20.01 6.35 14.37 11.99"/><line className="arrow-primary" y1="6.35" x2="19.34" y2="6.35"/></g></g></svg></a>
        </div>
      </div>
    </div>
  )
}

import React, { useState } from "react";

// STYLING

// UTILITY
import { BACKEND_URL } from "../../../env/env.js";
import { useCtxProvider } from "../../../context/context.js";
import { request_with_token } from "../../../context/axios_requests.js";
import { test_file } from "../../../js-utilities/profile_helpers.js";
import { create_error_element } from "../../../js-utilities/error_arrays.js";
import { accept_file } from "../../signup/steps/shared_functions.js";

// COMPONENTS
import AvatarUploader from "../../../components/upload/avatar-uploader.js";

export default function ProfileView({ user }) {
    
    const { session_token, ctx_dispatch } = useCtxProvider();

    const [first_name, set_first_name] = useState(user.first_name || "");
    const [last_name, set_last_name] = useState(user.last_name || "");
    const [email, set_email] = useState(user.email || "");
    const [phone_number, set_phone_number] = useState(user.phone_number || "");
    const [about, set_about] = useState("");

    
    function maybe_accept_file(accepted_files) {
        accept_file(accepted_files, 600, [300, 300])
            .then(({blob, file}) => {
                let form_data = new FormData();
                form_data.append("profile_image", file);
                
                request_with_token({
                    method: "POST",
                    url: `${BACKEND_URL()}/api/v1/accounts/update`,
                    data: form_data,
                    headers: {"Content-Type": "multipart/form-data"}
                }, session_token)
                    .then(({data}) => {
                        if (data.success) {
                            ctx_dispatch({type: "update_user", value: {profile_image: data.profile_image}});
                        } else if (data.errors) {
                            data.errors.forEach((error) => {
                                let n_error = create_error_element({...error, ttl: 5000, field: "Profile Image"});
                                ctx_dispatch({type: "show_error", value: n_error, dispatcher: ctx_dispatch});
                            });
                        }
                    })
                    .catch((e) => {
                        let error = create_error_element({msg: "There was an error processing your request", ttl: 7500, field: "Profile Image"});
                        ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
                    });
            });
    };

    function update_profile() {
        
        request_with_token({
            method: "POST",
            url: `${BACKEND_URL()}/api/v1/accounts/update`,
            data: {
                first_name: first_name,
                last_name: last_name,
                email: email,
                //phone_number: phone_number,
                about: about
            },
        }, session_token)
            .then(({data}) => {
                console.log(data)
                if (data.success) {
                    ctx_dispatch({type: "replace_user", value: data.user});
                } else if (data.errors) {
                    data.errors.forEach((error) => {
                        let n_error = create_error_element(error);
                        ctx_dispatch({type: "show_error", value: n_error, dispatcher: ctx_dispatch});
                    });
                }
            });
    }

    return (
        <div className="profile-self-content">
            <div className="profile-view-title-row">
                <h3>Profile <span>Update your information</span></h3>
                <button
                    className="btn primary-gradient"
                    onClick={(_) => update_profile()}
                >Update</button>
            </div>
            <div className="section-divider mt-2" style={{height: "40px"}} />
            <div className="fx-row mt-4 justify-content-center">
                <div className="text-center fx-1-0 sm:mb-2" style={{maxWidth: "225px"}}>
                    <AvatarUploader upload_callback={maybe_accept_file} file={user.profile_image} multiple={false} />
                    <p className="signup-note mt-1">Minimum 300px by 300px</p>
                </div>
                <div className="fx-1-0 ml-4 fx-column justify-content-start">
                    <input
                        type="text"
                        id="given-name"
                        name="given-name"
                        className="text-center mb-1 color-primary"
                        style={{width: "479px", maxWidth: "90%"}}
                        autoComplete="given-name"
                        value={first_name}
                        onChange={(event) => set_first_name(event.target.value)}
                        placeholder="First Name"
                    />
                    <input
                        type="text"
                        id="family-name"
                        name="family-name"
                        className="text-center my-1 color-primary"
                        style={{width: "479px", maxWidth: "90%"}}
                        autoComplete="family-name"
                        value={last_name}
                        onChange={(event) => set_last_name(event.target.value)}
                        placeholder="Last Name"
                    />
                    <input
                        type="text"
                        id="email"
                        name="email"
                        className="text-center my-1 color-primary"
                        autoComplete="email"
                        style={{width: "479px", maxWidth: "90%"}}
                        value={email}
                        onChange={(event) => set_email(event.target.value)}
                        pattern=".+?[@].+?"
                        title="Your email should contain at least a @"
                        placeholder="Email"
                    />
                    <input
                        type="tel"
                        id="telephone"
                        name="telephone"
                        className="text-center my-1 color-gray"
                        style={{width: "479px", maxWidth: "90%"}}
                        autoComplete="tel"
                        value={phone_number}
                        onChange={(event) => set_phone_number(event.target.value)}
                        disabled={true}
                        placeholder="Phone Number"
                        pattern="^[0-9]{3}[-\s]?[0-9]{3}[-\s]?[0-9]{4}$"
                        title="Valid 10 digit USA number with or without spaces or hyphens, e.g.: XXX XXX XXXX"
                    />
                </div>
            </div>
            <div className="section-divider full-divider mt-4" style={{height: "40px"}} />
            <div className="fx-column py-1 px-4 align-items-center">
                <label style={{fontSize: "24px", fontWeight: "bold"}} htmlFor="about">About</label>
                <textarea
                    id="about"
                    name="about"
                    style={{width: "90%", height: "200px", fontSize: "20px"}}
                    className="my-2 p-1"
                    value={about}
                    onChange={(event) => set_about(event.target.value)}
                    placeholder="Tell people about you"
                />
            </div>
        </div>
    );
};


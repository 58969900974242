import React, { useState, useEffect } from "react";

// STYLES

// UTILITY
import { plans } from "../../../env/plans.js";
import { BACKEND_URL } from "../../../env/env.js";
import { create_error_element, response_error } from "../../../js-utilities/error_arrays.js";
import { useCtxProvider } from "../../../context/context.js";

// COMPONENTS
import SignupPlanCard from "./SignupPlanCard.js";
import Spinner from "../../../components/global/Spinner.js";

const axios = require("axios");

export default function Step2Seller({ dispatch, state }) {

    const [loading, set_loading] = useState(false);

    const { ctx_dispatch } = useCtxProvider();

    useEffect(() => dispatch({type: "set_step", step: 2, account_type: "seller"}), [dispatch]);


    function select_plan(name) {
        if (state.coupon && state.coupon.trim() !== "") {
            set_loading(true);
            axios({
                method: "POST",
                url: `${BACKEND_URL()}/api/v1/verifications/coupon`,
                data: {coupon: state.coupon}
            })
                .then(({data}) => {
                    set_loading(false);
                    if (data.success) {
                        move_to_next(name);
                    } else {
                        let error = create_error_element({msg: "The coupon you entered seems to be invalid", ttl: 7500, field: "Coupon"});
                        ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
                    }
                })
                .catch((error) => {
                    error = create_error_element({msg: "There was a problem verifying the coupon you entered, please retry", ttl: 7500, field: "Coupon"});
                    ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
                    set_loading(false);
                });
        } else {
            move_to_next(name);
        }
        
    };

    function move_to_next(name) {
        dispatch({type: "set_field", field: "plan", value: name});
        dispatch({type: "set_step", step: 3, account_type: "seller"});
    };
    
    return (
        <div className="signup-container-step">
            <div className="signup-plan-container">
                {plans.map(({name, price_display, description, icon, disabled}) => {
                    return <SignupPlanCard
                               plan_name={name}
                               plan_price_display={price_display}
                               plan_description={description}
                               plan_icon={icon}
                               plan_disabled={disabled}
                               select_plan={select_plan}
                               key={name}
                    />;
                })}
            </div>
            <div className="signup-coupon-container">
                <p className="signup-label">Coupon</p>
                <input
                    type="text"
                    id="discount_coupon"
                    className="text-center mx-auto mt-2"
                    style={{width: "479px", maxWidth: "90%"}}
                    value={state.coupon ? state.coupon : ""}
                    onChange={(event) => dispatch({type: "set_field", field: "coupon", value: event.target.value})}
                    placeholder="Discount Coupon"
                />
            </div>
            {loading ? <Spinner classes="spinner-full" /> : ""}
        </div>
    );
};


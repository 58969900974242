import { CLOUDFRONT_PUBLIC } from "../../env/env.js";

export function decide_image(main_image) {
    switch(main_image) {
        case "loading":
        case "failed":
        case null:
            return `${CLOUDFRONT_PUBLIC()}/main/no-image.jpg`;
        default:
            return `${CLOUDFRONT_PUBLIC()}/${main_image}`;
    };
};

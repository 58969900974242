import React, { useState, useEffect } from "react";
import { NavLink, Route, Switch } from "react-router-dom";

// STYLING

// UTILITY
import { useCtxProvider }   from "../../../context/context.js";
import { profile_routes }   from "../../../context/user_routes.js";
import { get_display_name } from "../../../context/user_helpers.js";

// COMPONENTS
import ProfileAvatar     from "../shared/ProfileAvatar.js";
import ProfileMessages   from "../messages/ProfileMessages.js";
import ChatThread        from "../messages/ChatThread.js";
import ProfileSettings   from "../ProfileSettings.js";
import ProfileAccount    from "./ProfileAccount.js";
import ProfileListings   from "./ProfileListings.js";
import EditListing       from "./EditListing.js";
import EditListingMobile from "./EditListingMobile.js";

export default function SellerProfile({ user }) {

    const [display_name, set_display_name] = useState(get_display_name(user));
    const [user_routes, set_user_routes] = useState(profile_routes(user));

    useEffect(() => set_user_routes(profile_routes(user)), [user]);
    const { mobile } = useCtxProvider();
    isCreateListingUrl();
    return (
        user ?
        <div className="profile-self-grid profile-seller">
            {
                showSideNavigation(mobile, isCreateListingUrl) &&
                <div className="profile-self-sidebar">
                    <ProfileAvatar user={user} />
                    <div className="profile-self-name">{display_name}</div>
                    <div className="profile-self-navigation">
                        <NavLink className="btn primary-gradient" to="/account/create-listing" ><span className="profile__list-item-plus-sign">+</span>List An Item</NavLink>
                        <div className="profile-self-user-routes">
                            {user_routes.map(([route, name]) => <NavLink exact={true} activeClassName="active-link" to={route} key={route}>{name}</NavLink>)}
                        </div>
                    </div>
                    <button type="button" className="btn btn-white color-primary font-size-600 my-1 mx-auto">Log Out</button>
                </div>
            }

            <Switch>
                <Route path="/account/messages/:chat_id" component={ChatThread} />
                <Route path="/account/messages" component={ProfileMessages} />
                <Route path="/account/account" render={(_) => <ProfileAccount user={user} />} />
                <Route path="/account/settings" render={(_) => <ProfileSettings user={user} />} />
                {mobile
                ? <Route path="/account/create-listing" render={(_) => <EditListingMobile user={user} />} />
                : <Route path="/account/create-listing" component={EditListing} />
                }
                {mobile 
                ? <Route path="/account/product/:slug" render={(_) => <EditListingMobile user={user} />} />
                : <Route path="/account/product/:slug" component={EditListing} />
                }
                <Route path="/account" render={(_) => <ProfileListings user={user} />} />
            </Switch>
        </div> :
        ""
    );
};

function showSideNavigation(mobile, isCreateListingUrl) {
    if (mobile === false) return true;
    return mobile && !isCreateListingUrl();
}

function isCreateListingUrl() {
    const createListingPage = window.location.pathname.includes("create-listing") 
    const productPage = window.location.pathname.includes("product") 
    if (createListingPage || productPage) {
        return true;
    }
    return false;
}

import React, { useState, useEffect } from "react";

import { Link, NavLink } from "react-router-dom";

// STYLES
import "./navigation_dropdown.scss";

// UTILITY
import { profile_routes } from "../../../context/user_routes.js";
import { useCtxProvider } from "../../../context/context.js";

// COMPONENT
import Logo from "../Logo.js";

export default function NavigationDropdown({ set_show_dropdown, user }) {

    const [dropdown_section, set_dropdown_section] = useState("default");

    const [user_routes, set_user_routes] = useState(profile_routes(user));

    const { categories, ctx_dispatch } = useCtxProvider();

    useEffect(() => set_user_routes(profile_routes(user)), [user]);

    useEffect(() => {
        document.body.classList.add("no-vertical-overflow");

        return () => document.body.classList.remove("no-vertical-overflow");

    }, []);

    return  dropdown_section === "default" ? (
        <div className="navigation-mobile-dropdown-container">
            <div className="navigation-mobile-dropdown-default children-mx-1">
                <div className="navigation-mobile-dropdown-header">
                    <div className="close-dropdown">
                        <button type="button" className="input-clear" onClick={() => set_show_dropdown(false)} />
                    </div>
                    <div className="navigation-mobile-dropdown-logo">
                        <Logo width="100%" height="auto" />
                    </div>
                    <div className="navigation-placeholder-flex" />
                </div>
            </div>
            <hr />
            {
                user ?
                <div className="navigation-mobile-actions">
                  <div className="navigation-mobile-section-nav">
                    <div className="navigation-dropdown__user-routes">
                      {user_routes.map(([route, name]) => {
                          return <NavLink
                                     exact={true}
                                     activeClassName="active-link"
                                     className="btn btn-clear color-white"
                                     to={route}
                                     key={route}>{name}
                          </NavLink>
                      })}
                    </div>
                  </div>
                  <button
                      className="btn btn-white navigation-dropdown__signout-button"
                      onClick={() => ctx_dispatch({type: "signout"})}
                  >Sign Out</button>
                </div>
                 :
                <div className="navigation-mobile-section-nav">
                    <Link to="/signup" className="btn btn-white">Sign Up</Link>
                    <Link to="/login" className="btn btn-white">Log In</Link>
                </div>
            }
            <div className="navigation-mobile-dropdown-categories">
                <h4>Shop Local</h4>
                {categories ? categories.sort((a, b) => a.title > b.title ? 1 : -1).map(({title, slug}) => {
                    return <Link to={`/categories/${slug}`} key={slug}>{title}</Link>;
                }) : ""}
            </div>
        </div>
    ) : dropdown_section === "messages" ? (
        <div className="navigation-mobile-dropdown-container">
            <div className="navigation-mobile-dropdown-messages">
                Messages... These are not the droids you're looking for...
            </div>
            <div className="navigation-mobile-section-nav">
                <button type="button" className="btn btn-white" onClick={() => set_dropdown_section("default")}>Back</button>
                <button type="button" className="btn btn-white" onClick={() => set_dropdown_section("account")}>My Account</button>
            </div>
        </div>
    ) : (
        <div className="navigation-mobile-dropdown-container">
            <div className="navigation-mobile-dropdown-account">
                Account... This is not the droid you're looking for...n
            </div>
            <div className="navigation-mobile-section-nav">
                <button type="button" className="btn btn-white" onClick={() => set_dropdown_section("default")}>Back</button>
                <button type="button" className="btn btn-white" onClick={() => set_dropdown_section("messages")}>Messages</button>
            </div>
        </div>
    );
};

import React, { useState, useEffect } from "react";
import { NavLink, Link, withRouter } from "react-router-dom";

// STYLING
import "../global/navigation/navigation.scss";

// UTILITY
import { useCtxProvider } from "../../context/context.js";
import { admin_routes }   from "../../context/admin_routes.js";

// COMPONENTS
import Logo from "../global/Logo.js";

export default function AdminNavigation(_) {

    const { admin_state, admin_dispatch } = useCtxProvider();

    const [admin, set_admin] = useState(admin_state.user);
    const [display_name, set_display_name] = useState(get_name(admin));

    useEffect(() => {
        set_admin(admin_state.user);
    }, [admin_state.user]);

    useEffect(() => {
        if (admin) { set_display_name(get_name(admin)); }
    }, [admin]);

    return (
        <div className="navigation">
            <div className="navigation-logo">
                <Link to="/">
                    <Logo width="100%" height="auto" />
                </Link>
            </div>
            {
                admin ?
                <div className="navigation-session children-mx-1 fx-row justify-content-end">
                    {admin_routes.map(([route, label]) => <NavLink
                                                              to={route}
                                                              exact={true}
                                                              activeClassName="active-link"
                                                              className="link-text-inverse"
                                                              key={label}
                                                          >{label}</NavLink>
                    )}
                    <p>{display_name}</p> 
                </div> :
                ""
            }
        </div>
    );
};


function get_name({ first_name, last_name, id }) {
    if (first_name || last_name) {
        return [first_name, last_name].filter(name => name).join(" ");
    } else {
        return `Admin ${id}`;
    }
};

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// STYLES
import "./index.scss";
import "react-alice-carousel/lib/alice-carousel.css";

// UTILITY
import { CLOUDFRONT_PUBLIC } from "../../env/env.js";
import { BACKEND_URL }       from "../../env/env.js";

import { format_price }      from "../../js-utilities/currency.js";
import { get_display_name }  from "../../context/user_helpers.js";
import { useCtxProvider }    from "../../context/context.js";

// COMPONENTS
import DefaultLayout from "../../layouts/default.js";
import Spinner       from "../../components/global/Spinner.js";

import ItemFeed      from "../../components/sections/feeds/item_feed.js";

import ImageCarousel from "./ImagesCarousel.js";
import Popup         from "../../components/popups/Popup.js";
import Contact       from "./Contact.js";

// LIBS
const axios = require("axios");


export default function Product({ match: { params: { slug } }, history }) {

    const [loading, set_loading] = useState(true);
    const [product, set_product] = useState(false);

    const [primary_category, set_primary_category] = useState(false);

    const [show_contact, set_show_contact] = useState(false);

    const { user } = useCtxProvider();

    useEffect(() => {

        if (product) { history.replace(`/product/${product.slug}`); }

    }, [product, history]);

    useEffect(() => {
        set_loading(true);
        get_product(slug, set_product, set_primary_category, set_loading);
    }, [slug]);


    function toggle_contact() {
        set_show_contact(!show_contact);
    };


    return (
        <DefaultLayout>
            {
                loading ?
                <Spinner classes="spinner-full" /> :
                product ?
                <div className="product-container">
                    <ImageCarousel product={product} />
                    <div className="product__description">
                        <h1>{product.title}</h1>
                        <div className="product__description__company">
                            {
                                product.seller.company_logo ?
                                <img alt={`Seller Icon: ${product.seller.id}`} className="product__description__company__icon" src={`${CLOUDFRONT_PUBLIC()}/${product.seller.company_logo}`} /> :
                                ""
                            }
                            <p className="product__description__company_name">
                                <Link to={`/seller/${product.seller.slug}`}>
                                    {get_display_name(product.seller)}
                                </Link>
                            </p>
                        </div>
                        <p>{product.subtitle}</p>
                        <div className="product__description__price">
                            <p><span className="currency-sym">$</span>{format_price(product.price)}</p>
                        </div>
                        <button type="button" className="btn btn-size-normal primary-gradient primary-action" onClick={toggle_contact}>
                            Contact Seller
                        </button>
                        <div className="product__options">
                            {product.options.map(({ section, options }) => {
                                return (
                                    <div className="product__options__section__wrapper" key={`section-${section}`}>
                                        <h3 className="product__section__header">{section} Options</h3>
                                        <div className="product__options__section">
                                            {options.map(({title}) => {
                                                return <div className="product__options__item" key={`option-${title}`}>{title}</div>
                                            })}
                                        </div>
                                    </div>
                                )

                            })}
                        </div>
                        <h3 className="product__section__header">Details</h3>
                        <div dangerouslySetInnerHTML={{__html: product.description}}></div>
                    </div>
                </div> :
                ""
            }
            <div className="section-divider"></div>
            <ItemFeed icon={`${CLOUDFRONT_PUBLIC()}/${primary_category.image}`} title={primary_category.title} slug={primary_category.slug} except={product.id} />
            {
                show_contact ?
                <Popup toggle={toggle_contact}>
                    <Contact product={product} user={user} history={history} toggle={toggle_contact} />
                </Popup> :
                ""
            }
        </DefaultLayout>
    );
};


function get_product(slug, set_product, set_primary_category, set_loading) {
    return axios({
        method: "GET",
        url: `${BACKEND_URL()}/api/v1/products/${slug}`
    })
        .then(({data}) => {
            // console.log("get product", data);
            if (data.success) {
                set_product(data.product);
                set_primary_category(data.product.category);
            }
            set_loading(false);
        })
        .catch((e) => {
            console.log(`Error fetching product for ${slug}`, e);
            set_loading(false);
        });
};

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// STYLING
import "../../components/sections/banners/banner.scss";
import "./events_landing.scss";

// UTILITY
import { BACKEND_URL }      from "../../env/env.js";

// COMPONENTS
import Spinner from "../../components/global/Spinner.js";
import EventCard from "./EventCard.js";


const axios = require("axios");

export default function EventsFeed({ except, limit }) {

    limit = limit ? limit : 3;

    const [loading, set_loading] = useState(true);
    const [events, set_events] = useState([]);

    useEffect(() => {
        axios({
            method: "POST",
            url: `${BACKEND_URL()}/api/v1/events`,
            data: {
                except: except,
                limit: limit
            }
        })
            .then(({data}) => {
                if (data.success) {
                    set_events(data.events);
                }
                set_loading(false);
            })
            .catch((e) => {
                console.log("Error fetching events", e);
                set_loading(false);
            });
    }, []);

    return (
        loading ?
        <Spinner classes="spinner-full" /> :
        (events.length > 0) ?
        <div className="event-section">
            <h2 className="event-section-title mt-2">More Upcoming Events</h2>
            <div className="fx-row justify-content-center">
                {events.map((event) => <EventCard event={event} key={event.id} />)}
            </div>
        </div> :
        ""
    );
};

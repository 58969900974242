import React, { createContext, useContext, useReducer, useEffect } from "react";
import { withRouter } from "react-router-dom";

import { throttle } from "../js-utilities/window_resize_listener.js";
import { create_error_element, add_error_to, remove_error_from } from "../js-utilities/error_arrays.js";
import { add_notice_to, remove_notice_from } from "../js-utilities/notice_arrays.js";

import { verify, categories } from "./axios_requests.js";
import { SML_ADMIN_TOKEN_KEY } from "./admin_context.js";

import { BACKEND_URL } from "../env/env.js";
import { Socket } from "phoenix";

import useAdminCtx from "./admin_context.js";

const SML_TOKEN_KEY = 'sml_token';

const empty_start_ctx = {
    width: 0,
    height: 0,
    mobile: false,
    errors: [],
    notices: [],
    session_token: false,
    user: false,
    is_verifying_user: true,
    history: [],
    socket: false,
    user_channel: false,
    click: false,
    categories: false,
    subcategories: false,
    is_connected: false,
    unread_messages: {total: 0},
    history: false
}

const socket_url = `${BACKEND_URL()}/socket`.replace(/^http/, "ws");


const Ctx = createContext(null);

function CtxProviderFunction({ children, history }) {

    const [ctx_state, ctx_dispatch] = useReducer(ctx_reducer, {...empty_start_ctx, history: history});
    const [admin_state, admin_dispatch] = useAdminCtx(ctx_dispatch, history);

    useEffect(() => {

        function handle_resize() {
            ctx_dispatch({type: "set_window", element: document.documentElement});
        };

        function handle_storage_event(event) {
            handle_storage(event, ctx_state, ctx_dispatch, admin_dispatch);
        };

        function handle_click() {
            ctx_dispatch({type: "click"});
        };

        const {type, func} = throttle("resize", "optimized_resize");
        window.addEventListener("optimized_resize", handle_resize);
        window.addEventListener("storage", handle_storage_event);
        window.addEventListener("click", handle_click);

        try_fetch_session();

        if (sessionStorage.getItem(SML_TOKEN_KEY)) {
            ctx_dispatch({type: "set_session", storage: sessionStorage});
            ctx_dispatch({type: "verify", dispatcher: ctx_dispatch});
        } else {
            let fetch_session = !try_fetch_session();
            // console.log("fetch_session", fetch_session);
            if (!fetch_session) { ctx_dispatch({type: "set_verifying", value: false}); }
        }

        if (sessionStorage.getItem(SML_ADMIN_TOKEN_KEY)) {
            admin_dispatch({type: "set_session", storage: sessionStorage});
            admin_dispatch({type: "verify", dispatcher: admin_dispatch});
        } else {
            let fetch_session = !try_fetch_session();
            // console.log("fetch_session", fetch_session);
            if (!fetch_session) { admin_dispatch({type: "set_verifying", value: false}); }
        }

        ctx_dispatch({type: "fetch_categories", dispatcher: ctx_dispatch});

        handle_resize();

        return () => {
            //ctx_dispatch({type: "leave_ws"});
            window.removeEventListener("optimized_resize", handle_resize);
            window.removeEventListener(type, func);
            window.removeEventListener("storage", handle_storage_event);
        }
    }, [])

    useEffect(() => {
        ctx_dispatch({type: "verify", dispatcher: ctx_dispatch});
    }, []);

    useEffect(() => {
        if (!ctx_state.socket && ctx_state.session_token) {
            ctx_dispatch({type: "set_socket", dispatcher: ctx_dispatch});
        }
    }, [ctx_state.session_token]);

    useEffect(() => {
        if (ctx_state.socket && ctx_state.user &&
            (!ctx_state.user_channel || !["joined", "joining"].includes(ctx_state.user_channel.state))) {
            ctx_dispatch({type: "set_user_channel", dispatcher: ctx_dispatch});
        }
    }, [ctx_state.socket, ctx_state.user]);

    useEffect(() => {
        let redirect = localStorage.getItem("sml_redirect_signin");
        if (ctx_state.user && redirect) {
            localStorage.removeItem("sml_redirect_signin");
            history.push(redirect);
        }
    }, [ctx_state.user, history]);

    return (
        <Ctx.Provider value={{...ctx_state, ctx_dispatch: ctx_dispatch, admin_state: admin_state, admin_dispatch: admin_dispatch}}>
            {children}
        </Ctx.Provider>
    );
};


function ctx_reducer(state, action) {
    var token, user, remember_me;
    switch(action.type) {
        case "set_window":
            let width = action.element.clientWidth;
            let height = action.element.clientHeight;

            return {
                ...state,
                width: width,
                height: height,
                mobile: check_if_mobile(width, height)
            };
        case "set_session":
            console.log("set_session", action.storage);
            token = action.storage.sml_token ? action.storage.sml_token : false;
            return {...state, session_token: token};
        case "set_verifying":
            return {...state, is_verifying_user: action.value};
        case "signin":
            user = action.value.user;
            token = action.value.token;
            remember_me = action.value.remember_me;
            if (token) {
                set_token(SML_TOKEN_KEY, token, remember_me);
                sessionStorage.setItem(SML_TOKEN_KEY, token);
                localStorage.setItem("signin", Date.now());
                localStorage.removeItem("signin");
            }  else {
                user = false;
                delete_token(SML_TOKEN_KEY);
            }

            return {...state, user: user, session_token: token, is_verifying_user: action.is_verifying_user};
        case "verify":
            if (state.session_token && !state.user) {
                verify(state.session_token)
                    .then(({data}) => {
                        if (data.success) {
                            action.dispatcher({type: "signin", value: {user: data.user, token: data.token}, is_verifying_user: false});
                        } else {
                            action.dispatcher({type: "signin", value: {user: false, token: false}, is_verifying_user: false});
                        }
                    })
                    .catch((e) => {
                        action.dispatcher({type: "signin", value: {user: false, token: false}, is_verifying_user: false});
                    });
                return {...state, is_verifying_user: true};
            } else if (!state.user) {
                return {...state, session_token: false, user: false, is_verifying_user: false};
            } else {
                return {...state, is_verifying_user: false};
            }
        case "fetch_categories":
            if (!state.categories) {
                fetch_categories(action.dispatcher);
            }
            return state;
        case "set_categories":
            let fetched_categories = action.categories;
            let subcategories = fetched_categories.reduce((acc, {slug, subcategories}) => {
                acc[slug] = subcategories;
                return acc;
            }, {});
            
            return {...state, categories: fetched_categories, subcategories: subcategories};
        case "set_socket":
            state.socket = new Socket(socket_url, { params: { token: state.session_token } });
            state.socket.connect();
            state.socket.onClose((e) => {
                action.dispatcher({type: "connected", value: false})
                state.socket.disconnect();
                state.socket = false;
            });
            state.socket.onError((e) => {
                action.dispatcher({type: "connected", value: false});
                state.socket.disconnect();
                state.socket = false;
            });
            return {...state};
        case "set_user_channel":
            state.user_channel = state.socket.channel(`user:${state.user.id}`, { token: state.session_token });
            return add_user_channel_listeners(state, action.dispatcher);
        case "connected":
            return {...state, is_connected: action.value}
        case "rejoin_user_channel":
            state.user_channel.rejoin();
            return {...state};
        case "leave_ws":
            if (state.user_channel) { state.user_channel.leave(); }
            if (state.socket) { state.socket.disconnect(); }

            return {...state, socket: false, user_channel: false};
        case "signout":
            if (state.user_channel) {
                state.user_channel.leave();
                state.user_channel = false;
            }

            if (state.socket) {
                state.socket.disconnect();
                state.socket = false;
            }

            if (!action.notrigger) {
                localStorage.setItem("signout", Date.now());
                localStorage.removeItem("signout");
            }

            delete_token(SML_TOKEN_KEY)
            state.history.push("/");
            window.location.reload();
            return {...state, user: false, session_token: false, is_verifying_user: false};
        case "show_error":
            state.errors = add_error_to(state.errors, action.value, action.field, action.dispatcher);
            return {...state};
        case "remove_error":
            state.errors = remove_error_from(state.errors, action.id);
            return {...state};
        case "show_notice":
            state.notices = add_notice_to(state.notices, action.value, action.field, action.dispatcher);
            return {...state};
        case "remove_notice":
            state.notices = remove_notice_from(state.notices, action.id);
            return {...state};
        case "click":
            return {...state, click: !state.click};
        case "replace_user":
            return {...state, user: action.value};
        case "update_user":
            // the update_user is for when we're simply updating a single value like an image - because otherwise, since it's async, we could overrun the changes to other fields on the client side
            state.user = {...state.user, ...action.value};
            return {...state};
        case "new_message":
            if (state.user_channel && state.user_channel.state === "joined" && action.value && action.value.ack) {
                state.user_channel.push("ack_message", action.value.ack);
            }
            return maybe_add_unread(state, action.value);

        case "unread_messages":
            state.unread_messages = action.value.reduce((acc, chat_id) => {
                acc[chat_id] = true;
                acc.total += 1;
                return acc;

            }, {total: 0});
            return {...state};

        case "mark_as_read":
            let { chat_id } = action.value;
            state.unread_messages = Object.keys(state.unread_messages).reduce((acc, id) => {
                if (id === "total" || parseInt(id) === parseInt(chat_id)) {
                    return acc;
                }
                else {
                    acc[id] = true;
                    acc.total += 1;
                    return acc;
                }

            }, {total: 0});

            return {...state}
        default:
            return state;
    }
};


function handle_storage(event, state, dispatch, admin_dispatch) {
    if (event.key === "getSessionStorage") {
	// Some tab asked for the sessionStorage -> send it

	localStorage.setItem("sessionStorage", JSON.stringify(sessionStorage));
	localStorage.removeItem("sessionStorage");

    } else if (event.key === "sessionStorage" && sessionStorage.length === 0) {
	// sessionStorage is empty -> fill it

	let data = JSON.parse(event.newValue);
        // Handles edge case error causing app crashing
	if (data) {
            Object.keys(data).forEach((key) => {
  	        sessionStorage.setItem(key, data[key]);
  	    });
        }

        dispatch({type: "set_session", storage: sessionStorage});
        dispatch({type: "verify", dispatcher: dispatch});
        admin_dispatch({type: "set_session", storage: sessionStorage});
        admin_dispatch({type: "verify", dispatcher: admin_dispatch});

    } else if (event.key === "signout") {
        delete_token(SML_TOKEN_KEY)
        dispatch({type: "signout", storage: sessionStorage, notrigger: true});

    } else if (event.key === "signout_admin") {
        admin_dispatch({type: "signout", storage: sessionStorage, notrigger: true});
        
    } else if (event.key === "signin") {
        localStorage.setItem("getSessionStorage", Date.now());
        dispatch({type: "set_session", storage: sessionStorage});
        dispatch({type: "verify", dispatcher: admin_dispatch});
        
    } else if (event.key === "signin_admin") {
        localStorage.setItem("getSessionStorage", Date.now());
        admin_dispatch({type: "set_session", storage: sessionStorage});
        admin_dispatch({type: "verify", dispatcher: admin_dispatch});
    }
};

function try_fetch_session() {
    const localToken = localStorage.getItem(SML_TOKEN_KEY);
    const localAdminToken = localStorage.getItem(SML_ADMIN_TOKEN_KEY);

    if (localToken) {
        set_token(SML_TOKEN_KEY, localToken);
    }
    
    if (localAdminToken) {
        set_token(SML_ADMIN_TOKEN_KEY, localAdminToken);
    }

    if (sessionStorage.length === 0) {
	      localStorage.setItem("getSessionStorage", Date.now());
        return true;
    } else {
        return false;
    }
};

function check_if_mobile(width, height) {
    return width <= 768;
}

function add_user_channel_listeners(state, ctx_dispatch) {
    state.user_channel.on("new_message", (msg) => {
        ctx_dispatch({type: "new_message", value: msg});
        window.dispatchEvent(new CustomEvent("ctx_new_message", {detail: msg}));
    });

    state.user_channel.on("updated_profile", (payload) => {
        ctx_dispatch({type: "update_user", value: payload});
    });

    state.user_channel.on("unread_messages", ({ messages }) => {
        // console.log("unread_messages", messages);
        ctx_dispatch({type: "unread_messages", value: messages});
    });

    state.user_channel.onClose(() => ctx_dispatch({type: "connected", value: false}));


    state.user_channel.join()
         .receive("ok", (payload) => {
             ctx_dispatch({type: "connected", value: true});
             state.user_channel.push("unread_messages");
             console.log("channel joined", payload);
         })
        .receive("error", (payload) => {
            if (payload === "unauthorized") {
                let error = create_error_element({msg: "Your session has expired"});
                ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
                ctx_dispatch({type: "signout"});
                ctx_dispatch({type: "connected", value: false});
            }
         });

    return {...state};
};

function fetch_categories(dispatcher) {
    categories()
        .then(({data}) => {
            if (data.success) {
                dispatcher({type: "set_categories", categories: data.categories});
            }
        })
        .catch((e) => {
            console.log("Error fetching categories:", e);
        });
};

function maybe_add_unread(state, message) {
    if (state.user.id === message.to.id &&
        !message.is_read &&
        !state.unread_messages[message.to.id]) {

        state.unread_messages = {
            ...state.unread_messages,
            total: state.unread_messages.total + 1
        };
        state.unread_messages[message.chat_id] = true;
    }
    return {...state};

};

export function get_category(chosen_slug, categories, full) {
    if (!categories) {
        return null;
    } else {

        let category = categories.find((category) => category.slug === chosen_slug);
        if (category) {
            return full ? category : {slug: chosen_slug};

        } else {
            return null;
        }
    }
};

export function get_subcategory(chosen_slug, parent_category, subcategories, full) {
    if (!parent_category || !subcategories) {
        return null;
    } else {

        let subcategory = subcategories[parent_category.slug];
        if (subcategory) {
            return full ? subcategory : {category_id: subcategory.category_id, slug: chosen_slug};

        } else {
            return null;
        }
    }
};

function set_token(token_key, token, remember_me) {
  sessionStorage.setItem(token_key, token);
  if (remember_me) {
    localStorage.setItem(token_key, token);
  }
}

function delete_token(token_key) {
  sessionStorage.removeItem(token_key);
  localStorage.removeItem(token_key);
}

export const useCtxProvider = () => useContext(Ctx);

export default withRouter(CtxProviderFunction);

import React from "react";

// STYLING
import "../styles/layouts/admin_layout.scss";

// UTILITY

// COMPONENTS
import AdminNavigation from "../components/admin/AdminNavigation.js";

export default function AdminLayout({ children }) {

    return (
        <div className="admin-layout">
            <AdminNavigation />
            <div className="admin-container">{children}</div>
        </div>
    );
};

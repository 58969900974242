import React, { useState, useEffect, useReducer, useRef } from "react";

// STYLING

// UTILITY
import { BACKEND_URL } from "../../../env/env.js";

import { response_error } from "../../../js-utilities/error_arrays.js";
import { useCtxProvider } from "../../../context/context.js";

import { check_requirements, requirements, number_of_digits, empty_digits_array, check_verified_number } from "../shared_account_details.js";


// COMPONENTS
import Spinner from "../../../components/global/Spinner.js";

const axios = require("axios");

export default function Verification({ dispatch, state }) {

    const [loading, set_loading] = useState(false);

    const display_number = `${state.phone_number.slice(0,3)}-${state.phone_number.slice(3,6)}-${state.phone_number.slice(6)}`;

    const [verification, verification_dispatch] = useReducer(verification_reducer, {digits: [...empty_digits_array], complete: false});
    
    const { ctx_dispatch } = useCtxProvider();

    // We need to manually set all digits because useRef can't be called inside a loop, or an anonymous function/function that is not the React function itself (so no map/reduce)
    const digit_refs = {
        digit_0: useRef(null),
        digit_1: useRef(null),
        digit_2: useRef(null),
        digit_3: useRef(null),
        digit_4: useRef(null),
        digit_5: useRef(null)
    };

    useEffect(() => {

        if (state.type !== "buyer" && state.type !== "seller") {
            dispatch({type: "reset"});
            
        } else {
            let [valid, _errors] = check_requirements(requirements().required_fields[state.type], state, false);

            if (valid) {
                dispatch({type: "set_step", step: "verification", account_type: state.type});
                if (digit_refs.digit_0.current) {
                    digit_refs.digit_0.current.focus();
                }
                
            } else {
                dispatch({type: "reset"});
            }
        }
        
    }, []);

    function set_verification_digit(position, event) {
        let value = event.target.value;
        verification_dispatch({type: "set_value", index: position, value: value});
        if ((position + 1) < number_of_digits && value !== "") {
            digit_refs[`digit_${position + 1}`].current.focus();
        };
    };

    function handle_number_change(event) {
        console.log("number_change", state);
        if (state.type === "buyer") {
            dispatch({type: "set_step", step: 2, account_type: "buyer"});
        }
    };

    function handle_verification(event) {
        event.preventDefault();
        set_loading(true);

        try_verification(verification, state, dispatch, ctx_dispatch, set_loading);
    };

    function key_down(event, position, value) {
        if (event.keyCode === 8 && value === "") {
            if (position > 0) {
                digit_refs[`digit_${position - 1}`].current.focus();
            }
        }
    };

    return (
        loading ?
        <Spinner classes="fx-100 p-10" styleInner={{minWidth: "150px", width: "150px", minHeight: "150px", height: "150px"}} /> :
        <div className="signup-container-step bg-white" >
            <div className="signup-step-title">
                <h2>Verify your Number.</h2>
            </div>
            <div className="signup-verification-container mx-auto pb-8">
                <form className="verify-number-change my-3" onSubmit={handle_number_change}>
                    <div className="phone-verification-input mx-auto mt-5">
                        <input
                            type="tel"
                            id="telephone"
                            name="telephone"
                            value={display_number}
                            onChange={(event) => dispatch({type: "set_field", field: "phone_number", value: event.target.value})}
                            disabled
                        />
                        <button
                            type="button"
                            className="btn btn-clear color-primary"
                            onClick={handle_number_change}
                        >
                            Change
                        </button>
                    </div>
                </form>
                <p className="signup-italic-note mt-3 mb-5">We sent a 6-digit verification code to {state.phone_number}. Please enter it below to confirm that this is your phone.</p>
                <form className="verify-number-code" onSubmit={handle_verification}>
                    <div className="fx-row justify-content-center align-items-center">
                        {empty_digits_array.map((_val, index) => {
                            return <input
                                       type="text"
                                       id={`digit_${index}`}
                                       name={`digit_${index}`}
                                       key={`digit_${index}`}
                                       ref={digit_refs[`digit_${index}`]}
                                       className="digit-input"
                                       autoComplete="off"
                                       placeholder="#"
                                       maxLength="1"
                                       value={verification.digits[index]}
                                       onChange={(event) => set_verification_digit(index, event)}
                                       onKeyDown={(event) => key_down(event, index, verification.digits[index])}
                                       required
                            />
                        })}
                    </div>
                    <hr />
                    {state.success === "invalid" ?
                     <div className="invalid-element">
                         <img alt="Invalid" src="data:image/svg+xml;base64, PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0My42IDQzLjYiPjxnPjxnPjxjaXJjbGUgc3R5bGU9ImZpbGw6I2Y0NjQ2NCIgY3g9IjIxLjgiIGN5PSIyMS44IiByPSIyMS44Ii8+PGxpbmUgc3R5bGU9ImZpbGw6bm9uZTtzdHJva2U6I2ZmZjtzdHJva2UtbWl0ZXJsaW1pdDoxMDtzdHJva2Utd2lkdGg6M3B4OyIgeDE9IjEzLjUxIiB5MT0iMTMuNTEiIHgyPSIzMC4wOSIgeTI9IjMwLjA5Ii8+PGxpbmUgc3R5bGU9ImZpbGw6bm9uZTtzdHJva2U6I2ZmZjtzdHJva2UtbWl0ZXJsaW1pdDoxMDtzdHJva2Utd2lkdGg6M3B4OyIgeDE9IjEzLjUxIiB5MT0iMzAuMDkiIHgyPSIzMC4wOSIgeTI9IjEzLjUxIi8+PC9nPjwvZz48L3N2Zz4=" />
                         <p>Incorrect</p>
                     </div> :
                     ""
                    }
                    <div className="fx-row justify-content-center align-items-center mt-4">
                        <button type="submit" className="btn btn-size-large btn-len-normal primary-gradient" disabled={!verification.complete}>{state.success === "invalid" ? "Try again" : "Verify"}</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

function verification_reducer(state, action) {
    switch(action.type) {
        case "set_value":
            let new_array_digits = [...state.digits];
            new_array_digits[action.index] = action.value;
            let is_complete = verify_digits(new_array_digits);

            return {digits: new_array_digits, complete: is_complete};
        default:
            return state;
    };
};

function verify_digits(digits) {
    return digits.reduce((acc, value) => {
        if (acc && value && value !== "") { return true; }
        else { return false; }
    }, true);
};

function try_verification({ digits }, state, dispatch, ctx_dispatch, set_loading) {
    if (verify_digits(digits)) {
        let code = digits.join("");

        axios({
            method: "POST",
            url: `${BACKEND_URL()}/api/v1/verifications/check/phone_number`,
            data: {phone_number: state.phone_number, code: code} 
        })
            .then(({data}) => {
                if (data.success) {
                    if (state.type === "buyer") {
                        dispatch({type: "set_field", field: "success", value: true});
                        dispatch({type: "set_field", field: "verified_number", value: data.verified_number});
                        do_user_creation({...state, verified_number: data.verified_number})
                            .then(({data}) => {
                                
                                if (data.success) {
                                    ctx_dispatch({type: "signin", value: data});
                                    dispatch({type: "set_step", step: "finished", account_type: "buyer"});
                                } else if (data.errors) {
                                    data.errors.forEach((error) => {
                                        error = response_error(error);
                                        console.log(error);
                                        ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
                                    });
                                }
                                set_loading(false);
                            });
                    } else if (state.type === "seller") {
                        dispatch({type: "set_field", field: "verified_number", value: data.verified_number});
                        dispatch({type: "set_step", step: "4", account_type: "seller"});
                    }
                } else {
                    dispatch({type: "set_field", field: "success", value: "invalid"});
                    data.errors.forEach((error) => {
                        error = response_error(error);
                        ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
                    });
                    
                    set_loading(false);
                }
            })
            .catch((e) => {
                
            });
    }
};

function do_user_creation(state) {
    let validations = requirements();
    let [valid, errors] = check_requirements(validations.required_fields[state.type], state);
    
    if (valid && check_verified_number(state.verified_number)) {
        let valid_fields = validations.valid_fields[state.type];
        let form_data = new FormData();
        
        valid_fields.forEach((key) => {
            form_data.append(key, state[key]);
        });
        
        form_data.set("phone_number", state.verified_number);
        
        return axios({
            method: "POST",
            url: `${BACKEND_URL()}/api/v1/accounts/create`,
            data: form_data,
            headers: {"Content-Type": "multipart/form-data"}
        });
    } else {
        return new Promise(function(resolve, reject) {
            resolve({data: {errors: errors}});
        });
    }
};


import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// STYLES

// UTILITY
import { BACKEND_URL } from "../../../env/env.js";

import { create_error_element, response_error } from "../../../js-utilities/error_arrays.js";
import { useCtxProvider } from "../../../context/context.js";

import { phone_regex } from "../shared_account_details.js";
import { accept_file } from "./shared_functions.js";

// COMPONENTS
import Spinner from "../../../components/global/Spinner.js";
import AvatarUploader from "../../../components/upload/avatar-uploader.js";

const axios = require("axios");


export default function Step2Buyer({ dispatch, state }) {
    
    useEffect(() => dispatch({type: "set_step", step: 2, account_type: "buyer"}), [dispatch]);
    
    const [loading, set_loading] = useState(false);

    const { ctx_dispatch } = useCtxProvider();
    
    function handle_submit(event) {
        event.preventDefault();
        set_loading(true);

        valid_submission(state, dispatch, ctx_dispatch, set_loading);
    };

    function maybe_accept_file(accepted_files) {
        accept_file(accepted_files, 600, [300, 300])
            .then(({blob, file}) => {
                dispatch({type: "set_picture", field: "profile_image", value: file});
            })
            .catch((error) => {
                if (error.error === "min_size") {
                    error = create_error_element({msg: `The image you chose needs to have a minimum of ${error.minimum}px ${error.side}.`, ttl: 7500, field: "Profile Image"});
                } else {
                    error = create_error_element({msg: `The image you chose seems to be invalid. Please choose a valid JPG or PNG file.`, ttl: 7500, field: "Profile Image"});
                }
                ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
            });
    };
    
    return (
        loading ?
        <Spinner classes="fx-100 p-10" styleInner={{width: "150px", height: "150px"}} /> :
        <div className="signup-container-step bg-white" >
            <div className="signup-step-title">
                <h2>We only need a name and a number.</h2>
            </div>
            <form className="signup-container-form" onSubmit={handle_submit}>
                <input
                    type="text"
                    id="given-name"
                    name="given-name"
                    className="text-center mx-auto mt-5"
                    style={{width: "479px", maxWidth: "90%"}}
                    autoFocus={true}
                    autoComplete="given-name"
                    value={state.first_name}
                    onChange={(event) => dispatch({type: "set_field", field: "first_name", value: event.target.value})}
                    placeholder="First Name"
                    required
                    disabled={loading}
                />
                <input
                  type="text"
                  id="email"
                  name="email"
                  className="mx-auto text-center mt-5"
                  style={{width: "479px", maxWidth: "90%",}}
                  autoComplete="email"
                  value={state.email}
                  onChange={(event) => dispatch({type: "set_field", field: "email", value: event.target.value})}
                  pattern=".+?[@].+?"
                  title="Your email should contain at least a @"
                  placeholder="Email"
                  disabled={loading}
                  required
                />
                <input
                  type="password"
                  id="password"
                  name="password"
                  className="text-center mx-auto mt-5"
                  style={{width: "479px", maxWidth: "90%"}}
                  value={state.password}
                  onChange={(event) => dispatch({type: "set_field", field: "password", value: event.target.value})}
                  disabled={loading}
                  placeholder="Password"
                />
                <input
                    type="tel"
                    id="telephone"
                    name="telephone"
                    className="text-center mx-auto mt-5"
                    style={{width: "278px", maxWidth: "70%"}}
                    autoComplete="tel"
                    value={state.phone_number}
                    onChange={(event) => dispatch({type: "set_field", field: "phone_number", value: event.target.value})}
                    placeholder="Phone Number"
                    pattern="^[0-9]{3}[-\s]?[0-9]{3}[-\s]?[0-9]{4}$"
                    title="Valid 10 digit USA number with or without spaces or hyphens, e.g.: XXX XXX XXXX"
                    required
                    disabled={loading}
                />
                <div className="fx-column justify-content-center align-items-center mt-4">
                    <label style={{fontSize: "14px"}}>
                        I confirm I've read and agree to Simply Made Local's <Link to="/terms-of-service" target="_blank" className="color-primary">Terms of Service</Link> and <Link to="/privacy-policy" target="_blank" className="color-primary">Privacy Policy</Link>:</label>
                        <input
                            name="tos_pp"
                            type="checkbox"
                            className="mx-auto"
                            checked={state.tos_pp}
                            required
                            onChange={(event) => dispatch({type: "set_field", field: "tos_pp", value: event.target.checked})}
                            disabled={loading}
                        />
                </div>
                <hr className="mt-4 mb-2" />
                <div className="signup-step-subtitle">
                    <h3>Optional...</h3>
                </div>
                <div className="signup-optional-buyer">
                    <div className="signup-avatar-upload-section">
                        <AvatarUploader upload_callback={maybe_accept_file} file={state.profile_image} multiple={false} />
                        <p className="signup-label mt-1">User Photo <span className="thin-italic">(Optional)</span></p>
                        <p className="signup-note mt-1">Minimum Size 300px x 300px</p>
                    </div>
                    <div className="signup-optional-fields">
                        <input
                            type="text"
                            id="family-name"
                            name="family-name"
                            className="mx-auto"
                            autoComplete="family-name"
                            value={state.last_name}
                            onChange={(event) => dispatch({type: "set_field", field: "last_name", value: event.target.value})}
                            placeholder="Last Name"
                            disabled={loading}
                        />
                    </div>
                </div>
                <hr />
                <div className="fx-row justify-content-center align-items-center">
                    <button type="submit" className="btn btn-size-large btn-len-normal primary-gradient" disabled={loading}>Next</button>
                </div>
            </form>
        </div>
    );
};




function valid_submission(state, dispatch, ctx_dispatch, set_loading) {
    let {
        phone_number,
        first_name
    } = state;

    let valid_phone_number = phone_regex.test(phone_number);

    if (!valid_phone_number) {
        let error = create_error_element({msg: `Expected a valid USA phone number, in the form of XXX-XXX-XXXX (with or without hyphens or spaces), got ${phone_number}`, ttl: 7500, field: "Phone Number"});
        ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
        set_loading(false);
        return false;
    }

    if (first_name !== "" && (typeof first_name === "string" || first_name instanceof String)) {
        axios({
            method: "POST",
            url: `${BACKEND_URL()}/api/v1/accounts/create`,
            data: {first_name: first_name, phone_number: state.phone_number, email: state.email, password: state.password, type: 'buyer'},
        })
            .then(({data}) => {
                if (data.success) {
                    ctx_dispatch({type: "signin", value: data});
                    dispatch({type: "set_step", step: "finished", account_type: "buyer"});
                } else {
                    data.errors.map((error) => {
                        error = response_error(error);
                        ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
                        return null;
                    });
                    set_loading(false);
                }
            });
    } else {

    }
};


import React from "react";

// STYLES
import "../styles/layouts/default_layout.scss";

// UTILITY

// COMPONENTS
import Navigation from "../components/global/navigation/Navigation.js";
import Footer from "../components/footer/footer.js";

export default class DefaultLayout extends React.Component {
    render() {
        return (
            <div className="default-layout">
                <Navigation />
                <div className="main-container">{this.props.children}</div>
                <Footer />
            </div>
        )
    }
};

import React from "react";

// STYLING

// UTILITY
import { short_names_map, short_names } from "../../js-utilities/states.js";

// COMPONENTS

export default function StateSelect({ callback, name, id, classes, value, styles }) {

    id = id || name;
    classes = classes || "select-dropdown mx-auto";
    styles = styles || {};
    
    return (
        <div className="select-dropdown-wrapper" style={{width: "auto", maxWidth: "100%"}}>
            <select
                name={name}
                id={id}
                className={classes}
                value={value}
                onChange={(event) => callback(event.target.value)}
                style={styles}
            >
                <option value="">Select your State (Optional)</option>
                {short_names.map((key) => <option key={key} value={key}>{short_names_map[key]}</option>)}
            </select>
        </div>
    );
};

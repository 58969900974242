export function month_day_year(date) {
    return new Date(date).toLocaleDateString("en-US", { year: "numeric", month: "short", day: "numeric" });
};

export function time_am_pm(date) {
    return new Date(date).toLocaleTimeString("en-US", {hour: "2-digit", minute: "2-digit", hour12: true})
};

export function get_date(date) {
    if (date) {
        date = new Date(date);
        return month_day_year(date);
    } else {
        return "No Date";
    }
};

export function get_am_pm(time) {
    if (time) {
        let date = new Date(Date.now());
        let [hours, minutes, _] = time.split(":");
        date.setHours(hours, minutes);
        return time_am_pm(date);
    } else {
        return false;
    }
};

export function build_iso_8061_date(date, time) {
    return `${date}${time ? "T" + time + "Z" : ""}`;
}

export function get_offset_seconds() {
    return new Date(Date.now()).getTimezoneOffset() * 60;
};

import React from "react";

// STYLING
import "./message.scss";

// UTILITY
import { month_day_year, time_am_pm } from "../../../js-utilities/dates_and_times.js";
import { get_display_name } from "../../../context/user_helpers.js";

// COMPONENTS
import ProfileAvatar from "../shared/ProfileAvatar.js";

export default function Message({ message: { inserted_at, message, from_id }, user, parties }) {
    
    return (
        <div className="chat-message-row">
            <div className="chat-message-row-avatar">
                <ProfileAvatar user={parties[from_id]} />
            </div>
            <div className="chat-message-row-content">
                <p className="chat-message-row-name">{get_display_name(parties[from_id])}</p>
                <p className="chat-message-row-reply">{message}</p>
                <p className="chat-message-row-reply-date">{month_day_year(inserted_at)} at {time_am_pm(inserted_at)}</p>
            </div>
        </div>
    );
};

import { convert_file } from "../../../js-utilities/image_helpers.js";

export function accept_file(files, max_side, minimums) {
    var file = files[0];
        
    let quality = 0.85;
    let file_type = "image/jpeg";
    max_side = max_side || 300;

    return new Promise((resolve, reject) => {
        convert_file(file, quality, file_type, max_side, minimums)
            .then((canvas) => {
                let full_date = new Date();
                let date = full_date.toDateString().replace(/[\s]/g, "-");
                let time = full_date.toLocaleTimeString();
                let filename = `${date}-${time}.jpeg`;
                canvas.toBlob((blob) => {
                    let n_file = new File([blob], filename, {type:"image/jpeg", lastModified: new Date()});
                    resolve({blob: blob, file: n_file})
                }, file_type, quality);
            })
            .catch((e) => {
                reject(e);
            });
    });
};

import React from "react";

// STYLING
import "./listing_table.scss";

// UTILITY

// COMPONENTS
import ListingRow from "./ListingRow.js";

export default function ListingTable({ listings, mobile }) {
    return (
        <div className="listings-table">
            <div className="listing-row-header">
                <div className="listing-row-image"/>
                <div className="listing-row-title"/>
                <div className="listing-row-inquiries">Inquiries</div>
                <div className="listing-row-status">Status</div>
                <div className="listing-row-action"></div>
            </div>
            {listings.map((listing, index) => {
                return <ListingRow product={listing} mobile={mobile} key={`listing-${index}`} /> ;
            })}
        </div>
    )
};

import React from "react";


// STYLES

// UTILITY

// COMPONENTS
import DefaultLayout from "../../layouts/default.js";


export default function Tos(_props) {

    return (
        <DefaultLayout>
          <div className="legal-content">
            <h1><strong>Simply Made Local Terms of Use:</strong></h1>
            <p>&nbsp;</p>
            <p>The following terms and conditions ("Agreement") govern all use of the <a href="http://www.simplymadelocal.com">www.simplymadelocal.com</a> website (the "Website") and the services and Software available on or through the Website (the "Website," services and Software shall be referred to, collectively, as the "Service"). The Service is owned and operated by Simply Made Local, LLC. ("Simply Made Local"). The Service is offered subject to your acceptance without modification of all the terms and conditions contained herein and all other operating rules, policies, and procedures that may be published from time to time on the Website by Simply Made Local. By using or accessing any part of the Service, you agree to all the terms and conditions contained herein; if you do not agree to any of such terms and conditions, do not use or access the Service. This Agreement applies to you and your use of the Service regardless of whether you are a Seller, Buyer, or another user of the Service.</p>
            <p>&nbsp;</p>
            <p><strong>MODIFICATIONS TO TERMS OF USE</strong></p>
            <p>&nbsp;</p>
            <p>Simply Made Local reserves the right, at its sole discretion, to modify or replace any of the terms or conditions of this Agreement at any time without prior notice. It is your responsibility to check this Agreement periodically for changes. Your continued use of the Service following the posting of any changes to this Agreement constitutes acceptance of those changes. If any change to this Agreement is not acceptable to you, your sole remedy is to cease accessing, browsing, and otherwise using the Service.</p>
            <p>&nbsp;</p>
            <p><strong>MODIFICATION, SUSPENSION OR DISCONTINUATION OF THE SERVICE</strong></p>
            <p>&nbsp;</p>
            <p>Simply Made Local may modify, suspend or discontinue, temporarily or permanently, the Service or any portion thereof, in its sole discretion, at any time, without prior notice. Simply Made Local will not be responsible to you for a refund, in whole or in part. You agree that Simply Made Local shall not be liable to you or to any third party for any modification, suspension or discontinuance of the Service.</p>
            <p>&nbsp;</p>
            <p><strong>DESCRIPTION OF SIMPLY MADE LOCAL</strong></p>
            <p>&nbsp;</p>
            <p>Simply Made Local is an eCommerce marketplace website that allows people to buy and sell handmade goods, only. Simply Made Local takes steps to ensure that products sold on this marketplace platform are handmade products, only.</p>
            <p>&nbsp;</p>
            <p>Simply Made Local is not directly involved in the transaction between buyers and sellers.</p>
            <p>&nbsp;</p>
            <p>Consequently, Simply Made Local does not transfer legal ownership of items from the Seller to the Buyer. As a result, Simply Made Local does not control the quality, safety, morality or legality of any products sold on the Website; and Simply Made Local cannot verify the truth or accuracy of the listings, the ability of sellers to sell items or the ability of buyers to pay for items. Simply Made Local does not pre-screen users, the information about products being offered for sale, or the products themselves sold on Simply Made Local. Simply Made Local cannot guarantee the true identity, age, and nationality of a user. Simply Made Local cannot ensure that a Buyer or Seller will complete a transaction. For these reasons, buyers and sellers use the Service solely at their own risk.</p>
            <p>&nbsp;</p>
            <p>By using the Service, you agree that Simply Made Local is not responsible or liable for content uploaded by users of the Service (such as data, text, information, usernames, graphics, images, photographs, profiles, audio, video, items, and links) or the products sold on the Website.</p>
            <p>&nbsp;</p>
            <p>Simply Made Local encourages you to communicate directly with potential transaction partners through the tools available on the Website.</p>
            <p>&nbsp;</p>
            <p><strong>YOUR USE OF SERVICE</strong></p>
            <p>&nbsp;</p>
            <p>You shall use the Service only for purposes that are permitted by this Agreement and any applicable laws and regulations (foreign and domestic).</p>
            <p>&nbsp;</p>
            <p>Whether you use the Service as a Seller, Buyer, or otherwise, you agree to: (a) provide true, accurate, current and complete information about yourself as prompted by the Service registration form (the "Registration Data") and (b) maintain and promptly update the Registration Data to keep it true, accurate, current and complete. If you provide any information that is untrue, inaccurate, not current or incomplete, or Simply Made Local has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, Simply Made Local has the right to suspend or terminate your account and refuse any and all of your current or future use of the Service (or any portion thereof). Simply Made Local is concerned about the safety and privacy of all its users, particularly children. For this reason, and to be consistent with the terms of use of any FPM service provider or other third-party service providers, you must be at least 18 years of age or the legal age of majority where you reside if that jurisdiction has an older age of majority to register for an account.</p>
            <p>&nbsp;</p>
            <p>If it is determined or suspected by Simply Made Local, in its sole discretion, that you are misusing or attempting to misuse or circumvent the Service, or are using or attempting to use the Service for any inappropriate, infringing, illegal or fraudulent purpose, Simply Made Local reserves the right, in its sole discretion, to immediately terminate your access to the Service without notice and to initiate without notice appropriate legal actions or proceedings to seek appropriate remedies and/or damages, including but not limited to lost revenue, repairs, legal fees, costs and expenses, and to seek injunctions or other equitable remedies.</p>
            <p>&nbsp;</p>
            <p><strong>ACCOUNT, PASSWORD, AND SECURITY</strong></p>
            <p>&nbsp;</p>
            <p>As part of the Service registration process, you will create a password and account. You agree to: (a) provide accurate and current information about yourself as prompted by the Website (the "Account Information"); and (b) maintain and update the Account Information to keep it accurate and current. If you provide any information that is untrue, inaccurate, not current or incomplete, or we have reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, we may, in our sole discretion, suspend or terminate your account and decline to permit your continued use of the Website and future access to the Website.</p>
            <p>&nbsp;</p>
            <p>You are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer. You agree to notify us immediately of any unauthorized use of your account information or any other breach of security. Simply Made Local shall not, in any manner, be responsible or liable for fraudulent purchases that are made using your compromised Account Information. You agree to immediately notify Simply Made Local of any unauthorized use of your password or account or any other breach of security, and ensure that you exit from your account at the end of each session. Simply Made Local cannot and will not be held liable for any loss, damage, or other liability arising from your failure to comply with this Section.</p>
            <p>&nbsp;</p>
            <p>Simply Made Local uses commercially reasonable security measures to protect against the loss, misuse, and alteration of the information under our control, including credit card information that is submitted to Simply Made Local. When you place an order or access your account information, the Website uses secure server software (SSL), which encrypts all information you input before it is sent to us. We cannot guarantee that unauthorized third parties will never be able to defeat those security measures. Although we cannot guarantee that our systems are 100% secure 100% of the time, we periodically update and test our technology in order to improve the protection of customer information. Simply Made Local makes no representations or warranties with regard to the sufficiency of these security measures and, Simply Made Local shall not be responsible for any actual or consequential damages that result from a security breach.</p>
            <p>&nbsp;</p>
            <p><strong>CONDUCT</strong></p>
            <p>&nbsp;</p>
            <p>Whether you are using the Service as a Seller, Buyer, or otherwise, you understand that you are liable for all content, in whatever form, that you provide or otherwise make available to or through the Service, including to Sellers, Buyers and other users of the Service. You agree not to use the Service to:&nbsp;</p>
            <p>upload, post, email, transmit or otherwise make available any content that is unlawful, harmful, threatening, abusive, harassing, tortuous, defamatory, vulgar, obscene, libelous, invasive of another's privacy, hateful, or racially, ethnically or otherwise objectionable; harm minors in any way;</p>
            <p>impersonate any person or entity, including, but not limited to, a Simply Made Local representative or forum leader, or falsely state or otherwise misrepresent your affiliation with a person or entity;</p>
            <p>forge headers or otherwise manipulate identifiers in order to disguise the origin of any Content transmitted through the Service;</p>
            <p>upload, post, email, transmit or otherwise make available any Content that you do not have a right to make available under any law or contractual or fiduciary relationships (such as inside information, proprietary, and confidential information learned or disclosed as part of employment relationships or under nondisclosure agreements);</p>
            <p>upload, post, email, transmit or otherwise make available any Content that infringes any patent, trademark, trade secret, copyright or other intellectual property or proprietary rights of any person or entity;</p>
            <p>upload, post, email, transmit or otherwise make available any unsolicited or unauthorized advertising, promotional materials, junk mail, spam, chain letters, pyramid schemes, or any other form of solicitation, except in those areas that are designated for such purpose and within the scope of such designation;</p>
            <p>upload, post, email, transmit or otherwise make available any Content that contains software viruses, or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment;</p>
            <p>interfere with or disrupt the Service or servers or networks connected to the Service, or disobey any requirements, procedures, policies or regulations of networks connected to the Service;</p>
            <p>intentionally or unintentionally violate any applicable law or regulation (foreign or domestic), including without limitation (i) regulations promulgated by the US Securities and Exchange Commission, any rules of any national or other securities exchange (e.g., the New York Stock Exchange, the American Stock Exchange or the NASDAQ) and (ii) laws and regulations (foreign or domestic) regarding the sale or resale of goods (including without limitation with respect to licensure requirements, maximums or limits on ticket prices, etc.);</p>
            <p>or stalk or otherwise harass any person or entity.</p>
            <p>&nbsp;</p>
            <p><strong>MONITORING</strong></p>
            <p>&nbsp;</p>
            <p>Whether you are using the Service as a Seller, Buyer or otherwise, you acknowledge that Simply Made Local does not pre-screen any Content provided or made available by you or any third party in connection with the Service, but that Simply Made Local and its designees shall have the right (but not the obligation) in their sole discretion to (i) monitor alter, edit, or remove any of Your Content, in whole or in part, or (ii) rescind and terminate your right to use the Service for any reason or no reason. You acknowledge and agree that Simply Made Local may preserve Your Content and may also disclose Your Content for any reason, including without limitation if required to do so by law or in the good faith belief that such preservation or disclosure is reasonably necessary to: (a) comply with legal process; (b) enforce this Agreement; (c) respond to claims that any of Your Content violates the rights of third parties; or (d) protect the rights, property, or personal safety of Simply Made Local, its users and/or the public. You understand that the technical processing and transmission of the Service, including Your Content, may involve (i) transmissions over various networks; and (ii) changes to conform and adapt to technical requirements of connecting networks or devices.</p>
            <p>&nbsp;</p>
            <p><strong>SOFTWARE</strong></p>
            <p>&nbsp;</p>
            <p>You shall not, nor permit anyone else to, directly or indirectly: (i) modify or otherwise create derivatives of any part of the Service; (ii) reverse engineer, disassemble, decompile or otherwise attempt to discover the source code or structure, sequence, and organization of all or any part of the Service (except that this restriction shall not apply to the limited extent restrictions on reverse engineering are prohibited by applicable local law); (iii) rent, lease, resell, distribute or use the Service for timesharing, service bureau, or commercial purposes (except for the limited commercial purpose of selling event goods through the Service as an Seller); (iv) remove or alter any proprietary notices or labels on or in the Service; or (v) engage in any activity that interferes with or disrupts the Service.</p>
            <p>&nbsp;</p>
            <p>If you are allowed to download any software in connection with the Service ("Software"), subject to the terms and conditions of this Agreement, Simply Made Local grants you a personal, nontransferable, non-sublicensable, revocable, terminable, nonexclusive license to use the Software solely for your personal use, and only in accordance with this Agreement and the written instructions/directions (if any) provided by Simply Made Local. For clarity, the Software will be deemed a part of the "Service" hereunder.</p>
            <p>&nbsp;</p>
            <p><strong>RATINGS AND REVIEWS</strong></p>
            <p>&nbsp;</p>
            <p>Simply Made Local reserves the right, but not the obligation, to refuse to post or to remove any review or rating of any user of the Service if Simply Made Local determines (in its sole discretion) that it contains or features any of the following: (a) offensive, harmful and/or abusive language, including without limitation: expletives, profanities, obscenities, harassment, vulgarities, sexually explicit language and hate speech (e.g., racist/ discriminatory speech); (b) references to illegal activity; (c) reviews that do not address the goods and services of the business or reviews with no qualitative value; (d) language that violates the standards of good taste or the standards of Simply Made Local; (e) information or statements that are or appear to be false; or (h) comments that disparage Simply Made Local.</p>
            <p>&nbsp;</p>
            <p>All ratings and reviews of users on the Website reflect the opinions of others and do not reflect or represent the opinions or representations of Simply Made Local. Simply Made Local disclaims any and all representations or warranties with regard to the ratings and reviews. Reviews and ratings do not reflect the views of Simply Made Local or its affiliated companies; or its employees, officers, directors, or shareholders. Simply Made Local does not assume responsibility or liability for any review or rating.</p>
            <p>&nbsp;</p>
            <p><strong>PAYMENT METHODS</strong></p>
            <p>&nbsp;</p>
            <p>Payments are all transacted through Stripe services or other third-party payment service methods
              (collectively, &quot;Facilitated Payment Modes&quot; or &quot;FPM&quot;).</p>
            <p>&nbsp;</p>
            <p>Independent Relationship: When using an FPM, Sellers and Buyers affect the (a) applicable
              monetary payment transaction through the FPM service and are bound by the applicable terms of
              use governing the FPM service. Simply Made Local is not affiliated with and has no agency or
              employment relationship with any FPM service provider, Seller, Buyer, or any Seller&#39;s other
              customers. Simply Made Local has no responsibility for, and hereby disclaims all liability arising
              from, the acts or omissions of any FPM service provider, Seller, Buyer or any Seller&#39;s other
              customers. When using any other third-party service provider in conjunction with the Service, you,
              whether as a Seller, Buyer, or otherwise, are bound by the applicable terms of use governing the
              third-party service provider&#39;s Service. Simply Made Local is not affiliated with, and has no agency
              or employment relationship with, any third-party service providers, and Simply Made Local has no
              responsibility for, and hereby disclaims all liability arising from, the acts or omissions of any third-
              party service providers.</p>
            <p>&nbsp;</p>
            <p>Service Charges. Joining and setting up a basic level membership on Simply Made Local is free.
              Simply Made Local (b) charges a membership fee based upon the tier and services offered within
              the tier the Seller chooses. Simply Made Local does not charge a listing fee per item. Simply Made
              Local&#39;s Fees Policy, which is subject to change, is incorporated into this Agreement by reference.
              Changes to the Fees Policy and the fees for Simply Made Local&#39;s services are effective after Simply
              Made Local provides you with at least fourteen (14) days&#39; notice by posting the changes on the
              Website. However, Simply Made Local may choose to temporarily change the Fees Policy and the
              fees for Simply Made Local&#39;s services for promotional events (for example, trial memberships);
              such changes are effective when Simply Made Local posts the temporary promotional event on
              the Website. Simply Made Local may, at Simply Made Local&#39;s sole discretion, change some or all of
              Simply Made Local&#39;s services at any time. In the event Simply Made Local introduces a new
              service, the fees for that Service are effective at the launch of the Service. Unless otherwise
              stated, all fees are quoted in US Dollars (USD).</p>
            <p>&nbsp;</p>
            <p>Payment of Service Charges. Seller agrees to keep a valid credit card on file with Simply Made
              Local on which Simply Made Local will charge Seller monthly for membership charges incurred by
              Seller based on membership tier chosen by Seller. Seller is liable for all membership charges
              incurred. Seller will be automatically charged for membership services monthly. Notification will
              only be given for expiring credit cards and declined credit card services. Refunds will only be
              issued on an individual basis as determined by Simply Made Local. If Simply Made Local
              determines that a refund will be issued, a Five(5) dollar refund processing fee will be deducted
              from the refunded amount.</p>
            <p>&nbsp;</p>
            <p><strong>TAXES</strong></p>
            <p>&nbsp;</p>
            <p>If you are using the Service as a Seller, you are responsible for (and will indemnify Simply Made Local against) all taxes associated with your sale of goods through the.</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p><strong>SIMPLY MADE LOCAL CONTENT</strong></p>
            <p>&nbsp;</p>
            <p>You agree that all material, including without limitation information, data, software, text, design elements, graphics, images and other content (collectively, "Content"), contained in or delivered via the Service or otherwise made available by Simply Made Local at the Website (collectively, "Website Content") is protected by copyrights, trademarks, service marks, trade secrets or other intellectual property and other proprietary rights and laws. Simply Made Local may own the Website Content or portions of the Website Content may be made available to Simply Made Local through arrangements with third parties. Except as expressly authorized by Simply Made Local in writing, you agree not to sell, license, rent, modify, distribute, copy, reproduce, transmit, publicly display, publicly perform, publish, adapt, edit or create derivative works of any Website Content, or post any Website Content on any other web site or in a networked computer environment for any purpose. However, you may print or download a reasonable number of copies of the Website Content for your own informational purposes, provided that you retain all copyright and other proprietary notices contained therein. Reproducing, copying or distributing any Website Content for any other purpose is strictly prohibited without the express prior written permission of Simply Made Local. You shall use the Website Content only for purposes that are permitted by this Agreement and any applicable laws and regulations (foreign and domestic). Any rights not expressly granted herein are reserved.</p>
            <p>&nbsp;</p>
            <p><strong>TRADEMARK INFORMATION</strong></p>
            <p>&nbsp;</p>
            <p>The trademarks, service marks, and logos of Simply Made Local (the "Simply Made Local Trademarks") used and displayed in connection with the Service are trademarks, trade names or service marks of Simply Made Local. Other third-party trademarks, trade names or services marks used in connection with the Service may be trademarks or service marks owned by third parties (the "Third-Party Trademarks.") Third-Party Trademarks and Simply Made Local Trademarks shall be referred to collectively as "Trademarks." Nothing contained in this Agreement, nor your use of the Service, shall constitute or be construed as granting, by implication, estoppel, or otherwise, any license or right to use any Trademark displayed in connection with the Service without the prior written consent of Simply Made Local specific for each such use. You further agree not to use any Trademark to disparage Simply Made Local, the Service, or any third party, or third party products or services, in any manner that, in Simply Made Local's sole reasonable judgment, may damage any goodwill in the Trademarks. Use of any Trademarks as part of a link to or from any site is prohibited unless Simply Made Local approves the establishment of such a link by prior written consent. All goodwill generated from the use of any Simply Made Local Trademark shall inure to Simply Made Local's benefit.</p>
            <p>&nbsp;</p>
            <p><strong>USER CONTENT</strong></p>
            <p>&nbsp;</p>
            <p>You acknowledge and agree that if you contribute, provide or make available any Content to the Website ("Your Content"), whether as a Seller, Buyer or otherwise, you hereby grant to Simply Made Local a non-exclusive, worldwide, perpetual, irrevocable, royalty-free, sublicensable (through multiple tiers) right to exercise the copyright, publicity, and database rights (but no other rights) you have in the Content, in any media now known or not currently known, with respect to your Content. You agree to allow Simply Made Local to store, translate, or re-format your Content on Simply Made Local and display your Content on Simply Made Local in any way Simply Made Local chooses without compensation to you or any other provider of Your Content. You represent and warrant that you have all the rights, power and authority necessary to grant the foregoing license, and that all Your Content (i) does not infringe, violate, misappropriate or otherwise conflict with the rights of any third party, and (ii) complies with all applicable laws and regulations (foreign and domestic). Simply Made Local reserves the right to remove any of Your Content from the Website at any time for any reason (including, but not limited to, upon receipt of claims or allegations from third parties or authorities relating to Your Content) or no reason.</p>
            <p>&nbsp;</p>
            <p><strong>THE DIGITAL MILLENNIUM COPYRIGHT ACT (DMCA) POLICY</strong></p>
            <p>&nbsp;</p>
            <p>The Digital Millennium Copyright Act of 1998 (the "DMCA") provides recourse for copyright owners who believe that material appearing on the Internet infringes their rights under US copyright law. If you believe in good faith that any Content made available in connection with the Service infringes your copyright, you (or your agent) may send us a notice requesting that the Content be removed, or access to it blocked. Notices and counter-notices must meet the then-current statutory requirements imposed by the DMCA (see <a href="http://www.loc.gov/copyright">http://www.loc.gov/copyright</a> for details). Notices and counter-notices with respect to the Service should be sent to Simply Made Local.</p>
            <p>&nbsp;&nbsp;</p>
            <p>By email:&nbsp;support@SimplyMadeLocal.com</p>
            <p>&nbsp;</p>
            <p><strong>INDEMNITY</strong></p>
            <p>&nbsp;</p>
            <p>You agree to defend, indemnify and hold Simply Made Local, and its affiliates, and their officers, agents, co-branders or other partners, and employees, harmless from any and all damage, loss, liability, cost and expense (including, without limitation, reasonable attorneys' and accounting fees) resulting from any claim, demand, suit or proceeding made by any third party ("Claim") due to or arising out of: Your Content; your use of, contribution to or connection with the Service (including the Referral Software); your violation of this Agreement; or your violation of any rights of another. Simply Made Local shall provide notice to you of any such Claim. Simply Made Local reserves the right to assume the exclusive defense and control of any matter which is subject to indemnification under this Section, and in such case, you agree to cooperate with all reasonable requests in assisting Simply Made Local's defense of such matter.</p>
            <p>&nbsp;</p>
            <p><strong>TERMINATION</strong></p>
            <p>&nbsp;</p>
            <p>Simply Made Local, in its sole discretion, may terminate your password, account (or any part thereof) and/or your right to use the Service, and remove and discard any and all of Your Content within the Service, for any reason or no reason, including, without limitation, for lack of use, failure to timely pay any fees or other monies due Simply Made Local, or if Simply Made Local believes that you have violated or acted inconsistently with the letter or spirit of this Agreement. You agree that any termination of your right to use the Service may be effected without prior notice, and acknowledge and agree that Simply Made Local may immediately deactivate or delete your account and all related Content and files related to your account and/or bar any further access to such files or the Service. Further, you agree that Simply Made Local shall not be liable to you or any third-party for any termination of your right to use or otherwise access the Service. All provisions of this Agreement that by their nature should survive termination of your right to use the Service shall survive (including, without limitation, all limitations on liability, releases, indemnification obligations, disclaimers of warranties, and intellectual property protections).</p>
            <p>&nbsp;</p>
            <p><strong>LINKS</strong></p>
            <p>&nbsp;</p>
            <p>The Service may provide, or third parties may provide, links to other Internet websites or resources. Because Simply Made Local has no control over such websites and resources, you acknowledge and agree that Simply Made Local is not responsible for the availability of such websites or resources, and does not endorse and is not responsible or liable for any Content, advertising, products, services or other materials on or available from such websites or resources. You further acknowledge and agree that Simply Made Local shall not be responsible or liable for any damage or loss caused or alleged to be caused by or in connection with any use of or reliance on any such Content, advertising, products, services or other materials available on or through any such website or resource.</p>
            <p>&nbsp;</p>
            <p><strong>DISCLAIMER OF WARRANTIES</strong></p>
            <p>&nbsp;</p>
            <p>THE SERVICE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. SIMPLY MADE LOCAL HEREBY EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE, NON-INFRINGEMENT, AND FITNESS FOR A PARTICULAR PURPOSE. SIMPLY MADE LOCAL MAKES NO WARRANTY THAT: (i) THE SERVICE WILL MEET YOUR REQUIX TO PERFORM, OR ACTUALLY COMPLETE A TRANSACTION. THE FOREGOING DISCLAIMERS SHALL NOT APPLY TO THE EXTENT PROHIBITED BY APPLICABLE LAW. Notwithstanding the foregoing, you may report the misconduct of users, Buyers, Sellers, and/or third parties in connection with the Website or any other service to Simply Made Local. Simply Made Local, in its sole discretion, may investigate the claim and take necessary action.</p>
            <p>&nbsp;</p>
            <p><strong>LIMITATION OF LIABILITY</strong></p>
            <p>&nbsp;</p>
            <p>Simply Made Local shall not be liable with respect to the Service, or any other subject matter of this Agreement, for any: (i) indirect, incidental, special, consequential or exemplary damages, including, but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses (even if Simply Made Local has been advised of the possibility of such damages), (ii) the cost of procurement of substitute services, (iii) amounts in the aggregate in excess of us$100.00, or (iv) any matters beyond simply made local's reasonable control. Simply Made Local shall have no liability with respect to any of your content or any content of any other user of the Service. The foregoing limitations shall not apply to the extent prohibited by applicable law.</p>
            <p>&nbsp;</p>
            <p><strong>RELEASE</strong></p>
            <p>&nbsp;</p>
            <p>If you have a dispute with one or more users, you release Simply Made Local (and our affiliates and their officers, directors, agents, joint ventures and employees) from claims, demands and damages (actual and consequential) of every kind and nature, known and unknown, arising out of or in any way connected with such disputes. If you are a California resident, you waive California civil code 1542, which says: "a general release does not extend to claims which the creditor does not know or suspect to exist in his favor at the time of executing the release, which if known by him must have materially affected his settlement with the debtor."</p>
            <p>&nbsp;</p>
            <p><strong>PRIVACY</strong></p>
            <p>&nbsp;</p>
            <p>All information provided by you or collected by Simply Made Local in connection with the Service is governed by Simply Made Local's Privacy Policy, a copy of which is located at <a href="http://www.SimplyMadeLocal.com/privacy">http://www.SimplyMadeLocal.com/privacy</a>, which is hereby incorporated by reference into this Agreement.</p>
            <p>&nbsp;</p>
            <p>Any information submitted or provided by you to the Service may be publicly accessible. You should take care to protect private information or information that is important to you. Simply Made Local shall not be responsible for protecting any such information and is not liable for the protection of privacy of electronic mail or other information transferred through the Internet or any other network that you may use. Please be aware that if you decide to disclose personally identifiable information on the Service, this information may become public. Simply Made Local does not control and shall not be responsible for the acts of you or any other users of the Service.</p>
            <p>&nbsp;</p>
            <p><strong>NOTICE</strong></p>
            <p>&nbsp;</p>
            <p>Notices to you may be made via either email or regular mail. The Service may also provide notices of changes to this Agreement or other matters by displaying notices or links to notices to you generally on the Service.</p>
            <p>&nbsp;</p>
            <p><strong>APPLICABLE LAW/INTERNATIONAL ISSUES</strong></p>
            <p>&nbsp;</p>
            <p>Information that is submitted to the Website will be collected, processed, stored, disclosed, and disposed of in accordance with applicable US law. If you are a non-U.S. user, you acknowledge and agree that we may collect and use your information, as discussed above, outside your resident jurisdiction. In addition, such Information may be stored on servers located outside your resident jurisdiction. US law may not provide the degree of protection for Information that is available in other countries. By providing us with your Information, you acknowledge that you have read this Agreement, understand it, agree to its terms and consent to the transfer of such Information outside your resident jurisdiction. IF YOU DO NOT AGREE WITH ALL OF THESE TERMS OF USE, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SITE AND YOU MUST DISCONTINUE USE IMMEDIATELY. &nbsp;</p>
            <p>&nbsp;</p>
            <p><strong>GENERAL</strong></p>
            <p>&nbsp;</p>
            <p>Entire Agreement. This Agreement constitutes the entire agreement between</p>
            <p>(a) You and Simply Made Local; and governs your use of the Service, superseding any prior agreements or contemporaneous communications between you and Simply Made Local. You also may be subject to additional terms and conditions that may apply when you use affiliate services, third-party content, or third-party software.</p>
            <p>(b) Choice of Law. This agreement, and the provision of the service to you, are governed by the laws of the state of California, USA.</p>
            <p>(c) Governing Language. Any translation of this Agreement is done for local requirements and in the event of a dispute between the English and any non-English versions, the English version of this Agreement shall govern.</p>
            <p>(d) Arbitration. Any controversy or claim arising out of or relating to this agreement or the provision of the service shall be finally settled by binding arbitration in accordance with the commercial arbitration rules of the Judicial Arbitration and Mediation Service, Inc. Any such controversy or claim shall be arbitrated on an individual basis and shall not be consolidated in any arbitration with any claim or controversy of any other party. The arbitration shall be conducted in the County of Riverside, California, and judgment on the arbitration award may be entered in any court having jurisdiction thereof. However, subject to the foregoing arbitration provision, you and Simply Made Local agree to submit to the personal jurisdiction of the courts located within the city and county of Riverside, California. Either you or Simply Made Local may seek any interim or preliminary relief from a court of competent jurisdiction in the County of Riverside, California, necessary to protect the rights or property of you or Simply Made Local (or its agents, suppliers, and subcontractors) pending the completion of arbitration.</p>
            <p>(e) Invalid Provisions. The failure of Simply Made Local to exercise or enforce any right or provision of this Agreement shall not constitute a waiver of such right or provision. If any provision of this Agreement is found by an arbitrator or court of competent jurisdiction to be invalid, the parties nevertheless agree that the arbitrator or court should endeavor to give effect to the parties' intentions as reflected in the provision, and the other provisions of this Agreement remain in full force and effect.</p>
            <p>(f) Time to File Claim. You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of the Service or this Agreement must be filed within two (2) years after such claim or cause of action arose or be forever barred.</p>
            <p>(g) Violations. Please send queries or reports of any violations of this Agreement to&nbsp;support@SimplyMadeLocal.com.</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
          </div>
        </DefaultLayout>
    );
}

import React, { useState, useEffect, useReducer } from "react";

// STYLING
import "./profile_messages.scss";

// UTILITY
import { useCtxProvider } from "../../../context/context.js";

// COMPONENTS
import Spinner from "../../../components/global/Spinner.js";
import ShortMessage from "./ShortMessage.js";

export default function ProfileMessages(_props) {
    
    const { unread_messages, user, user_channel, is_connected } = useCtxProvider();
    
    const [loading, set_loading] = useState(true);
    const [loading_more, set_loading_more] = useState(false);
    
    const [ctx, dispatch] = useReducer(threads_reducer, {messages: [], has_more: false});

    useEffect(() => {
        if (is_connected) { get_chats(0); }
    }, [user_channel, is_connected]);

    useEffect(() => {
        /* we use a specific eventListener and not the default channel.on... same same as in ChatThread.js and is explained there. */
        window.addEventListener("ctx_new_message", add_to_messages);
        
        return () => window.removeEventListener("ctx_new_message", add_to_messages);
    }, []);

    function get_chats(last_id) {
        user_channel.push("get_all_chats", {last_id: last_id})
                    .receive("ok", ({chats, has_more}) => {
                        console.log("get_all_chats", chats);
                        if (last_id > 0) {
                            dispatch({type: "add_chats", value: chats});
                        } else {
                            dispatch({type: "set_chats", value: chats});
                        }

                        dispatch({type: "set_has_more", value: has_more});
                        set_loading(false);
                        set_loading_more(false);
                    })
                    .receive("error", () => {
                        console.log(`Error fetching last messages for user`);
                        set_loading(false);
                        set_loading_more(false);
                    });
    };

    function load_previous() {
        set_loading_more(true);
        let last_id = ctx.messages[ctx.messages.length - 1].chat_id;
        get_chats(last_id);
    };

    function add_to_messages({detail: msg}) {
        console.log("received msg in threads", msg); 
        if (msg && msg.to_id === user.id) {
            dispatch({type: "new_message", value: msg});
        }
    };


    return (
        loading ?
        <Spinner classes="spinner-full" /> :
        <div className="profile-self-content">
            <div className="profile-view-title-row">
                <h3>Messages <span>You have {unread_messages.total} unread conversations</span></h3>
            </div>
            <div className="profile-messages-container">
                {ctx.messages.map((chat) => <ShortMessage key={chat.chat_id} message={chat} your_id={user.id} />)}
            </div>
            {
                ctx.has_more && !loading_more ?
                <button type="button" className="link color-secondary mx-auto chat-message-row" style={{fontSize: "18px", textAlign: "center"}} onClick={() => load_previous()}>Load previous threads...</button> :
                loading_more ?
                <Spinner /> :
                "" 
            }
        </div>
    );
};

function threads_reducer(state, action) {
    switch (action.type) {
        case "set_chats":
            return {...state, messages: action.value};
        case "add_chats":
            return {...state, messages: [...action.value, ...state.messages]};
        case "new_message":
            let new_message = action.value;
            let { deduped_chats } = [new_message, ...state.messages].reduce(({deduped_chats, existing_chats}, message) => {
                if (!existing_chats[message.chat_id]) {
                    existing_chats[message.chat_id] = true;
                    deduped_chats.push(message);
                }
                return {deduped_chats, existing_chats};
            }, {deduped_chats: [], existing_chats: {}});
            
            state.messages = [...deduped_chats].sort((a, b) => {
                return (a.chat_id > b.chat_id) ? 1 : (b.chat_id > a.chat_id ? -1 : 0);
            });
            return {...state};
        case "has_more":
            return {...state, has_more: action.value};
        default:
            return state;
    };
};


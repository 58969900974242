import React from "react";

import { Link } from "react-router-dom";

// STYLES
import "./boxed_components.scss";

// UTILITY
import { font_sizing } from "../../js-utilities/text_helpers.js";

// COMPONENTS

export default function BoxedItems({ elements, title }) {
    return (
        <div className="boxed-items-wrapper">
            <p className="boxed-items-header">{title}</p>
            <div className="boxed-items-subheader"><Link className="boxed-items-action btn btn-white" to={'/blog'}>Browse All <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.42 12.7"><g><g><polyline className="arrow-primary" points="6.17 0.71 11.81 6.35 6.17 11.99"/><polyline className="arrow-primary" points="14.37 0.71 20.01 6.35 14.37 11.99"/><line className="arrow-primary" y1="6.35" x2="19.34" y2="6.35"/></g></g></svg></Link></div>
            <div className="boxed-items-container">
                {elements.map(({ picture, title, subtitle, highlight, action: { text, to }}) => {
                    let title_size = font_sizing(48, 27, title);
                    return <div className="boxed-item" style={{backgroundImage: `url(${picture})`}} key={title}>
                        <div className="boxed-item-card" style={{backgroundColor: highlight}}>
                            <div className="boxed-item-title" style={{fontSize: `${title_size}px`}}>
                                {title}
                            </div>
                            <hr />
                            <div className="boxed-item-subtitle">
                                {subtitle}
                            </div>
                        </div>
                        <Link className="boxed-item-action btn btn-white" to={to}>{text} <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.42 12.7"><g><g><polyline className="arrow-primary" points="6.17 0.71 11.81 6.35 6.17 11.99"/><polyline className="arrow-primary" points="14.37 0.71 20.01 6.35 14.37 11.99"/><line className="arrow-primary" y1="6.35" x2="19.34" y2="6.35"/></g></g></svg></Link>
                    </div>
                })}
            </div>
        </div>
    );
};

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// STYLING
import "./event_attendees_section.scss";

// UTILITY
import { image_url, BACKEND_URL } from "../../env/env.js";
import { get_display_name }       from "../../context/user_helpers.js";
import { font_sizing }            from "../../js-utilities/text_helpers.js";

// COMPONENTS
import Spinner from "../../components/global/Spinner.js";
import DoubleArrow from "../../components/svg_elements/DoubleArrow.js";
import ProfileAvatar from "../profile/shared/ProfileAvatar.js";

const axios = require("axios");

export default function EventAttendeesSection({ title, type, event, history, totals, is_attending, user }) {
    
    const [loading, set_loading] = useState(true);
    const [attendees, set_attendees] = useState(false);
    const [has_more, set_has_more] = useState(false);
    const [total_more, set_total_more] = useState(false);
    const [last_id, set_last_id] = useState(0);
    const [complete_attendees, set_complete_attendees] = useState(attendees);


    useEffect(() => {
        get_attendees(set_attendees);
    }, []);

    useEffect(() => {
        
        var new_attendees,
            n_last_id;
        
        if (user && attendees && type.includes(user.type)) {
            
            if (is_attending === true) {
                
                [new_attendees, , n_last_id] = [user, ...attendees].reduce(([acc_attendees, map_attendees, acc_last_id], attendee) => {

                    if (!map_attendees[attendee.id]) {
                        map_attendees[attendee.id] = attendee;
                        acc_attendees.push(attendee);
                        acc_last_id = attendee.id > acc_last_id ? attendee.id : acc_last_id;
                    }
                    return [acc_attendees, map_attendees, acc_last_id];
                }, [[],{}, 0]);

                set_complete_attendees(new_attendees);
                set_last_id(n_last_id);

            } else if (is_attending === false) {
                [new_attendees, , n_last_id] = attendees.reduce(([acc_attendees, map_attendees, acc_last_id], attendee) => {

                    if (!map_attendees[attendee.id] && attendee.id !== user.id) {
                        map_attendees[attendee.id] = attendee;
                        acc_attendees.push(attendee);
                        acc_last_id = attendee.id > acc_last_id ? attendee.id : acc_last_id;
                    }
                    return [acc_attendees, map_attendees, acc_last_id];
                }, [[],{}, 0]);

                set_complete_attendees(new_attendees);
                set_last_id(n_last_id);
            }
        }
    }, [is_attending, attendees, user]);

    useEffect(() => {
        if (complete_attendees && totals[type] > complete_attendees.length) {
            set_total_more(totals[type] - complete_attendees.length);
        } else {
            set_total_more(0);
        }
        if (complete_attendees) {
            set_last_id(complete_attendees.reduce((acc, { id }) => id > acc ? id : acc, 0));
        }
    }, [totals, complete_attendees]);

    function get_attendees(attendees_callback) {
        let previous_id = last_id;
        axios({
            method: "GET",
            url: `${BACKEND_URL()}/api/v1/events/attendees/${event.id}/${type}/${previous_id}`,
        })
            .then(({data}) => {
                if (data.success) {
                    attendees_callback(data.attendees);
                    set_has_more(data.has_more);
                    set_loading(false);
                }
            })
            .catch((e) => {
                set_loading(false);
            });
    };

    function load_more() {
        set_loading(true);
        get_attendees(add_attendees);
    };

    function add_attendees(new_attendees) {
        set_attendees([...attendees, ...new_attendees]);
    };
    
    return (
        loading ?
        <Spinner classes="spinner-full" /> :
        (attendees ?
         <div className="event-section">
             <p className="event-section-title">{title}</p>
             {
                 (complete_attendees.length > 0) ?
                 <div className="event-attendees-list">
                     {
                         complete_attendees.map((attendee) => {
                             switch(type) {
                                 case "buyers":
                                     return BuyerAttendee(attendee);
                                 case "sellers":
                                     return SellerAttendee(attendee);
                             }
                         })
                     }
                     {
                         total_more ?
                         <button
                             type="button"
                             className="event-attendee link-text-inverse color-primary"
                             style={{fontSize: "24px", justifyContent: "center"}}
                             onClick={load_more}
                         >
                             {total_more} more...
                         </button> :
                         ""
                     }
                 </div> :
                 <p className="t-center my-2">Currently no attendees</p>
                 
             }
         </div> :
         ""
        )
    );
};

function BuyerAttendee(user) {
    let display_name = get_display_name(user);
    
    return (
        <div className="event-attendee attendee-list-buyer" key={user.id}>
            <ProfileAvatar user={user} />
            <p className="attendee-name" style={{fontSize: `${font_sizing(26, 30, display_name)}px`}}>{display_name}</p>
        </div>
    );
};

function SellerAttendee(user) {
    console.log("sellerattendee", user);
    let display_name = get_display_name(user);
    
    return (
        <div className="event-attendee attendee-list-seller" key={user.id}>
            <ProfileAvatar user={user} />
            <p className="attendee-name" style={{fontSize: `${font_sizing(26, 20, display_name)}px`}}>{display_name}</p>
            <Link className="color-primary" to={`/seller/${user.slug}`}>
                Visit Shop
                <DoubleArrow />
            </Link>
        </div>
    );
};

import React, { useState, useReducer, useEffect } from "react";
import { Link, Route, Switch } from "react-router-dom";

// STYLES
import "./index.scss";

// UTILITY
import { add_error_to, remove_error_from } from "../../js-utilities/error_arrays.js";

import { useCtxProvider } from "../../context/context.js";

// COMPONENTS
import Logo from "../../components/global/Logo.js";
import Spinner from "../../components/global/Spinner.js";

// Route components
import Step1 from "./steps/Step1.js";
import Step2Buyer from "./steps/Step2Buyer.js";

import Step2Seller from "./steps/Step2Seller.js";
import Step3Seller from "./steps/Step3Seller.js";
import Step4Seller from "./steps/Step4Seller.js";
import Step5Seller from "./steps/Step5Seller.js";

import Verification from "./steps/Verification.js";
import Finished    from "./steps/Finished.js";

const initial_wizard = {
    step: 1,
    type: false,
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    verified_number: false,
    profile_image: false,
    company_name: "",
    company_logo: false,
    company_cover: false,
    cc_name: "",
    tos_pp: false,
    plan: false,
    success: false,
    coupon: false,
    errors: {
        first_name: [],
        last_name: [],
        email: [],
        phone_number: [],
        profile_image: [],
        company_name: [],
        business_logo: [],
        business_cover: []
    }
};

export default function Signup({ history }) {

    const [loading, set_loading] = useState(true);
    const [maybe_check_step, set_maybe_check_step] = useState(false);

    const { user, is_verifying_user } = useCtxProvider();

    const [wizard_state, dispatch_wizard] = useReducer(wizard_reducer, {...initial_wizard});

    useEffect(() => {

        if (user && wizard_state.step !== "finished") {
            set_maybe_check_step(true);
        }
        else if (!is_verifying_user) { set_loading(false); }

    }, [user, is_verifying_user]);

    useEffect(() => {
        history.push(get_step_route(wizard_state));
    }, [wizard_state.step]);

    useEffect(() => {
        if (maybe_check_step) {
            if (wizard_state.step !== "finished") {
                history.push("/account/profile");
            } else {
                set_maybe_check_step(false);
            }
        }
    }, [maybe_check_step]);

    return (
        loading ?
        <Spinner classes="spinner-full" /> :
        <div className="signup-container">
            <div className="signup-container-top">
                <Logo width="100%" height="auto" />
                <h2 className="signup-title">Sign Up</h2>
            </div>
            <div className="signup-container-panel">
                <Switch>
                    <Route path="/signup/seller/step/5" render={(_) => <Step5Seller dispatch={dispatch_wizard} state={wizard_state} />} />
                    <Route path="/signup/seller/step/4" render={(_) => <Step4Seller dispatch={dispatch_wizard} state={wizard_state} />} />
                    <Route path="/signup/seller/step/3" render={(_) => <Step3Seller dispatch={dispatch_wizard} state={wizard_state} />} />
                    <Route path="/signup/seller/step/2" render={(_) => <Step2Seller dispatch={dispatch_wizard} state={wizard_state} />} />
                    <Route path="/signup/buyer/step/2" render={(_) => <Step2Buyer dispatch={dispatch_wizard} state={wizard_state} />} />
                    <Route path="/signup/step/1" render={(_) => <Step1 dispatch={dispatch_wizard} state={wizard_state} />} />
                    <Route path="/signup/:account_type/step/verification" render={(_) => <Verification dispatch={dispatch_wizard} state={wizard_state} />} />
                    <Route path="/signup/:account_type/step/finished" render={(_) => <Finished dispatch={dispatch_wizard} state={wizard_state} />} />
                    <Route path="/signup" render={(_) => <Step1 dispatch={dispatch_wizard} state={wizard_state} />} />
                </Switch>
            </div>
            <div className="signup-container-footer">
                <p>© Copyright 2020 Simply Made Local</p>
                <Link to="/terms-of-service" className="link-text-inverse">Terms of Service</Link>
                <Link to="/privacy-policy" className="link-text-inverse">Privacy Policy</Link>
            </div>
        </div>
    );
};

function wizard_reducer(state, action) {
    var new_state;
    switch(action.type) {
        case "select_type":
            new_state = {...state, type: action.value, step: 2};
            return new_state;
        case "set_step":
            let step = action.step;
            let account_type = action.step === 1 ? undefined : action.account_type;
            return {...state, type: account_type, step: step};
        case "set_field":
            state[action.field] = action.value;
            return {...state};
        case "set_picture":
            state[action.field] = action.value;
            return {...state};
        case "show_error":
            state.errors[action.field] = add_error_to(state.errors[action.field], action.value, action.field, action.dispatcher);
            state.errors = {...state.errors};
            return {...state}
        case "remove_error":
            state.errors[action.field] = remove_error_from(state.errors[action.field], action.id);
            state.errors = {...state.errors};
            return {...state}
        case "reset":
            return {...initial_wizard};
        default:
            return state;
    };
};

function get_step_route({step, type}, params_placeholder) {
    switch(step) {
        case 1:
            return `/signup/step/${params_placeholder ? ":step" : "1"}`;
        default:
            let new_type = params_placeholder ? ":account_type" : type;
            let new_step = params_placeholder ? ":step" : step;
            return `/signup/${new_type}/step/${new_step}`;
    };
};

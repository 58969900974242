import { create_error_element } from "../../js-utilities/error_arrays.js";
import { plans } from "../../env/plans.js";

export const phone_regex = /^[0-9]{3}[-\s]?[0-9]{3}[-\s]?[0-9]{4}$/;
export const verified_number_regex = /^\+.+?[0-9]{3}[-\s]?[0-9]{3}[-\s]?[0-9]{4}$/;
export const email_regex = /.+?[@].+?/;

const buyer_valid_fields = [
    "phone_number",
    "first_name",
    "last_name",
    "profile_image",
    "email",
    "type"
];

const seller_valid_fields = [
    "phone_number",
    "first_name",
    "last_name",
    "profile_image",
    "email",
    "city",
    "state",
    "company_name",
    "company_logo",
    "company_cover",
    "plan",
    "type"
];

const buyer_non_sendable_fields = ["profile_image", "type"];

const buyer_update_fields = [...buyer_valid_fields].filter((field) => {
    return !buyer_non_sendable_fields.includes(field);
});

const seller_non_sendable_fields = ["profile_image", "company_logo", "company_cover", "plan", "type"];

const seller_update_fields = [...seller_valid_fields].reduce((acc, field) => {
    return !seller_non_sendable_fields.includes(field);
});


export function requirements() {
    return {
        valid_fields: {
            buyer: buyer_valid_fields,
            seller: seller_valid_fields
        },
        to_update_fields: {
            buyer: buyer_update_fields,
            seller: seller_update_fields
        },
        required_fields: {
            buyer: [
                {
                    field: "phone_number",
                    fun: (value) => {
                        return phone_regex.test(value);
                    },
                    error: (value) => {
                        return create_error_element({msg: `Expected a valid USA phone number, in the form of XXX-XXX-XXXX (with or without hyphens or spaces), got ${value}`, ttl: 7500, field: "Phone Number"});
                    }
                },
                {
                    field: "first_name",
                    fun: (value) => {
                        return value !== "" && (typeof value === "string" || value instanceof String);
                    },
                    error: (value) => {
                        return create_error_element({msg: "You need to fill your First Name", ttl: 7500, field: "First Name"});
                    }
                },
                {
                    field: "type",
                    fun: (value) => { return value === "buyer" },
                    error: (value) => {
                        return create_error_element({msg: "It seems the type of account isn't valid", ttl: 7500, field: "Account Type"});
                    }
                },
                {
                    field: "tos_pp",
                    fun: (value) => value === true,
                    error: (value) => {
                        return create_error_element({msg: "You need to agree to Simply Made Local's Terms of Service and Privacy Policy", ttl: 7500, field: "Tos & PP"});
                    }
                }
            ],
            seller: [
                { 
                    field: "phone_number",
                    fun: (value) => {
                        return phone_regex.test(value);
                    },
                    error: (value) => {
                        return create_error_element({msg: `Expected a valid USA phone number, in the form of XXX-XXX-XXXX (with or without hyphens or spaces), got ${value}`, ttl: 7500, field: "Phone Number"});
                    }
                },
                {
                    field: "first_name",
                    fun: (value) => {
                        return value !== "" && (typeof value === "string" || value instanceof String);
                    },
                    error: (value) => {
                        return create_error_element({msg: "You need to fill your First Name", ttl: 7500, field: "First Name"});
                    }
                },
                {
                    field: "last_name",
                    fun: (value) => {
                        return value !== "" && (typeof value === "string" || value instanceof String);
                    },
                    error: (value) => {
                        return create_error_element({msg: "You need to fill your Last Name", ttl: 7500, field: "Last Name"});
                    }
                },
                {
                    field: "profile_image",
                    fun: (value) => {
                        return value instanceof File;
                    },
                    error: (value) => {
                        return create_error_element({msg: "You need to choose a file for your profile image", ttl: 7500, field: "Profile Image"});
                    }
                },
                {
                    field: "email",
                    fun: (value) => {
                        return email_regex.test(value);
                    },
                    error: (value) => {
                        return create_error_element({msg: "You need a valid email address", ttl: 7500, field: "Email"});
                    }
                },
                {
                    field: "type",
                    fun: (value) => { return value === "seller" },
                    error: (value) => {
                        return create_error_element({msg: "It seems the type of account isn't valid", ttl: 7500, field: "Account Type"});
                    }
                },
                {
                    field: "plan",
                    fun: (value) => {
                        return plans.reduce((acc, plan) => {
                            return (plan.name === value && !plan.disabled) || acc ? true : false;
                        }, false);
                    },
                    error: (value) => {
                        return create_error_element({msg: "It seems the plan you chose isn't valid", ttl: 7500, field: "Subscription Plan"});
                    }
                },
                {
                    field: "tos_pp",
                    fun: (value) => value === true,
                    error: (value) => {
                        return create_error_element({msg: "You need to agree to Simply Made Local's Terms of Service and Privacy Policy", ttl: 7500, field: "Tos & PP"});
                    }
                }
            ]
        }
    }
};

export function check_verified_number(number) {
    if (typeof number === "string") {
        return verified_number_regex.test(number);
    } else {
        return false;
    }
};

export function check_requirements(required_fields, state) {
    return required_fields.reduce(([valid, errors], {field, fun, error}) => {
        let valid_field = fun(state[field]);
        if (valid && valid_field) { return [true, []]; }
        else if (valid_field) { return [false, errors]; }
        else {
            let error_obj = error(state[field]);
            return [false, [...errors, error_obj]]; }
    }, [true, []]);
};

export const number_of_digits = 6;

var digits_array = [];

for (let i = 0; i < number_of_digits; i++) {
    digits_array.push("");
};

export const empty_digits_array = [...digits_array];

import React from "react";
import axios from "axios";

// STYLES
import "./index.scss";

// UTILITY
import { BACKEND_URL } from "../../env/env.js";

// COMPONENTS
import DefaultLayout from "../../layouts/default.js";

const CONTACT_API_ENDPOINT = `${BACKEND_URL()}/api/v1/contact`;

export default class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      text: "",
      message: ""
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    axios.post(CONTACT_API_ENDPOINT, this.state)
    .then((res) => {
      this.setState({
        message: "Message sent!",
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        text: ""
      })
      window.scrollTo(0, 0);
    })
    .catch((res) => {
      this.setState({message: "Sorry! There was a server error. Please try again later."});
      window.scrollTo(0, 0);

    })
  }

  onChangeHandler = (e) => {
    const { name, value } = e.target;
    const obj = {};
    obj[name] = value;
    this.setState(obj)
  }

  render() {
    const { message } = this.state;
    var messageExists = message.length > 0;
    return (
        <DefaultLayout>
            <div className="contact-cover">
              <div className="contact-cover__title">
                <h1>Contact</h1>
              </div>
            </div>
            <div className="contact-container">
              {messageExists > 0 && <div className="contact-message">
                <span>{this.state.message}</span>
              </div>}
              <div className="contact-text-container">
                  <h1 className="contact-header">Have a question?</h1>
                  <p className="contact-text">Is something not working the way it should? Or maybe you just want to say hello? We're here to help! Just fill out the form below and we'll get back to you as soon as we can!</p>
              </div>
              <form className="signup-container-form">
                <input
                    type="text"
                    id="first-name"
                    name="first_name"
                    className="text-center mx-auto mt-5"
                    style={{width: "479px", maxWidth: "90%"}}
                    autoComplete="first-name"
                    placeholder="First Name"
                    required
                    onChange={this.onChangeHandler}
                    value={this.state.first_name}
                />
                <input
                    type="text"
                    id="last-name"
                    name="last_name"
                    className="text-center mx-auto mt-5"
                    style={{width: "479px", maxWidth: "90%"}}
                    autoComplete="last-name"
                    placeholder="Last Name"
                    required
                    onChange={this.onChangeHandler}
                    value={this.state.last_name}
                />
                <input
                    type="text"
                    id="email"
                    name="email"
                    className="text-center mx-auto mt-5"
                    style={{width: "479px", maxWidth: "90%"}}
                    autoComplete="email"
                    placeholder="Email"
                    required
                    onChange={this.onChangeHandler}
                    value={this.state.email}
                />
                <input
                    type="text"
                    id="phone-number"
                    name="phone_number"
                    className="text-center mx-auto mt-5"
                    style={{width: "479px", maxWidth: "90%"}}
                    autoComplete="phone"
                    placeholder="Phone number"
                    onChange={this.onChangeHandler}
                    value={this.state.phone_number}
                />
                <textarea
                    id="textarea"
                    name="text"
                    className="text-center mx-auto mt-5 contact-textarea"
                    style={{width: "479px", maxWidth: "90%"}}
                    placeholder="Tell us how we can help."
                    onChange={this.onChangeHandler}
                    value={this.state.text}
                />
                <input type="submit" className="primary-gradient btn btn-len-small" onClick={this.handleSubmit} />

                </form>
            </div>
        </DefaultLayout>
    );
  }
};

export function font_sizing(default_size, default_threshold, text_or_length) {
    var for_size;

    if (typeof text_or_length === "string") { for_size = text_or_length.length; }
    else { for_size = text_or_length; }
    
    if (for_size <= default_threshold) { return default_size; }
    else {
        return default_size * default_threshold / for_size;
    }
};

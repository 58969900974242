import React from "react";

import { NavLink } from "react-router-dom";

// STYLING

// UTILITY
import { image_url, product_placeholder } from "../../../../env/env.js";
import { font_sizing }       from "../../../../js-utilities/text_helpers.js";
import { format_price } from "../../../../js-utilities/currency.js";

// COMPONENTS
import DoubleArrow from "../../../../components/svg_elements/DoubleArrow.js";

export default function ListingRow({product: {slug, title, price, currency, published, draft, paused, main_image, inquiries}, mobile}) {

    let image = main_image ? image_url(main_image) : product_placeholder;
    let status = published ? "Live" : (paused ? "Paused" : "Draft");
    let display_title = title ? title : "No Title";
    let title_style = {fontSize: `${font_sizing((mobile ? 14 : 22), (mobile ? 20 : 28), display_title)}px`}
    let display_price = price ? format_price(price) : "-";
    let price_style = {fontSize: `${font_sizing((mobile ? 16 : 22), (mobile ? 10 : 14), display_title)}px`}

    return (
        <NavLink className="listing-row" to={`/account/product/${slug}`}>
        <div className="listing-row-image" style={{backgroundImage: `url(${image})`}} />
        <div className="listing-row-title">
        <p style={title_style}>{display_title}</p>
        <p className="listing-row-price">${display_price}</p>
        </div>
        <div className="listing-row-inquiries">{inquiries || "-"}</div>
        <div className="listing-row-status">
        <div className={`listing-row-${status.toLowerCase()}`}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7.14 7.14"><g><g><circle cx="3.57" cy="3.57" r="3.57"/></g></g></svg>
        {status}
                </div>
            </div>
            <div className="listing-row-action">Edit  <DoubleArrow /></div>
        </NavLink>
    );
};

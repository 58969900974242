import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// STYLING
import "./show_event.scss";

// UTILITY
import { image_url, BACKEND_URL }      from "../../env/env.js";
import { useCtxProvider } from "../../context/context.js";
import { create_error_element } from "../../js-utilities/error_arrays.js";
import { create_notice_element } from "../../js-utilities/notice_arrays.js";
import { request_with_token } from "../../context/axios_requests.js";
import { decide_image } from "./shared.js";
import { get_date, get_am_pm } from "../../js-utilities/dates_and_times.js";

// COMPONENTS
import Spinner from "../../components/global/Spinner.js";
import DoubleArrow from "../../components/svg_elements/DoubleArrow.js";
import CalendarIcon from "../../components/svg_elements/CalendarIcon.js";
import EventAttendeesSection from "./EventAttendeesSection.js";
import Popup from "../../components/popups/Popup.js";
import EventsFeed from "./EventsFeed.js";

export default function ShowEvent({ match: { params: { id } }, history }) {

    const [loading, set_loading] = useState(true);

    const [totals, set_totals] = useState({buyers: 0, sellers: 0});

    const [event, set_event] = useState(false);
    const [date, set_date] = useState(false);
    const [time_start, set_time_start] = useState(false);
    const [time_end, set_time_end] = useState(false);
    const [image, set_image] = useState(decide_image(null));

    const [loading_rsvp, set_loading_rsvp] = useState(false);
    const [is_attending, set_is_attending] = useState(0);

    const [google_link, set_google_link] = useState(false);

    const [show_signin_popup, set_show_signin_popup] = useState(false);

    const { user, session_token } = useCtxProvider();

    useEffect(() => {
        set_loading(true);
        request_with_token({
            method: "GET",
            url: `${BACKEND_URL()}/api/v1/events/show/${id}`
        }, session_token)
            .then(({data}) => {
                if (data.success) {
                    set_event(data.event);
                    set_totals(data.totals);
                } else {
                    set_event(false);
                }

                set_loading(false);
            })
            .catch((e) => {
                set_event(false);
                set_loading(false);
            })
    }, [id]);

    useEffect(() => {
        if (event) {
            set_image(decide_image(event.main_image));
            set_date(get_date(event.date));
            set_time_start(get_am_pm(event.time_start));
            set_time_end(get_am_pm(event.time_end));
            set_google_link(build_google_link(event.address))
        }
    }, [event]);

    useEffect(() => {
        if (user && session_token) {
            request_with_token({
                method: "GET",
                url: `${BACKEND_URL()}/api/v1/events/is_attending/${id}`
            }, session_token)
                .then(({data}) => {
                    if (data.success) {
                        set_is_attending(data.is_attending);
                    }
                })
                .catch((e) => {
                    set_is_attending(0);
                })
        } else {
            set_is_attending(false);
        }

    }, [user, session_token]);

    function toggle_rsvp() {
        if (session_token && event) {
            set_loading_rsvp(true);
            request_with_token({
                method: "POST",
                url: `${BACKEND_URL()}/api/v1/events/rsvp/toggle`,
                data: {id: event.id}
            }, session_token)
                .then(({data}) => {
                    if (data.success) {
                        set_is_attending(data.toggle);
                    }
                    set_loading_rsvp(false);
                })
                .catch((e) => {
                    set_loading_rsvp(false);
                });
        } else if (event) {
            set_show_signin_popup(!show_signin_popup);
        }
    };

    function goto_signin() {
        localStorage.setItem("sml_redirect_signin", history.location.pathname);
        history.push("/login");
    };

    return (
        loading ?
        <Spinner classes="spinner-full" /> :
        (event ?
         <div className="event-view">
             <div className="event-header" style={{backgroundImage: `url(${image})`}}>
                 <div className="event-header-content">
                   <h1 className="event-title">{event.title}</h1>
                   <h4 className="event-date">{date} @ {time_start.replace(/^0+/, '')}-{time_end.replace(/^0+/, '')}</h4>
                   <h4 className="event-address">{event.address}</h4>
                   <h6 className="event-header-going"><strong>{totals.buyers}</strong> Buyers / <strong>{totals.sellers}</strong> Makers</h6>
                 </div>
             </div>
             <div className="event-details">
                 <div className="event-details-description">
                     <p>What to Expect</p>
                     <p className="mt-2">{event.description}</p>
                 </div>
                 <div className="event-details-when">
                     <p>When & Where</p>
                     <p className="thin-italic my-3" style={{fontSize: "30px"}}>{date} @ {time_start.replace(/^0+/, '')}-{time_end.replace(/^0+/, '')}</p>
                     <p><strong>Local Park Name</strong></p>
                     <p>{event.address}</p>
                     <div className="fx-column sm:align-items-center">
                         {
                             google_link ?
                             <a className="color-primary my-3" href={google_link} target="_blank" style={{fontSize: "22px"}}>
                                 Go to Map
                                 <DoubleArrow />
                             </a> :
                             ""
                         }
                         {
                             loading_rsvp ?
                             <Spinner /> :
                             <button
                                 type="button"
                                 className="btn btn-size-xlarge btn-len-large primary-gradient"
                                 onClick={(_) => toggle_rsvp() }
                             >{is_attending ? "Cancel RSVP" : "RSVP"}</button>
                         }
                         <CalendarElement event={event} disabled={true} />
                     </div>
                 </div>
             </div>
             <EventAttendeesSection title="Buyers Attending" type="buyers" event={event} totals="totals" is_attending={is_attending} user={user} />
             <EventAttendeesSection title="Makers Attending" type="sellers" event={event} totals="totals" is_attending={is_attending} user={user} />
             {
                 show_signin_popup ?
                 <Popup toggle={toggle_rsvp}>
                     <div className="fx-column align-items-center children-m-1">
                         <p>You need to sign-in in order to join this event</p>
                         <button
                             type="button"
                             className="btn btn-size-normal primary-gradient"
                             onClick={goto_signin}
                         >Sign In</button>
                     </div>
                 </Popup> :
                 ""
             }
             <EventsFeed except={event.id} limit={3} />
         </div> :
         <div className="error-view">
             <p className="color-primary t-center mx-auto">It seems that event is no longer open!</p>
             <Link className="t-center mx-auto" to="/events">Browse other events</Link>
         </div>
        )
    );
};

function CalendarElement({ event, disabled }) {
    return <p className={`calendar-element ${disabled ? "disabled" : ""}`}><CalendarIcon classes="mr-2"/> Add to Calendar</p>
};


function build_google_link(event_address) {
    let address = encodeURI(event_address);
    return `https://www.google.com/maps/search/?api=1&query=${address}`
};

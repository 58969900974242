export function format_price(value) {
    let divide = /\./.test(`${value}`) ? 1 : 100
    value = (value instanceof String ? parseFloat(value) : (value / divide));

    const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,      
        maximumFractionDigits: 2,
    });

    return formatter.format(value);
};

export function add_currency_to_price(value, currency) {
    switch(currency.toLowerCase()) {
        case "usd":
            return `$ ${value}`;
        default:
            return value;
    }
};

import React, { useState, useEffect, useReducer } from "react";
import { Link } from "react-router-dom";

// STYLES
import "./index.scss";

// UTILITY
import { BACKEND_URL, image_url } from "../../env/env.js";
import { useCtxProvider, get_category, get_subcategory } from "../../context/context.js";

// COMPONENTS
import DefaultLayout from "../../layouts/default.js";
import Spinner       from "../../components/global/Spinner.js";
import Popup         from "../../../src/components/popups/Popup.js";
import Contact       from "../../scenes/product/Contact.js";

import ItemCard from "../../components/sections/feeds/item_card.js";

const axios = require("axios");

export default function Category({match: { params }}) {

    const [loading, set_loading] = useState(true);
    const [products_state, products_dispatch] = useReducer(products_reducer, {category: params.category});

    const [products, set_products] = useState([]);
    const [load_more, set_load_more] = useState(false);
    const [loading_more, set_loading_more] = useState(false);

    const [category, set_category] = useState({});
    const [subcategory, set_subcategory] = useState(false);

    const [show_contact, set_show_contact] = useState(false);
    const [contact_details, set_contact_details] = useState({});

    const { categories, subcategories } = useCtxProvider();


    useEffect(() => {
        set_loading(true);
        set_subcategory(false);
        products_dispatch({type: "set_category", value: params.category});
        load_products(params.category, false, products_dispatch, set_loading);
    }, [params.category]);

    useEffect(() => set_category(get_category(params.category, categories, true)), [categories, params.category]);

    useEffect(() => {
        let chosen_slug = subcategory ? subcategory : params.category;
        if (products_state[chosen_slug]) {
            set_products(products_state[chosen_slug]);
        } else if (!loading) {
            load_products(params.category, subcategory, products_dispatch, set_loading);
        }
    }, [products_state, subcategory, params.category, loading]);

    useEffect(() => {
        let chosen_slug = subcategory ? subcategory : params.category;
        set_load_more(products_state[`${chosen_slug}_load_more`]);
    }, [params.category, subcategory, products_state]);

    function toggle_contact(product, user, history) {
        if (show_contact) {
            set_show_contact(false);
            set_contact_details({});
        } else {
            set_contact_details({ product, user, history });
            set_show_contact(true);
        }
    };

    function load_next() {
        set_loading_more(true);

        let last_product = products[products.length - 1],
            last_datetime = last_product.inserted_at,
            last_ids;

        last_ids = products.reduce((acc, {inserted_at, id}) => {
            if (inserted_at === last_datetime) {
                acc.push(id);
            }
            return acc
        }, []);

        axios({
            method: "POST",
            url: `${BACKEND_URL()}/api/v1/categories/${params.category}`,
            data: {
                last_datetime: last_datetime,
                except: last_ids,
                subcategory: subcategory
            }
        })
            .then(({data}) => {
                if (data.success) {
                    products_dispatch({type: "add_products", value: data.products, has_more: data.has_more, category: params.category, subcategory: subcategory});
                    set_loading_more(false);
                } else {
                    set_loading_more(false);
                }
            })
            .catch((e) => {
                set_loading_more(false);
            })
    };

    function select_subcategory(slug) {
        if (subcategory === slug) {
            set_subcategory(false);

        } else {
            set_subcategory(slug);
        }
    };

    return (
        <DefaultLayout>
            {
                loading || !categories ?
                <Spinner classes="spinner-full" /> :

                <div className="products-list-container">
                    {category ?
                     <div className="products-list-top">
                         <div className="products-list-title" onClick={(_) => set_subcategory(false)}>
                             <img className="products-list-title-icon" alt={category.title} src={image_url(category.image)} />
                             <h4 className="products-list-title-header">{category.title}</h4>
                         </div>
                         <div className="products-list-subcategories">
                               <button
                                    type="button"
                                    className={`m-1 btn btn-size-normal t-italic ${!subcategory ? "primary-gradient" : "btn-clear"}`}
                                    onClick={(_) => select_subcategory(false)}
                                >All</button>
                             {subcategories[category.slug].map(({title, slug}) => {
                                 return <button
                                            type="button"
                                            className={`m-1 btn btn-size-normal t-italic ${subcategory === slug ? "primary-gradient" : "btn-clear"}`}
                                            key={slug}
                                            onClick={(_) => select_subcategory(slug)}
                                        >{title}</button>
                             })}
                         </div>
                         <div className="select-dropdown-wrapper sub">
                             <select
                                 name="search-subcategories"
                                 id="search-subcategories"
                                 className="select-dropdown mx-auto"
                                 value={subcategory ? subcategory.slug : ""}
                                 style={{width: "90%"}}
                                 onChange={(event) => select_subcategory(event.target.value)}
                             >
                                 <option value="">All</option>
                                 {(subcategories[category.slug]).map(({title, slug}) => <option key={`search-subcategory-${slug}`} value={slug}>{title}</option> )}
                             </select>
                         </div>
                     </div> :
                     ""
                    }
                    <div className="section-divider full-divider mt-2 mb-4"/>
                    <div className="products-list mb-4 mt-6 children-mx-2">
                        {
                            products && products.length > 0 ? (
                                products.map((product) => <ItemCard
                                                              product={product}
                                                              key={product.id}
                                                              card_type="display"
                                                              toggle_contact={toggle_contact}
                                />)) :
                                                          <div className="fx-column justify-content-center align-items-center mt-4">
                                                              <h1 style={{fontSize: "20px"}} className="my-2">No products yet available for this category!</h1>
                                                              <p className="my-2">Check one of our other categories:</p>
                                                              {categories.map(({title, slug}) => <Link to={`/categories/${slug}`} key={slug} className="color-primary">{title}</Link>)}
                                                          </div>
                        }
                {
                    show_contact ?
                    <Popup toggle={toggle_contact}>
                        <Contact product={{...contact_details.product, published: true}} user={contact_details.user} toggle={toggle_contact} history={contact_details.history} />
                    </Popup> :
                    ""
                }
                <div className="fx-row justify-content-center align-items-center m-2">
                    {
                        load_more && !loading_more ?
                        <button type="button" className="btn primary-gradient fx-1-0-0 mx-auto" style={{fontSize: "18px", textAlign: "center"}} onClick={() => load_next()}>Load more products...</button> :
                        loading_more ?
                        <Spinner /> :
                        ""
                    }
                </div>
                </div>
                </div>
            }
        </DefaultLayout>
    );
};


function load_products(category, subcategory, products_dispatch, set_loading) {
    axios({
        method: "POST",
        url: `${BACKEND_URL()}/api/v1/categories/${category}`,
        data: {subcategory: subcategory}
    })
        .then(({data}) => {
            if (data.success) {
                products_dispatch({type: "set_products", value: data.products, has_more: data.has_more, category: category, subcategory: subcategory});
                set_loading(false);
            } else {
                products_dispatch({type: "set_products", value: [], has_more: false, category: category, subcategory: subcategory});
                set_loading(false);
            }
        })
        .catch((e) => {
            products_dispatch({type: "set_products", value: [], has_more: false, category: category, subcategory: subcategory});
            set_loading(false);
        })
};


function products_reducer(state, action) {
    let category = action.subcategory ? action.subcategory : action.category;
    switch(action.type) {
        case "set_category":
            return {...state, category: action.value};
        case "set_products":
            state[category] = action.value;
            state[`${category}_load_more`] = action.has_more;
            return {...state};
        case "add_products":
            let existing = state[category] ? state[category] : [];
            state[category] = [...existing, ...action.value];
            state[`${category}_load_more`] = action.has_more;
            return {...state};
        default:
            return state;
    };
};

import React, { useState, useReducer, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

// STYLES
import "./item_feed.scss";

// UTILITY
import { BACKEND_URL } from "../../../env/env.js";

// COMPONENTS
import Spinner from "../../../components/global/Spinner.js";
import ItemCard from "./item_card.js";
import CarousselButton from "./caroussel_button.js";
import Popup         from "../../popups/Popup.js";
import Contact       from "../../../scenes/product/Contact.js";

// LIBS
const axios = require("axios");


export default function ItemFeed({ icon, title, slug, mock, type, except }) {
    const [loading, set_loading] = useState(true);

    const [show_left, toggle_left] = useState(false);
    const [show_right, toggle_right] = useState(false);
    const [show_contact, set_show_contact] = useState(false);
    const [contact_details, set_contact_details] = useState({});

    const feed_ref = useRef(null);
    const initial_feed_state = {items: [], ref: feed_ref, toggle_left: toggle_left, toggle_right: toggle_right};

    const [{items, ref}, items_dispatch] = useReducer(items_reducer, initial_feed_state);


    useEffect(() => {
        if (mock) {
            items_dispatch({type: "set", value: mock});
            setTimeout(() => set_loading(false), 500);
        }

        let timer = setTimeout(() => set_scroll_options(ref, toggle_left, toggle_right), 500);

        return () => clearTimeout(timer);
    }, [mock, ref, items]);


    useEffect(() => {
        if (!mock) {
            get_products_for_category(slug, items_dispatch, except, set_loading);
        }
    }, [slug, mock]);


    // set a listener for the scroll, otherwise we can't accurately decide if to show or not the sliders because the scroll animation takes a bit
    // it also doesn't seem to be necessary to request frameAnimation for this since the elements are not scrollable by the user, meaning the event triggers 1 per click which doesn't require any kind of throtlling
    useEffect(() => {
        const ref_copy = ref;
        const callback = () => items_dispatch({type: "set_scrolls"});

        ref_copy.current.addEventListener("scroll", callback);

        return function cleanup() {
            ref_copy.current.removeEventListener("scroll", callback);
        }
    }, []);


    function toggle_contact(product, user, history) {
        if (show_contact) {
            set_show_contact(false);
            set_contact_details({});
        } else {
            set_contact_details({ product, user, history });
            set_show_contact(true);
        }
    };

    return (
        <div className={`item-feed ${items.length > 0 ? "" : "no-items"}`}>
            {loading || !show_left ? "" : <CarousselButton classes="feed-left-arrow" callback={() => items_dispatch({type: "left"})} />}
            {type === "secondary" ? (
                <div className="item-feed-title-secondary">
                    <h4 className="item-feed-title-secondary-element">{title}</h4>
                </div>
            ) : (
                <Link className="item-feed-title" to={`/categories/${slug}`}>
                    <img alt={`Category Icon: ${title}`} className="item-feed-icon" src={icon} />
                    <h4 className="item-feed-title-element">{title}</h4>
                </Link>
            )}
            <div className="item-feed-collection" ref={feed_ref}>
                {loading ?
                 <Spinner classes="helper-card-dimensions mx-auto" /> :
                 items.map((product) => {
                     return <ItemCard
                                product={product}
                                key={`${product.id}_${Math.random()}`}
                                card_type="display"
                                toggle_contact={toggle_contact}
                     />
                 })
                }
            </div>
            {loading || !show_right ? "" : <CarousselButton classes="feed-right-arrow" callback={() => items_dispatch({type: "right"})} />}
            {
                show_contact ?
                <Popup toggle={toggle_contact}>
                    <Contact product={{...contact_details.product, published: true}} user={contact_details.user} toggle={toggle_contact} history={contact_details.history} />
                </Popup> :
                ""
            }
        </div>
    );
};

function items_reducer(state, action) {
    switch(action.type) {
        case "set":
            return {...state, items: action.value};
        case "left":
            apply_scroll("left", state);
            return state;
        case "right":
            apply_scroll("right", state);
            return state;
        case "set_scrolls":
            set_scroll_options(state.ref, state.toggle_left, state.toggle_right);
            return state;
        default:
            return state;
    }
};


function apply_scroll(direction, {ref, toggle_left, toggle_right}) {
    let el = ref.current;
    let scroll_width = el.scrollWidth;
    let scrolled = el.scrollLeft;

    let item = el.firstElementChild;
    let { width } = item.getBoundingClientRect();
    let item_style = window.getComputedStyle(item);
    let item_real_width = width + (2 * parseInt(item_style.getPropertyValue("margin-left")) - 5);
    let bounding_container = el.getBoundingClientRect();
    let container_width = bounding_container.width;

    let new_scroll = direction === "right" ?
                     decide_right(scrolled, item_real_width, scroll_width, container_width) :
                     decide_left(scrolled, item_real_width, container_width);

    el.scrollTo({
        top: 0,
        left: new_scroll,
        behavior: "smooth"
    });

    set_scroll_options(ref, toggle_left, toggle_right);
};

function set_scroll_options(ref, toggle_left, toggle_right) {
    let el = ref.current;
    let scroll_width = el.scrollWidth;
    let scrolled = el.scrollLeft;
    let { width } = el.getBoundingClientRect();

    toggle_right((width + scrolled) < scroll_width);
    toggle_left(scrolled > 0);
};


function decide_right(scrolled, item_width, scroll_width, container_width) {
    let number_of_items_in_view = Math.floor((container_width / item_width)) || 1;
    let total_scroll = scrolled + (item_width * number_of_items_in_view);
    if (total_scroll >= (scroll_width - 80)) { return scroll_width; }
    else { return total_scroll; }
}

function decide_left(scrolled, item_width, container_width) {
    let number_of_items_in_view = Math.floor((container_width / item_width));
    let total_scroll = scrolled - (item_width * number_of_items_in_view);
    if (total_scroll <= (item_width - 80)) { return 0; }
    else { return total_scroll; }
}

function get_products_for_category(slug, items_dispatch, except, set_loading) {
    axios({
        method: "GET",
        url: `${BACKEND_URL()}/api/v1/products/feed/${slug}${except ? "?except=" + except : ""}`
    })
        .then(({data}) => {
            if (data.success) {
                // console.log("products", data.products);
                items_dispatch({type: "set", value: data.products});
            }
            set_loading(false);
        })
        .catch((e) => {
            console.log(`Error fetching products for ${slug}`, e);
            set_loading(false);
        });
};

import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

// STYLES
import "./index.scss";


// UTILITY
import { BACKEND_URL } from "../../env/env.js";
import { useCtxProvider } from "../../context/context.js";
import { response_error, create_error_element } from "../../js-utilities/error_arrays.js";

// COMPONENTS
import Logo from "../../components/global/Logo.js";
import Spinner from "../../components/global/Spinner.js";
import { request_with_token } from "../../context/axios_requests.js";


const axios = require("axios");

export default function Login({ history }) {

    const token = useParams();

    const [loading, set_loading] = useState(false);

    const [step, set_step] = useState("email");

    const { user, session_token, ctx_dispatch } = useCtxProvider();

    const [ password, set_password ] = useState("");
    const [ confirm_password, set_confirm_password ] = useState("");


    useEffect(() => {
        if (user) {
            history.push("/account/profile");
        }
    }, [user, history]);

    function handle_submit(event) {
        event.preventDefault();
        if (password == ''){

            let error = create_error_element({msg: `Password can't be  empty!`, ttl: 5000});
            ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
            return
        }
        if(password != confirm_password){
            let error = create_error_element({msg: "Password & Confirm Password did not match!", ttl: 5000});
            ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
            return;
        }
        set_loading(true);

        axios({
            method: "POST",
            url: `${BACKEND_URL()}/api/v1/accounts/password_change`,
            data: {
                token: token.token,
                password: password
            }
        }).then(({data}) => {
            if (data.success) {
                set_step('email_sent');
            } else if (data.errors) {
                data.errors.map((error) => {
                    error = response_error(error);
                    ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
                    set_loading(false);
                    return null;
                });
            }
            set_loading(false);
        }).catch((e) => {
            let error = create_error_element({msg: "Unable to process your request", ttl: 5000});
            ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
            set_loading(false);
        });

    };

    return (
      <div className="login-container">
          {loading ? (<Spinner classes="spinner-full" />) : ""}
          <div className="login-container-form-wrapper">
              <div className="login-container-form-inner">
                  <div className="login-logo mb-8">
                      <Link to="/">
                          <Logo width="100%" height="auto" />
                      </Link>
                  </div>
                  {step == 'email' &&
                    <>
                  <p className="label-title mb-1">Enter New Password</p>
                  <form className="login-container-form mb-4" onSubmit={handle_submit}>
                      <input
                        type="password"
                        id="password"
                        name="password"
                        autoFocus={true}
                        value={password}
                        onChange={(event) => set_password(event.target.value)}
                        placeholder="Enter New Password"
                      />
                      <input
                        type="password"
                        id="confirm_password"
                        name="confirm_password"
                        autoFocus={true}
                        value={confirm_password}
                        onChange={(event) => set_confirm_password(event.target.value)}
                        placeholder="Confirm New Password"
                      />

                      <input type="submit" value="Update" className="primary-gradient btn btn-len-small"/>

                  </form>
                  </>
                  }
                  {step == 'email_sent' &&
                    <div style={{textAlign: 'center'}}>
                        <p>Password Updated, Please Proceed to login page.</p>
                        <button
                          style={{margin: 'auto', marginTop: '10px'}}
                          type="button"
                          className="btn primary-gradient"
                          onClick={(event) => window.location.href = '/login'}
                        >Login</button>
                    </div>
                  }
              </div>
          </div>


          <div style={{zIndex: 9}} className="login-container-cta">
              <div className="login-cta-element">
                  <h2 className="login-cta-title">Begin selling unique handmade goods and crafts locally.</h2>
                  <ul className="my-3 login-cta-list">
                      <li><span className="check-marker">Find new customers</span></li>
                      <li><span className="check-marker">Join a community of entrepreneurs</span></li>
                      <li><span className="check-marker">Let us promote you</span></li>
                  </ul>
                  <Link className="btn btn-size-normal btn-len-normal btn-white color-primary mx-auto" to="/signup">Sign Up</Link>
              </div>
          </div>
      </div>
    );
};

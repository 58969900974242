import React, { useState, useEffect } from "react";

// STYLES

// UTILITY
import { create_error_element } from "../../../js-utilities/error_arrays.js";
import { useCtxProvider } from "../../../context/context.js";

import { check_requirements, requirements } from "../shared_account_details.js";
import { accept_file } from "./shared_functions.js";

// COMPONENTS
import AvatarUploader from "../../../components/upload/avatar-uploader.js";
import StateSelect    from "../../../components/inputs/StateSelect.js";

export default function Step4Seller({ dispatch, state }) {

    const [loading, set_loading] = useState(false);

    const { ctx_dispatch } = useCtxProvider();

    useEffect(() => {

        let [valid, _errors] = check_requirements(requirements().required_fields.seller, state, false);

        if (valid) {
            dispatch({type: "set_step", step: 4, account_type: "seller"});
        } else {
            dispatch({type: "reset"});
        }
        
    }, []);

    function handle_submit(event) {
        event.preventDefault();
        set_loading(true);

        let [valid, _errors] = check_requirements(requirements().required_fields.seller, state, false);

        if (valid) {
            dispatch({type: "set_step", step: 5, account_type: "seller"});
        } else {
            dispatch({type: "reset"});
        }
    };

    function maybe_accept_company_logo(accepted_files) {
        accept_file(accepted_files, 600, [300, 300])
            .then(({blob, file}) => {
                dispatch({type: "set_picture", field: "company_logo", value: file});
            })
            .catch((error) => {
                if (error.error === "min_size") {
                    error = create_error_element({msg: `The image needs to have a minimum of ${error.minimum}px ${error.side}.`, ttl: 7500, field: "Company Logo"});
                } else {
                    error = create_error_element({msg: `The image you chose seems to be invalid. Please choose a`, ttl: 7500, field: "Company Logo"});
                }
                ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
            });
    };

    function maybe_accept_company_cover(accepted_files) {
        accept_file(accepted_files, 1100, [1100, 200])
            .then(({blob, file}) => {
                dispatch({type: "set_picture", field: "company_cover", value: file});
            })
            .catch((error) => {
                if (error.error === "min_size") {
                    error = create_error_element({msg: `The image needs to have a minimum of ${error.minimum}px ${error.side}.`, ttl: 7500, field: "Company Cover"});
                } else {
                    error = create_error_element({msg: `The image you chose seems to be invalid. Please choose a valid JPG or PNG file.`, ttl: 7500, field: "Company Cover"});
                }
                ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
            });
    };
        
        return (
            <div className="signup-container-step bg-white">
                <div className="signup-step-title">
                    <h2><span>Step 4</span> Tell us about your business.</h2>
                </div>
                <form className="signup-container-form" onSubmit={handle_submit}>
                    <div className="signup-optional-fields text-center mt-4">
                        <StateSelect callback={(state_selected) => dispatch({type: "set_field", field: "state", value: state_selected})} name="state" value={state.state} styles={{width: "479px", maxWidth: "100%"}} />
                    </div>
                    <div className="signup-optional-fields text-center mb-4">
                        <input
                            type="text"
                            id="city"
                            name="city"
                            className="text-center mx-auto mt-1"
                            style={{width: "479px", maxWidth: "100%"}}
                            autoFocus={true}
                            autoComplete="city"
                            value={state.city}
                            onChange={(event) => dispatch({type: "set_field", field: "city", value: event.target.value})}
                            placeholder="City (Optional)"
                        />
                    </div>
                    <div className="signup-optional-fields text-center mb-4">
                        <input
                            type="text"
                            id="company-name"
                            name="company-name"
                            className="text-center mx-auto"
                            style={{width: "479px", maxWidth: "100%"}}
                            autoComplete="company-name"
                            value={state.company_name}
                            onChange={(event) => dispatch({type: "set_field", field: "company_name", value: event.target.value})}
                            placeholder="Company Name (Optional)"
                        />
                    </div>
                    <div className="signup-optional-fields mb-4">
                        <div className="fx-row justify-content-center children-mx-1">
                            <div className="signup-avatar-upload-section text-center">
                                <AvatarUploader upload_callback={maybe_accept_company_logo} file={state.company_logo} multiple={false} />
                                <p className="signup-label mt-1">Business Logo</p>
                                <p className="signup-label"><span className="thin-italic">(Optional)</span></p>
                                <p className="signup-note mt-1">Minimum Size 300px x 300px</p>
                            </div>
                            <div className="signup-avatar-upload-section text-center">
                                <AvatarUploader upload_callback={maybe_accept_company_cover} file={state.company_cover} classes="cover-image" multiple={false} placeholder={false}/>
                                <p className="signup-label mt-1">Business Cover</p>
                                <p className="signup-label"><span className="thin-italic">(Optional)</span></p>
                                <p className="signup-note mt-1">Minimum Size 1100px by 200px</p>
                            </div>
                        </div>
                    </div>
                    <div className="fx-row justify-content-end align-items-center">
                        <hr className="mt-5" />
                        <button type="submit" className="btn btn-size-large btn-len-normal primary-gradient mr-5">Next</button>
                    </div>
                </form>
            </div>
        );
};

export function main_profile_route(user) {
    switch(user && user.type) {
    case "seller":
        return "/account/listings";
    case "buyer":
        return "/account/profile";
    default:
        return "/";
    };
};


export function profile_routes(user) {
    switch(user && user.type) {
    case "buyer":
        return [
            ["/account/profile", "Profile"],
            ["/account/messages", "Messages"],
            // ["/account/favorites", "Favorites"],
            ["/account/settings", "Settings"],
            ["/events", "Events"]
        ];
    case "seller":
        return [
            ["/account/listings", "Listings"],
            ["/account/account", "Account"],
            ["/account/messages", "Messages"],
            ["/account/settings", "Settings"],
            ["/events", "Events"]
        ]
    default:
        return [];
    };
};

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// UTILITY
import { BACKEND_URL } from "../../env/env.js";
import { plans } from "../../env/plans.js";
import { request_with_token } from "../../context/axios_requests.js";

import { create_error_element, response_error } from "../../js-utilities/error_arrays.js";
import { useCtxProvider } from "../../context/context.js";
import { check_requirements } from "../signup/shared_account_details.js";
import { accept_file } from "../signup/steps/shared_functions.js";

// COMPONENTS
import Spinner from "../../components/global/Spinner.js";
import AvatarUploader from "../../components/upload/avatar-uploader.js";

// STRIPE SPECIFIC
import {
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
    injectStripe
} from "react-stripe-elements";

function StripeForm({ state, dispatch, stripe }) {

    const [loading, set_loading] = useState(false);

    const [expiry_ready, set_expiry_ready] = useState(false);
    const [number_ready, set_number_ready] = useState(false);
    const [cvc_ready, set_cvc_ready] = useState(false);

    const [expiry_complete, set_expiry_complete] = useState(false);
    const [number_complete, set_number_complete] = useState(false);
    const [cvc_complete, set_cvc_complete] = useState(false);

    const { ctx_dispatch, session_token } = useCtxProvider();

    useEffect(() => {
        dispatch({type: "set_field", field: "cc_name", value: [state.first_name, state.last_name].filter((val) => val).join(" ")});
    }, []);
    


    function handle_change(event) {
        switch(event.elementType) {
            case "cardNumber":
                set_number_complete(event.complete);
                return;
            case "cardExpiry":
                set_expiry_complete(event.complete);
                return;
            case "cardCvc":
                set_cvc_complete(event.complete);
                return;
            default:
                return;
        };
    };

    function handle_ready(event) {
        switch(event._componentName) {
            case "cardExpiry":
                set_expiry_ready(true);
                return;
            case "cardNumber":
                set_number_ready(true);
                return;
            case "cardCvc":
                set_cvc_ready(true);
                return;
            default:
                return;
        };
    };

    function handle_submit(event) {
        event.preventDefault();
        set_loading(true);
        try_payment_flow(dispatch, state, stripe, ctx_dispatch, session_token, set_loading);
    };

    function maybe_accept_file(accepted_files) {
        accept_file(accepted_files, 600, [300, 300])
            .then(({blob, file}) => {
                let form_data = new FormData();
                form_data.append("profile_image", file);
                
                request_with_token({
                    method: "POST",
                    url: `${BACKEND_URL()}/api/v1/accounts/update`,
                    data: form_data,
                    headers: {"Content-Type": "multipart/form-data"}
                }, session_token)
                    .then(({data}) => {
                        if (data.success) {
                            ctx_dispatch({type: "update_user", value: {profile_image: data.profile_image}});
                        } else if (data.errors) {
                            data.errors.forEach((error) => {
                                let n_error = create_error_element({...error, ttl: 5000, field: "Profile Image"});
                                ctx_dispatch({type: "show_error", value: n_error, dispatcher: ctx_dispatch});
                            });
                        }
                    })
                    .catch((e) => {
                        let error = create_error_element({msg: "There was an error processing your request", ttl: 7500, field: "Profile Image"});
                        ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
                    });
            });
    };


    const styling = {
        style: {
            base: {
                fontSize: "24px",
                color: "#424770",
                fontFamily: "Zilla Slab, Arial",
                '::placeholder': {
                    color: '#cecee7',
                }
            },
            invalid: {
                color: "#ff2100",
            },
        },
    };
    
    return (
        <form className="fx-row my-4 mx-auto justify-content-center" onSubmit={handle_submit}>
            <div className="text-center fx-1-0 sm:mb-2 ml-auto sm:mx-auto" style={{maxWidth: "225px"}}>
                <AvatarUploader
                    upload_callback={maybe_accept_file}
                    file={state.profile_image}
                    multiple={false}
                />
                <p className="signup-note mt-1">Recommended size: 600px x 600px</p>
            </div>
            <div className="fx-1-0 ml-4 mr-auto fx-column align-items-center" style={{maxWidth: "479px"}}>
                <input
                    type="text"
                    id="given-name"
                    name="given-name"
                    className="text-center mb-1 color-primary"
                    style={{width: "479px", maxWidth: "90%"}}
                    autoComplete="given-name"
                    value={state.first_name}
                    onChange={(event) => dispatch({type: "set_field", field: "first_name", value: event.target.value})}
                    placeholder="First Name"
                />
                <input
                    type="text"
                    id="family-name"
                    name="family-name"
                    className="text-center my-1 color-primary"
                    style={{width: "479px", maxWidth: "90%"}}
                    autoComplete="family-name"
                    value={state.last_name}
                    onChange={(event) => dispatch({type: "set_field", field: "last_name", value: event.target.value})}
                    placeholder="Last Name"
                />
                <input
                    type="text"
                    id="email"
                    name="email"
                    className="text-center my-1 color-primary"
                    autoComplete="email"
                    style={{width: "479px", maxWidth: "90%"}}
                    value={state.email}
                    onChange={(event) => dispatch({type: "set_field", field: "email", value: event.target.value})}
                    pattern=".+?[@].+?"
                    title="Your email should contain at least a @"
                    placeholder="Email"
                />
                <input
                    type="tel"
                    id="telephone"
                    name="telephone"
                    className="text-center my-1 color-primary"
                    style={{width: "278px", maxWidth: "70%"}}
                    autoComplete="tel"
                    value={state.phone_number}
                    disabled={true}
                    onChange={(event) => dispatch({type: "set_field", field: "phone_number", value: event.target.value})}
                    placeholder="Phone Number"
                    pattern="^[0-9]{3}[-\s]?[0-9]{3}[-\s]?[0-9]{4}$"
                    title="Valid 10 digit USA number with or without spaces or hyphens, e.g.: XXX XXX XXXX"
                />
            </div>
            <div className="fx-column justify-content-center align-items-center mt-4">
                <label style={{fontSize: "14px"}}>
                    I confirm I've read and agree to Simply Made Local's <Link to="/seller-agreement" target="_blank" className="color-primary">Terms of Service</Link> and <Link to="/privacy-policy" target="_blank" className="color-primary">Privacy Policy</Link>:</label>
                    <input
                        name="tos_pp"
                        type="checkbox"
                        className="mx-auto"
                        checked={state.tos_pp}
                        required
                        onChange={(event) => dispatch({type: "set_field", field: "tos_pp", value: event.target.value})}
                        disabled={loading}
                    />
            </div>
            <div className="fx-column justify-content-center align-items-center mt-4">
                <div className="signup-coupon-container">
                    <p className="signup-label">Coupon</p>
                    <input
                        type="text"
                        id="discount_coupon"
                        className="text-center mx-auto mt-2"
                        style={{width: "479px", maxWidth: "90%"}}
                        value={state.coupon ? state.coupon : ""}
                        onChange={(event) => dispatch({type: "set_field", field: "coupon", value: event.target.value})}
                        placeholder="Discount Coupon"
                    />
                </div>
            </div>
            <div className="signup-container-form stripe-form mt-4" onSubmit={handle_submit}>
                {loading || !expiry_ready || !number_ready || !cvc_ready ? <Spinner classes="spinner-full" /> : ""}
                <p className="signup-label my-2 text-center" style={{fontWeight: 300}}>Enter your payment information to begin your subscription.</p>
                <div className="fx-column">
                    <div className="fx-row justify-content-between">
                        <input
                            type="text"
                            id="cc-name"
                            name="cc-name"
                            className="text-center"
                            autoFocus={true}
                            autoComplete="cc-name"
                            value={state.cc_name}
                            onChange={(event) =>  dispatch({type: "set_field", field: "cc_name", value: event.target.value})}
                            placeholder="Name on Card"
                            disabled={loading}
                            required
                        />
                        <label className="card-number">
                            Card number
                            <CardNumberElement
                                onChange={handle_change}
                                onReady={handle_ready}
                                disabled={loading}
                                {...styling}
                            />
                        </label>
                        
                        <label className="card-expiry">
                            Expiration date
                            <CardExpiryElement
                                onChange={handle_change}
                                onReady={handle_ready}
                                disabled={loading}
                                {...styling}
                            />
                        </label>
                        <label className="card-cvc">
                            CVC
                            <CardCvcElement
                                onChange={handle_change}
                                onReady={handle_ready}
                                disabled={loading}
                                {...styling}
                            />
                        </label>
                    </div>
                    <p style={{fontSize: "12px", marginTop: "-20px", marginLeft: "5px"}}>powered by Stripe</p>
                    <div className="fx-row">
                        <button
                            type="submit"
                            className="btn btn-size-large btn-len-normal primary-gradient mx-auto"
                            disabled={!expiry_complete || !number_complete || !cvc_complete || !state.cc_name || loading}
                        >Pay Now</button>
                    </div>
                </div>
            </div>
        </form>
    );
};

const InjectedCheckoutForm = injectStripe(StripeForm);

export default InjectedCheckoutForm;


function try_payment_flow(dispatch, state, stripe, ctx_dispatch, session_token, set_loading) {
    let [valid, errors] = check_requirements(required_fields, state);

    if (valid) {
        stripe.createToken({type: "card", name: state.cc_name}).then((result) => {
            if (result.token) {
                let form_data = new FormData();

                valid_fields.forEach((key) => {
                    form_data.append(key, state[key]);
                });
                
                form_data.set("token", JSON.stringify(result.token));

                if (state.coupon) { form_data.set("coupon", state.coupon); }
                
                request_with_token({
                    method: "POST",
                    url: `${BACKEND_URL()}/api/v1/payments/upgrade`,
                    data: form_data,
                    headers: {"Content-Type": "multipart/form-data"}
                }, session_token).then(({data}) => {
                    
                    if (data.success) {
                        ctx_dispatch({type: "signin", value: data});
                        dispatch({type: "upgraded"});
                    } else if (data.errors) {
                        
                        data.errors.forEach((error) => {
                            error = response_error(error);
                            ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
                        });
                        set_loading(false);
                    }
                });
            } else if (result.error) {
                
                let stripe_error = create_error_element({msg: result.error.message});
                ctx_dispatch({type: "show_error", value: stripe_error, dispatcher: ctx_dispatch});
                set_loading(false)
            }
        });
    } else {
        errors.forEach((error) => {
            ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
            return false;
        });
        set_loading(false);
    }
};

export const phone_regex = /^[0-9]{3}[-\s]?[0-9]{3}[-\s]?[0-9]{4}$/;
export const email_regex = /.+?[@].+?/;

const required_fields = [
    { 
        field: "phone_number",
        fun: (value) => {
            return phone_regex.test(value);
        },
        error: (value) => {
            return create_error_element({msg: `Expected a valid USA phone number, in the form of XXX-XXX-XXXX (with or without hyphens or spaces), got ${value}`, ttl: 7500, field: "Phone Number"});
        }
    },
    {
        field: "first_name",
        fun: (value) => {
            return value !== "" && (typeof value === "string" || value instanceof String);
        },
        error: (value) => {
            return create_error_element({msg: "You need to fill your First Name", ttl: 7500, field: "First Name"});
        }
    },
    {
        field: "last_name",
        fun: (value) => {
            return value !== "" && (typeof value === "string" || value instanceof String);
        },
        error: (value) => {
            return create_error_element({msg: "You need to fill your Last Name", ttl: 7500, field: "Last Name"});
        }
    },
    {
        field: "profile_image",
        fun: (value) => {
            console.log("profile_image", value, typeof value);
            return value instanceof File || (typeof value === "string" && value !== "uploading" && value !== "failed");
        },
        error: (value) => {
            return create_error_element({msg: "You need to choose a file for your profile image or have an image already set", ttl: 7500, field: "Profile Image"});
        }
    },
    {
        field: "email",
        fun: (value) => {
            return email_regex.test(value);
        },
        error: (value) => {
            return create_error_element({msg: "You need a valid email address", ttl: 7500, field: "Email"});
        }
    },
    {
        field: "plan",
        fun: (value) => {
            return plans.reduce((acc, plan) => {
                return (plan.name === value && !plan.disabled) || acc ? true : false;
            }, false);
        },
        error: (value) => {
            return create_error_element({msg: "Please choose one of the plans for upgrading your account", ttl: 7500, field: "Subscription Plan"});
        }
    },
    {
        field: "tos_pp",
        fun: (value) => value === true || value === "on",
        error: (value) => {
            return create_error_element({msg: "You need to agree to Simply Made Local's Terms of Service and Privacy Policy", ttl: 7500, field: "Tos & PP"});
        }
    }
]

const valid_fields = required_fields.map(({field}) => field).filter((field) => !(["profile_image", "phone_number"].includes(field)));

import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";

// STYLING

// UTILITY
import { useCtxProvider }   from "../../context/context.js";
import { BACKEND_URL } from "../../env/env.js";
import { create_error_element } from "../../js-utilities/error_arrays.js";

// COMPONENTS
import Spinner from "../../components/global/Spinner.js";

const axios = require("axios");

export default function AdminSignin({ history }) {

    const [loading, set_loading] = useState(true);
    
    const { admin_state, admin_dispatch, ctx_dispatch } = useCtxProvider();

    const [email, set_email] = useState("");
    const [password, set_password] = useState("");

    useEffect(() => {
        if (!admin_state.user && !admin_state.is_verifying_admin) {
            set_loading(false);
        }
    }, [admin_state.user, admin_state.is_verifying]);

    useEffect(() => {
        if (admin_state.user) {
            history.push("/admin/dashboard");
        }
    }, [admin_state.user, history]);

    function admin_signin(event) {
        event.preventDefault();
        set_loading(true);

        axios({
            method: "POST",
            url: `${BACKEND_URL()}/api/v1/admin/signin`,
            data: {email: email, password: password}
        })
            .then(({data}) => {
                if (data.success) {
                    admin_dispatch({type: "signin", value: data});
                } else {
                    if (data.errors) {
                        data.errors.map((error) => {
                            error = create_error_element(error);
                            ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
                            return null;
                        });
                    }
                }
                set_loading(false);
            }).catch((e) => {
                let error = create_error_element({msg: "Unable to process your request", ttl: 5000});
                ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
                set_loading(false);
            });
    };
   
    return (
        loading ?
        <Spinner classes="spinner-full" /> :
        <div className="login-container mx-auto mt-4">
            <form className="login-container-form mb-4 mx-auto children-my-1" onSubmit={admin_signin}>
                <input
                    type="email"
                    id="email"
                    name="email"
                    autoFocus={true}
                    autoComplete="email"
                    value={email}
                    onChange={(event) => set_email(event.target.value)}
                    placeholder="Email"
                />
                <input
                    type="password"
                    id="password"
                    name="password"
                    autoComplete="current-password"
                    value={password}
                    onChange={(event) => set_password(event.target.value)}
                    placeholder="Password"
                />
                <button type="submit" className="primary-gradient btn btn-len-small">
                    Sign-in
                </button>
            </form>
        </div>
    );
};

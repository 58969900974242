import React from "react";


// STYLES

// UTILITY

// COMPONENTS
import DefaultLayout from "../../layouts/default.js";


export default function SellerAgreement(_props) {

    return (
        <DefaultLayout>
          <div className="legal-content">
            <h1>Seller Terms</h1>
            <strong><p>What Can be Sold on Simply Made Local</p></strong>
            <p>Simply Made Local is an exclusive handmade marketplace. Buyers come here to purchase handmade products in their local area. Everything listed for sale on Simply Made Local must be handmade.</p>
            <p>Handmade&nbsp;items are products that are made and/or designed by you, the seller. If you sell handmade items, you agree that:</p>
            <ol>
              <li>All handmade items are made or designed by you. If you work with a production partner, you must disclose that production partner in your relevant listings.</li>
              <li>You are using your own photographs&mdash;not stock photos, artistic renderings, or photos used by other sellers or sites.</li>
              <li>If you are selling personalized or made-to-order items you agree that:</li>
            </ol>
            <p>&nbsp;</p>
            <ol>
              <li>All listings are available for purchase at a set price.</li>
              <li>If you are using photographs of previous work with options for customization (like color choices) included in the listing, it is clear in your description that the photos shown are just examples.</li>
            </ol>
            <p>What Can't be Sold on Simply Made Local</p>
            <p>Even if they otherwise meet our marketplace criteria,&nbsp;prohibited items,&nbsp;services, and&nbsp;items that violate our intellectual property policies&nbsp;are not allowed to be sold on Simply Made Local.</p>
            <p>Reselling&nbsp;is not allowed. Reselling refers to listing an item as handmade when you were not involved in designing or making that item.</p>
            <p>Keep in mind that members may flag listings that appear to violate our policies for Simply Made Local&rsquo;s review. Simply Made Local may remove any listings that violate our policies. Simply Made Local may also suspend or terminate your account for any violations. You&rsquo;ll still be on the hook to pay any outstanding fees on your Simply Made Local statement. You can find more information in our&nbsp;Term of Service Agreement</p>
            <p>&nbsp;</p>
          </div>
        </DefaultLayout>
    );
}

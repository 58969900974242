import React, { useState, useEffect } from "react";

// STYLING
import "./index.scss";

// UTILITY
import { useCtxProvider } from "../../context/context.js";
import { create_error_element } from "../../js-utilities/error_arrays.js";

// COMPONENTS
import DefaultLayout    from "../../layouts/default.js";
import Spinner          from "../../components/global/Spinner.js";

import BuyerProfile     from "./buyer/BuyerProfile.js";
import SellerProfile    from "./seller/SellerProfile.js";

export default function Profile({ history }) {

    const [loading, set_loading] = useState(true);

    const { user, is_verifying_user, ctx_dispatch } = useCtxProvider();

    const [user_type, set_user_type] = useState(type_of_user(user));

    useEffect(() => {
        if (user) {
            set_user_type(type_of_user(user));
            set_loading(false);
        }
        else if (is_verifying_user) {
            set_loading(true);
        } else {
            let error = create_error_element({msg: "You need to login to access that."});
            ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
            
            history.push("/login");
        }
    }, [user, is_verifying_user, ctx_dispatch, history]);
    
    return (
        loading ?
        <Spinner classes="spinner-full" /> :
        <DefaultLayout>
            {
                user_type ?
                <div className="profile-self-container">
                    {
                        user_type === "buyer" ?
                        <BuyerProfile user={user} /> :
                        <SellerProfile user={user} />
                    }
                </div> :
                ""
            }
        </DefaultLayout>
    );      
};


function type_of_user(user) {
    return user && user.type;
};

import React, { useState, useEffect } from "react";

// STYLING

// UTILITY
import { useCtxProvider } from "../../context/context.js";

// COMPONENTS
import Spinner from "../../components/global/Spinner.js";
import AdminUserTable from "./users/AdminUserTable.js";


export default function AdminUsers(_) {

    const [loading, set_loading] = useState(true);
    //const [filters, set_filters] = useState({});
    const [users, set_users] = useState([]);
    
    const [load_more, set_load_more] = useState(false);
    const [loading_more, set_loading_more] = useState(false);
    
    const { admin_state } = useCtxProvider();

    useEffect(() => {
        if (admin_state.is_connected) {
            admin_state.channel.push("get_users", {})
                       .receive("ok", (payload) => {
                           set_users(payload.users);
                           set_load_more(payload.has_more);
                           set_loading(false);
                       })
                       .receive("error", (e) => {
                           console.log("error retrieving users for admin", e);
                           set_loading(false);
                       });
        };
    }, [admin_state.channel, admin_state.is_connected]);

    function load_next() {
        let last_user = users[users.length - 1],
            last_id = last_user.id;

        set_loading_more(true);
        admin_state.channel.push("get_users", {last_id: last_id})
                   .receive("ok", (payload) => {
                       set_users([...users, ...payload.users]);
                       set_load_more(payload.has_more);
                       set_loading_more(false);
                   })
                   .receive("error", (e) => {
                       console.log("error retrieving users for admin", e);
                       set_loading_more(false);
                   });
    };

    return (
        <div className="admin-users">
            {
                loading ?
                <Spinner classes="spinner-full" /> :
                <AdminUserTable users={users} />
            }
            {
                load_more && !loading_more ?
                <button type="button" className="btn primary-gradient fx-1-0-0 mx-auto px-1" style={{fontSize: "18px", textAlign: "center"}} onClick={() => load_next()}>Load more Users...</button> :
                loading_more ?
                <Spinner /> :
                ""
            }
        </div>
    );
};

import React, { useState, useEffect, useRef } from "react";
import { NavLink, Link, withRouter } from "react-router-dom";

// STYLES
import "./navigation.scss";

// UTILITY
import { useCtxProvider, get_category } from "../../../context/context.js";
import { main_profile_route, profile_routes } from "../../../context/user_routes.js";
import { get_display_name } from "../../../context/user_helpers.js";
import { BACKEND_URL } from "../../../env/env.js";

// COMPONENTS
import Logo from "../Logo.js";
import ProfileAvatar from "../../../scenes/profile/shared/ProfileAvatar.js";
import Spinner from "../Spinner.js";

import NavigationDropdown from "./NavigationDropdown.js";
import MessageElement     from "./MessageElement.js";

const axios = require("axios");

function NavigationView({ history }) {

    const [search, set_search] = useState("");
    const [clean_search, set_clean_search] = useState(false);
    const [show_search, set_show_search] = useState(false);
    const [search_select, set_search_select] = useState("");
    const [show_dropdown, set_show_dropdown] = useState(false);

    const [debouncing, set_debouncing] = useState(false);
    const [searching, set_searching] = useState(false);
    const [search_results, set_search_results] = useState([]);

    const [show_user_dropdown, set_show_user_dropdown] = useState(false);

    const input_ref = useRef(null);

    const { mobile, categories, user, ctx_dispatch, click } = useCtxProvider();

    const [user_routes, set_user_routes] = useState(profile_routes(user));

    useEffect(() => {
        set_show_dropdown(false);
        set_show_user_dropdown(false);
    }, [history.location]);

    useEffect(() => set_user_routes(profile_routes(user)), [user]);

    function set_and_maybe_search(event, search_category) {
        let val = event.target.value
        set_search(val);
        if (val !== "" && val.length > 2) {
            set_searching(true);
            if (debouncing) { clearInterval(debouncing); }
            set_debouncing(setTimeout(() => do_search(search_category), 500));
        } else {
            set_search_results([]);
        }
    };

    function set_category_and_maybe_search(event) {
        let search_category = get_category(event.target.value, categories);
        set_search_select(search_category);
        set_and_maybe_search({target: {value: search}}, search_category);
    };

    function decide_search_click() {
        if (mobile && !show_search) {
            set_show_search(true);
            setTimeout(() => input_ref.current.focus(), 1);
        } else if (mobile && search === "") {
            set_show_search(false);
        } else if (mobile) {
            let event = document.createEvent("Event");
            event.initEvent("fake_submit", true, true);
            handle_search(search, event);
        } else {
            input_ref.current.focus();
        }
    };

    function toggle_mobile_dropdown() {
        if (mobile) {
            set_show_search(false);
            if (show_dropdown) {
                set_show_dropdown(false);
            } else {
                set_show_dropdown(true);
            }
        }
    };

    function decide_reset_search() {
        if (mobile && search === "") {
            set_show_search(false);
        } else {
            set_search("");
        }
    }

    function open_user_dropdown(event) {
        event.stopPropagation();
        set_show_user_dropdown(!show_user_dropdown);
    };

    function handle_search(event) {
        event.preventDefault();
        do_search(search, search_select);
    };

    function do_search(search_category) {
        set_searching(true);
        return axios({
            method: "POST",
            url: `${BACKEND_URL()}/api/v1/search`,
            data: {search: search, category: (search_category ? search_category : search_select)}
        })
          .then(({data}) => {
              if (data.success && data.results.length > 0) {
                  set_search_results(data.results);
              } else {
                  set_search_results([{no_results: true}]);
              }
              set_searching(false);
          })
          .catch((e) => {
              set_search_results([]);
              set_searching(false);
          });
    };

    useEffect(() => {
        if (search === "") {
            set_search_results([]);
        }
    }, [search]);

    useEffect(() => set_clean_search(search !== ""), [search]);

    useEffect(() => {
        set_show_user_dropdown(false);
        set_search_results([]);
    }, [click]);

    return (
      <div className="navigation children-mx-1" style={mobile && show_dropdown ? {minHeight: "100vh"} : {}}>
          <div className="navigation-mobile-dropdown" onClick={() => toggle_mobile_dropdown() }>
              <button className="primary-gradient navigation-mobile-dropdown-button"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.19 22.13"><g><g><polyline points="1.06 1.06 11.06 11.06 1.06 21.07"/></g></g></svg></button>
          </div>
          <div className="navigation-logo">
              <Link to="/">
                  <Logo width="100%" height="auto" />
              </Link>
          </div>
          <div className="navigation-search">
              <button
                className="navigation-search-icon"
                onClick={() => decide_search_click() }
              />
              <div className={`navigation-search-input ${show_search && mobile ? "show-mobile" : ""}`}>
                  <form onSubmit={(event) => handle_search(event)}>
                      <input
                        type="text"
                        name="nav-search"
                        id="nav-search"
                        className="nav-search"
                        value={search}
                        onChange={(event) => set_and_maybe_search(event)}
                        placeholder="Search Simply Made Local"
                        ref={input_ref}
                      />
                      <button type="submit" style={{display: "none"}}/>
                      {clean_search || mobile ? <button
                          type="button"
                          className="input-clear"
                          onClick={() => decide_reset_search()}/> :
                        ""}
                  </form>
                  {
                      searching ?
                        <div className="navigation-search-results">
                            <div style={{height: "40px"}}>
                                <Spinner />
                            </div>
                        </div> :
                        (search_results.length > 0) ?
                          <div className="navigation-search-results">
                              {search_results.map((product) => {
                                  if (product.no_results) {
                                      return <p className="navigation-search-result color-primary" key="search-product-no-results">No results</p>
                                  } else {
                                      if (product.result_type === "product") {
                                          return <Link className="navigation-search-result color-primary" to={`/product/${product.id}`} key={`search-product-${product.id}`}><span className="result-type-product">Product</span> {product.display_text}</Link>
                                      } else if (product.result_type === "seller") {
                                          return <Link className="navigation-search-result color-primary" to={`/seller/${product.id}`} key={`search-seller-${product.id}`}><span className="result-type-maker">Maker</span> {product.display_text}</Link>
                                      }
                                  }
                              })}
                          </div> :
                          ""
                  }
              </div>
          </div>
          <div className="navigation-actions">
              <div className="navigation-categories">
                  <div className="select-dropdown-wrapper">
                      <select
                        name="search-categories"
                        id="search-categories"
                        className="select-dropdown mx-auto"
                        value={search_select ? search_select.slug : ""}
                        style={{width: "90%"}}
                        onChange={(event) => set_category_and_maybe_search(event)}
                      >
                          <option>Category</option>
                          {(categories || []).map(({title, slug}) => <option key={`search-category-${slug}`} value={slug}>{title}</option> )}
                      </select>
                  </div>
              </div>
              <div className="navigation-events pl-1">
                  <Link className="link-text-inverse t-center select-dropdown" to="/events">Events</Link>
              </div>
              {
                  user ?
                    <div className="navigation-session">
                        <MessageElement />
                        <div className="navigation-no-session">
                            <Link className="btn primary-gradient" to="/blog">Blog</Link>
                        </div>
                        <div className="navigation-session-user">
                            <Link className="link-text-inverse t-right" to={main_profile_route(user)}>
                                {get_display_name(user)}
                            </Link>
                            <div className="navigation-avatar-container" onClick={(event) => open_user_dropdown(event) }>
                                <ProfileAvatar user={user} />
                                <svg
                                  className="navigation-dropdown"
                                  style={{transform: "rotate(90deg)", width: "10px", height: "10px", marginLeft: "5px"}}
                                  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.95 15.9"><g><g><path d="M1.41,15.9a1.44,1.44,0,0,1-1-.41,1.42,1.42,0,0,1,0-2L6,8,.41,2.41a1.41,1.41,0,0,1,2-2L10,8,2.41,15.49A1.44,1.44,0,0,1,1.41,15.9Z"/></g></g>
                                </svg>
                            </div>
                            {
                                show_user_dropdown ?
                                  <div className="navigation-user-dropdown">
                                      {user_routes.map(([route, name]) => <NavLink exact={true} activeClassName="active-link" to={route} key={route}>{name}</NavLink>)}
                                      <button
                                        type="button"
                                        className="btn primary-gradient"
                                        onClick={(_event) => ctx_dispatch({type: "signout"}) }
                                      >Sign Out</button>
                                  </div> :
                                  ""
                            }
                        </div>
                    </div> :
                    <div className="navigation-no-session">
                        <Link className="btn primary-gradient" to="/blog">Blog</Link>
                        <Link className="link-text-inverse fx-1-0 t-center" to="/login">Login</Link>
                        <Link className="btn primary-gradient" to="/signup">Sign Up</Link>
                    </div>
              }
          </div>
          {
              show_dropdown ?
                <NavigationDropdown
                  set_show_dropdown={set_show_dropdown}
                  user={user}
                /> :
                ""
          }
      </div>
    );
};

export default withRouter(NavigationView);

import { CLOUDFRONT_PUBLIC } from "../env/env.js";

export const Products = [
    {picture: `${CLOUDFRONT_PUBLIC()}/just_for_mock/product-picture-1.jpg`, product_name: "Artists-drawn Sneakers with vibrant colors", product_id: 1, display_price: "64.00", seller: { id: 1, name: "Unique Creations", icon: `${CLOUDFRONT_PUBLIC()}/just_for_mock/icon-seller-1.png`}},
    {picture: `${CLOUDFRONT_PUBLIC()}/just_for_mock/product-picture-2.jpg`, product_name: "Hand Made Soap", product_id: 2, display_price: "13.00", seller: { id: 4, name: "Crazy Natural Products", icon: `${CLOUDFRONT_PUBLIC()}/just_for_mock/icon-seller-4.png`}},
    {picture: `${CLOUDFRONT_PUBLIC()}/just_for_mock/product-picture-3.jpg`, product_name: "Avery Wood Porch Swing Bed Daybed, Twin or Crib Size", product_id: 3, display_price: "1500.00", seller: { id: 1, name: "Unique Creations", icon: `${CLOUDFRONT_PUBLIC()}/just_for_mock/icon-seller-1.png`}},
    {picture: `${CLOUDFRONT_PUBLIC()}/just_for_mock/product-picture-4.jpg`, product_name: "Handcrafted Leather Bag", product_id: 4, display_price: "143.00", seller: { id: 2, name: "American Native Goods", icon: `${CLOUDFRONT_PUBLIC()}/just_for_mock/icon-seller-2.png`}},
    {picture: `${CLOUDFRONT_PUBLIC()}/just_for_mock/product-picture-5.jpg`, product_name: "Custom Knit Scarves", product_id: 5, display_price: "34.00", seller: { id: 5, name: "Scarves Company", icon: `${CLOUDFRONT_PUBLIC()}/just_for_mock/icon-seller-3.png` }}
];


export const BoxItems = [
    {picture: `${CLOUDFRONT_PUBLIC()}/just_for_mock/boxed-items-1.jpg`, title: "Best Seasonal Shops of 2019", subtitle: "Fayeteville, AR", highlight: "#f07765", action: {text: "Browse", to: "/somewhere"}},
    {picture: `${CLOUDFRONT_PUBLIC()}/just_for_mock/boxed-items-2.jpg`, title: "Newest Arrivals", subtitle: "Fayeteville, AR", highlight: "#f8cd6c", action: {text: "Browse", to: "/somewhere"}},
    {picture: `${CLOUDFRONT_PUBLIC()}/just_for_mock/boxed-items-3.jpg`, title: "Most Delicious In Town", subtitle: "Fayeteville, AR", highlight: "#4bb9ea", action: {text: "Browse", to: "/somewhere"}}
]

    
export const PopularItems = createRandomItems(8);
export const ApparelItems = createRandomItems(6);
export const HomeGardenItems = createRandomItems(9);

function createRandomItems(length) {
    var items = [];

    for (var i = 0; i < length; i++) {
        let rand = getRandomInt(0, 4);
        items.push(Products[rand]);
    }

    return items;
}

function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}




import { BACKEND_URL } from "../../../../env/env.js";
import { format_price } from "../../../../js-utilities/currency.js";
import { convert_file } from "../../../../js-utilities/image_helpers.js";

import { request_with_token } from "../../../../context/axios_requests.js";


const empty_option_section = {
    section: "Untitled",
    options: []
};

const new_option = { title: "Untitled" };

function set_option({ value, field, index }, options) {
    var option = {...options[index]};
    switch(field) {
        case "section":
            option.section = value;
            break;
        case "move_section":
            let length = options.length,
                to_replace = index + value,
                displaced;

            if (to_replace >= 0 && to_replace < length) {
                displaced = {...options[to_replace]};
                options[to_replace] = {...option};
                options[index] = displaced;
            }
            return [...options];
        case "delete_section":
            return options.reduce((acc, section, r_index) => {
                if (index === r_index) { return acc; }
                else {
                    acc.push(section);
                    return acc;
                }
            }, []);
        case "option":
            switch(value) {
                case "create":
                    option.options = [...option.options, {...new_option}];
                    break;
                default:
                    break;
            };
            break;
        case "option_edit":
            let [option_index, option_new_value] = value;
            option.options[option_index] = {title: option_new_value};
            option.options = [...option.options];
            break;
        case "option_delete":
            option.options = option.options.reduce((acc, r_option, r_index) => {
                if (r_index === value) { return acc; }
                else {
                    acc.push(r_option);
                    return acc;
                }
            }, []);
            break;
        default:
            break;
    };

    options[index] = {...option};
    return [...options];
};


export function upload_image(type, blob, ctx, session_token) {
    // type will either be "main_image" or an integer index of an image in the images array
    // if it's not "main", we use the index to figure out if we should delete a previously uploaded image (like if the user clicked on a specific image to upload another, it probably means they want to substitute it)
    let { images } = ctx,
        replace;
    
    if (type !== "main_image") {
        replace = images[type] ? images[type].id : "";
    } else {
        replace = "";
    }

    let form_data = new FormData();
    form_data.append("image", blob);
    form_data.append("type", type === "main_image" ? type : "extra");
    form_data.append("replace", replace);
    form_data.append("id", ctx.id);
    

    return request_with_token({
        method: "POST",
        url: `${BACKEND_URL()}/api/v1/products/seller/image`,
        data: form_data,
        headers: {"Content-Type": "multipart/form-data"}
    }, session_token);
};


export function build_images_array(images) {
    return images.length < 8 ? [...images, ...number_of_placeholders(8 - images.length)] : images; 
};

export const number_of_placeholders = (n) => {
    n = n ? n : 8;
    return [...Array(n).keys()].reduce((acc, _) => {
        return [...acc, false];
    }, []);
};


export function listing_reducer(state, action) {
    switch(action.type) {
        case "reset":
            return {...empty_start_ctx};
        case "set_product":
            let product = action.value;
            
            return Object.keys(state).reduce((acc, key) => {
                if (product[key] && key === "options") {
                    acc[key] = [...product[key]];
                } else if (product[key] && key === "images") {
                    acc[key] = build_images_array(product[key]);
                } else if (product[key] && key === "price") {
                    acc[key] = format_price(product[key]);
                } else if (product[key] !== undefined) { acc[key] = product[key] }
                return acc;
            }, state);
        case "set":
            if (action.field === "images") {
                state[action.field] = build_images_array(action.value);
            } else {
                state[action.field] = action.value;
            }
            return {...state};
        case "set_primary_category":
            return {...state, categories: action.value};
        case "set_subcategory":
            return {...state, subcategories: action.value};
        case "create_option_section":
            return {...state, options: [...state.options, {...empty_option_section}]};
        case "set_option":
            return {...state, options: set_option(action, state.options)};
        case "add_option":
            return {...state, options: set_option(action, state.options)};
        case "check_ready":
            let image_ready = state.main_image && state.main_image !== "loading" ? true : false;
            if (state.id &&
                image_ready &&
                state.title &&
                state.price &&
                state.categories) {
                return {...state, ready: true}
            } else {
                return {...state, ready: false};
            }
        default:
            return state;
    };
};


export const empty_start_ctx = {
    id: null,
    title: "",
    subtitle: "",
    description: "",
    price: "",
    currency: "usd",
    main_image: false,
    published: false,
    draft: true,
    paused: false,
    images: [],
    options: [],
    categories: null,
    subcategories: null,
    ready: false
};

export const valid_fields = Object.keys(empty_start_ctx).filter((key) => {
    return !["published", "draft", "paused", "ready", "main_image", "images"].includes(key);
});

export function maybe_upload_image(type, file, ctx, dispatch, session_token, set_loading) {

    let quality = 0.80;
    let file_type = "image/jpeg";
    let max_side = 1000;
    
    return new Promise((resolve, reject) => {
        convert_file(file, quality, file_type, max_side)
            .then((canvas) => {
                canvas.toBlob((blob) => {
                    set_loading(true);
                    upload_image(type, blob, ctx, session_token)
                        .then(({data}) => {
                            if (data.success) {
                                dispatch({type: "set_product", value: data.product});
                                dispatch({type: "check_ready"});
                                resolve(data)
                            } else {
                                reject(data);
                                // TODO ADD ERROR
                            }
                            set_loading(false);
                        })
                        .catch(e => {
                            console.log("error upload_image", e);
                            set_loading(false);
                            reject(e);
                        });
                    
                }, file_type, quality);
            });
    });

};

export const max_length_title = 40;
export const max_length_subtitle = 300;
export const max_length_description = 1000;


import React from "react";

// STYLING

// UTILITY

// COMPONENTS

export default function CalendarIcon({ style, classes }) {
    let styling = style ? style : {width: "36px", height: "37px"};
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.51 23.79" className={classes ? classes : ""} style={styling}><g><g><rect width="23.51" height="6.81" rx="0.79"/><rect  x="16.7" y="8.49" width="6.81" height="6.81" rx="0.79"/><rect x="8.35" y="8.49" width="6.81" height="6.81" rx="0.79"/><rect y="8.49" width="6.81" height="6.81" rx="0.79"/><rect x="16.7" y="16.98" width="6.81" height="6.81" rx="0.79"/><rect  x="8.35" y="16.98" width="6.81" height="6.81" rx="0.79"/><rect y="16.98" width="6.81" height="6.81" rx="0.79"/></g></g></svg>
    );
};

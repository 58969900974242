import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// STYLING
import "../../components/sections/banners/banner.scss";
import "./events_landing.scss";

// UTILITY
import { image_url, BACKEND_URL } from "../../env/env.js";
import { useCtxProvider }         from "../../context/context.js";

// COMPONENTS
import EventCard from "./EventCard.js";
import Spinner   from "../../components/global/Spinner.js";

const background = image_url("main/events-header.jpg");

const axios = require("axios");

export default function EventsLanding({ history }) {

    const [loading, set_loading] = useState(true);
    const [events, set_events] = useState([]);
    const [own_events, set_own_events] = useState(false);
    const [load_more, set_load_more] = useState(false);
    const [loading_more, set_loading_more] = useState(false);

    const { user, user_channel, is_connected } = useCtxProvider();

    useEffect(() => {
        axios({
            method: "POST",
            url: `${BACKEND_URL()}/api/v1/events`
        })
            .then(({data}) => {
                if (data.success) {
                    set_events(data.events);
                    set_load_more(data.has_more);
                }
                set_loading(false);
            })
            .catch((e) => {
                console.log("Error fetching events", e);
                set_loading(false);
            });
    }, []);

    useEffect(() => {
        if (is_connected) {
            user_channel.push("get_own_events")
                        .receive("ok", (payload) => {
                            set_own_events(payload.events)
                        })
                        .receive("error", (error) => {
                            set_own_events(false)
                        });

        }
    }, [is_connected, user_channel]);

    function goto_signin() {
        localStorage.setItem("sml_redirect_signin", history.location.pathname);
        history.push("/login");
    };

    function load_next() {
        set_loading_more(true);
        let last_event = events[events.length - 1],
            last_datetime = last_event.datetime,
            last_ids;

        last_ids = events.reduce((acc, {datetime, id}) => {
            if (datetime === last_datetime) {
                acc.push(id);
            }
            return acc
        }, []);

        axios({
            method: "POST",
            url: `${BACKEND_URL()}/api/v1/events`,
            data: {
                last_datetime: last_datetime,
                except: last_ids
            }
        })
            .then(({data}) => {
                if (data.success) {
                    set_events([...events, ...data.events]);
                    set_load_more(data.has_more);
                }
                set_loading_more(false);
            })
            .catch((e) => {
                console.log("Error fetching events", e);
                set_loading_more(false);
            });
        
    };

    return (
        loading ?
        <Spinner classes="full-spinner" /> :
        <div className="events-landing">
            <div className="banner banner-events" style={{backgroundImage: `url(${background})`}}>
                <div className="white-card">
                    <p>Browse Exciting <strong>Local-Made Events</strong> Or Create Your Own!</p>
                    {
                        user ?
                        <Link to="/events/create" className="btn primary-gradient btn-size-normal btn-len-normal mx-auto my-2">Create an Event</Link> :
                        <button type="button" className="btn primary-gradient btn-size-normal btn-len-large mx-auto my-2" onClick={goto_signin}>Sign In to Create an Event</button>
                    }
                </div>
            </div>
            {
                user && user.type === "seller" ?
                <div className="events-list">
                    {
                        (Array.isArray(own_events) && own_events.length > 0) ?
                        own_events.map((event) => <EventCard event={event} own_event={true} key={event.id} />) :
                        <p style={{fontSize: "30px", fontWeight: "bold"}} className="my-2 t-center">You haven't created any events yet.</p>
                    }
                </div> :
                ""
            }

            {user && user.type === "seller" ? <div className="section-divider secondary-divider"/> : ""}
            <h2 className="section-title t-center mt-4">Coming Up Soon</h2>
            <div className="events-list">
                {
                    (events.length > 0) ?
                    events.map((event) => <EventCard event={event} key={event.id} />) :
                    <p style={{fontSize: "30px", fontWeight: "bold"}}>No upcoming Events for now...</p>
                }
                <div className="fx-row justify-content-center align-items-center m-2">
                    {
                        load_more && !loading_more ?
                        <button type="button" className="btn primary-gradient fx-1-0-0 mx-auto" style={{fontSize: "18px", textAlign: "center"}} onClick={() => load_next()}>Load more events...</button> :
                        loading_more ?
                        <Spinner /> :
                        "" 
                    }
                </div>
            </div>
        </div>
    );
}

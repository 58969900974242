import React from "react";

// STYLES
import "./index.scss";

// UTILITY

// COMPONENTS
import DefaultLayout from "../../layouts/default.js";

export default function Somewhere(_props) {

    return (
        <DefaultLayout>
            <div className="how-it-works-cover">
              <div className="how-it-works-cover__title">
                <h1>How It Works</h1>
              </div>
            </div>

            <div className="how-it-works">
              <div className="how-it-works__row">
                <div className="how-it-works__column">
                  <div className="how-it-works__item__image">
                    <img alt="Signup" src="/hiw1-signup.svg" />
                  </div>
                </div>
                <div className="how-it-works__column">
                  <div className="how-it-works__item__content">
                    <h2>Quick sign up.</h2>
                    <p>Artists and crafters easily register for an account, and in a couple of simple steps, they will post handmade products for sale, absolutely free.</p>
                  </div>
                </div>
              </div>

              <span className="section-divider"></span>

              <div className="how-it-works__row">
                <div className="how-it-works__column">
                  <div className="how-it-works__item__image">
                    <img alt="Upgrade" src="/hiw2-upgrade.svg" />
                  </div>
                </div>
                <div className="how-it-works__column">
                  <div className="how-it-works__item__content">
                    <h2>Upgrade when you want.</h2>
                    <p>Artists can upgrade their monthly membership for exclusive benefits that fit the needs of their small business.</p>
                  </div>
                </div>
              </div>

              <span className="section-divider"></span>

              <div className="how-it-works__row">
                <div className="how-it-works__column">
                  <div className="how-it-works__item__image">
                    <img alt="Reach" src="/hiw3-reach.svg" />
                  </div>
                </div>
                <div className="how-it-works__column">
                  <div className="how-it-works__item__content">
                    <h2>Reach Buyers From Your Local Area and Across the USA.</h2>
                    <p>Buyers access Simply Made Local and shop for handmade products, locally and countrywide.</p>
                  </div>
                </div>
              </div>

              <span className="section-divider"></span>

              <div className="how-it-works__row">
                <div className="how-it-works__column">
                  <div className="how-it-works__item__image">
                    <img alt="Message" src="/hiw4-message.svg" />
                  </div>
                </div>
                <div className="how-it-works__column">
                  <div className="how-it-works__item__content">
                    <h2>Close the Deal Over Messaging.</h2>
                    <p>Buyers message the artist, and both parties agree on delivery and meeting location if local, or the
                      preferred shipping method. Both parties will also agree on payment such as cash, or a third party
                      payment processor (Venmo, Paypal, Zelle.)</p>
                  </div>
                </div>
              </div>

              <span className="section-divider"></span>

              <div className="how-it-works__row">
                <div className="how-it-works__column">
                  <div className="how-it-works__item__image">
                    <img alt="Meet" src="/hiw5-meet.svg" />
                  </div>
                </div>
                <div className="how-it-works__column">
                  <div className="how-it-works__item__content">
                    <h2>Meet and Deliver or Choose Shipping.</h2>
                    <p>Artists and buyers meet locally, and the artist gets paid for their handmade product, or the artist may
                      ship the product directly to the buyer.</p>
                  </div>
                </div>
              </div>

              <span className="section-divider"></span>
              <br />

            </div>

            <div className="how-it-works__footer">
              <div className="how-it-works__footer__icon">
                <img alt="Winner Circle" src="winner-circle.svg" />
              </div>
              <h2>Everyone wins.</h2>
              <p style={{textAlign: 'left'}}>By choosing Simply Made Local, Buyers support their local community of Artisans as well as Makers
                across the United States. Crafters can connect with customers in person and decrease their carbon
                footprint by delivering locally and avoiding shipping. Shipping is also available for Artists and Buyers who
                prefer that method of delivery.</p>
            </div>

            <br />
            <br />
        </DefaultLayout>
    );
};

import React from "react";

// STYLES
import "./wide_notifications.scss";

// UTILITY
import { useCtxProvider } from "../../context/context.js";

// COMPONENTS

export default function WideNotifications(_props) {

    const { errors, notices, ctx_dispatch } = useCtxProvider();
    return (
        <div className="global-notifications-container">
            {notices.map(({field, msg, id}) => {
                return (
                    <div className="notification-notice" key={`${field}-${id}`}>
                        <button className="notification-close" type="button" onClick={() => ctx_dispatch({type: "remove_notice", id: id})} />
                        <p>
                            {field ? <span className="notification-field">{field}</span> : ""}
                            <span className="notification-msg">{msg}</span>
                        </p>
                    </div>
                );
            })}
            {errors.map(({field, msg, id}) => {
                return (
                    <div className="notification-error" key={`${field}-${id}`}>
                        <button className="notification-close" type="button" onClick={() => ctx_dispatch({type: "remove_error", id: id})} />
                        {field ? <span className="notification-field">{field}</span> : ""}
                        <span className="notification-msg">{msg}</span>
                    </div>
                );
            })}
        </div>
    );
};

import React, { useState, useEffect } from "react";

// STYLES

// UTILITY
import { STRIPE_PUBLIC_KEY } from "../../../env/env.js";

import { check_requirements, requirements } from "../shared_account_details.js";

import { plans_object } from "../../../env/plans.js";

// COMPONENTS
import Spinner from "../../../components/global/Spinner.js";
import SignupPlanCard from "./SignupPlanCard.js";
import InjectedCheckoutForm from "./StripeForm";

// STRIPE
import { StripeProvider, Elements } from "react-stripe-elements";


const stripe_snippet_id = "stripe-js-snippet";

export default function Step5Seller({ dispatch, state }) {

    const [loading, set_loading] = useState(true);
    const [plan, set_plan] = useState(false);

    const [is_stripe_loaded, set_stripe_loaded] = useState(false);

    useEffect(() => {
        let [valid,] = check_requirements(requirements().required_fields.seller, state, false);

        if (valid) {

            if (!document.getElementById(stripe_snippet_id)) {
                let script = document.createElement("script");
                script.src = "https://js.stripe.com/v3/";
                script.id = stripe_snippet_id;
                script.onload = () => set_stripe_loaded(true);
                
                document.head.appendChild(script);
            } else {
                set_stripe_loaded(true);
            }
            set_plan(plans_object[state.plan]);
            set_loading(false);
        } else {
            dispatch({type: "reset"});
        }
        
    }, [dispatch]);


    return (
        loading ?
        <Spinner classes="spinner-full" /> :
        <div className="signup-container-step bg-white">
            <div className="fx-row align-items-center children-mx-2 sm:fx-column fx-100">
                <div className="signup-step-title fx-1-0">
                    <h2>You're opening a <span className="color-text t-capitalize">{state.plan} store</span>!</h2>
                </div>
                {
                    plan ? 
                    <div className="signup-plan-container short-card" style={{minWidth: "320px"}}>
                        <SignupPlanCard plan_name={plan.name} plan_price_display={plan.price_display} plan_icon={plan.icon} no_select_plan={true} classes="short-card" />
                    </div> :
                    ""
                }
            </div>
            {
                is_stripe_loaded ?
                <StripeProvider apiKey={STRIPE_PUBLIC_KEY()}>
                    <Elements>
                        <InjectedCheckoutForm dispatch={dispatch} state={state} />
                    </Elements>
                </StripeProvider> :
                <Spinner classes="spinner-full" />
            }
        </div>
    );
};

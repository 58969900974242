import React from "react";
import { Link } from "react-router-dom";

// STYLING
import "./event_card.scss";

// UTILITY
import { decide_image } from "./shared.js";
import { get_date, get_am_pm } from "../../js-utilities/dates_and_times.js";
import { font_sizing }            from "../../js-utilities/text_helpers.js";
import { useCtxProvider }         from "../../context/context.js";

// COMPONENTS
import DoubleArrow from "../../components/svg_elements/DoubleArrow.js";

export default function EventCard({event, own_event}) {

    let { mobile, history } = useCtxProvider();

    let image = decide_image(event.main_image);
    let date = get_date(event.date);
    let time_start = get_am_pm(event.time_start);
    let time_end = get_am_pm(event.time_end);
    let title = event.title || "Untitled";

    let address = event.address || "No Address yet";

    let style_font_size_title = {fontSize: `${font_sizing((mobile ? 18 : 24), 24, title)}px`}
    let style_font_size_address = {fontSize: `${font_sizing((mobile ? 16 : 24), 85, address)}px`}

    return (
        <Link to={`/events/${own_event ? "edit/" : ""}${event.id}`}>
            <div className="event-card">

                <div className="event-card-image" style={{backgroundImage: `url(${image})`}} />
                <div className="event-card-info">
                    <h6 className="event-card-info-title" style={style_font_size_title}>
                        {title}
                    </h6>
                    <p className="event-card-info-time">
                        {date} @ {
                            time_start && time_end ?
                            `${time_start.replace(/^0+/, '')} - ${time_end.replace(/^0+/, '')}` :
                            "No times yet"
                        }
                    </p>
                    <p className="event-card-info-address" style={style_font_size_address}>
                        {address}
                    </p>
                </div>
                <div className="event-card-go-to">
                    <button
                        type="button"
                        className="color-primary"
                        onClick={(evt) => {
                            evt.stopPropagation();
                            evt.preventDefault();
                            history.push(`/events/${event.id}`);
                        }}
                    >
                        Go to Event
                        <DoubleArrow />
                    </button>
                    {
                        own_event ?
                        (event.passed ? "Completed" : (event.published ? "Published" : "Draft")) :
                        ""
                    }
                </div>
            </div>
        </Link>
                            );
};

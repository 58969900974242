import React, { useReducer, useEffect } from "react";

import { BACKEND_URL } from "../env/env.js";
import { Socket } from "phoenix";

import { create_error_element } from "../js-utilities/error_arrays.js";

const empty_admin_ctx = {
    user: false,
    session_token: false,
    channel: false,
    is_connected: false,
    is_verifying_user: false,
    ctx_dispatch: false
};

const socket_url = `${BACKEND_URL()}/socket`.replace(/^http/, "ws");

export const SML_ADMIN_TOKEN_KEY = "sml_admin_token";

const axios = require("axios");

function verify(token) {
    return axios({
        method: "POST",
        url: `${BACKEND_URL()}/api/v1/admin/verify`,
        data: {token: token}
    });
};

export default function useAdminCtx(ctx_dispatch, history) {
    const [state, dispatch] = useReducer(admin_reducer, {...empty_admin_ctx, ctx_dispatch: ctx_dispatch});

    useEffect(() => {
        
        dispatch({type: "verify", dispatcher: dispatch});
    }, []);

    useEffect(() => {
        if (!state.socket && state.session_token) {
            dispatch({type: "set_socket", dispatcher: dispatch});
        }
    }, [state.session_token]);

    useEffect(() => {
        if (state.socket && state.user &&
            (!state.channel || !["joined", "joining"].includes(state.channel.state))) {
            dispatch({type: "set_channel", dispatcher: dispatch});
        }
    }, [state.socket, state.user]);

    return [state, dispatch];
};


function admin_reducer(state, action) {
    var token, user, remember_me;
    switch(action.type) {
        case "set_session":
            token = action.storage[SML_ADMIN_TOKEN_KEY] ? action.storage[SML_ADMIN_TOKEN_KEY] : false;
            return {...state, session_token: token};
        case "signin":
            user = action.value.user;
            token = action.value.token;
            remember_me = action.value.remember_me;
            if (token) {
                set_token(SML_ADMIN_TOKEN_KEY, token, remember_me);
                localStorage.setItem("signin_admin", Date.now());
                localStorage.removeItem("signin_admin");
            } else {
                user = false;
                delete_token(SML_ADMIN_TOKEN_KEY);
            }

            return {...state, user: user, session_token: token, is_verifying_user: action.is_verifying_user};
        case "verify":
            if (state.session_token && !state.user) {
                verify(state.session_token)
                    .then(({data}) => {
                        if (data.success) {
                            action.dispatcher({type: "signin", value: {user: data.user, token: data.token}, is_verifying_user: false});
                        } else {
                            action.dispatcher({type: "signin", value: {user: false, token: false}, is_verifying_user: false});
                        }
                    })
                    .catch((e) => {
                        action.dispatcher({type: "signin", value: {user: false, token: false}, is_verifying_user: false});
                    });
                return {...state, is_verifying_user: true};
            } else if (!state.user) {
                return {...state, session_token: false, user: false, is_verifying_user: false};
            } else {
                return {...state, is_verifying_user: false};
            }
        case "set_socket":
            state.socket = new Socket(socket_url, { params: { token: state.session_token } });
            state.socket.connect();
            state.socket.onClose((e) => {
                action.dispatcher({type: "connected", value: false})
                state.socket.disconnect();
                state.socket = false;
            });
            state.socket.onError((e) => {
                action.dispatcher({type: "connected", value: false});
                state.socket.disconnect();
                state.socket = false;
            });
            return {...state};
        case "set_channel":
            state.channel = state.socket.channel(`admin:${state.user.id}`, { token: state.session_token });
            return add_channel_listeners(state, action.dispatcher);
        case "signout":
            if (state.channel) {
                state.channel.leave();
                state.channel = false;
            }

            if (state.socket) {
                state.socket.disconnect();
                state.socket = false;
            }

            if (!action.notrigger) {
                localStorage.setItem("signout_admin", Date.now());
                localStorage.removeItem("signout_admin");
            }

            delete_token(SML_ADMIN_TOKEN_KEY)
            state.history.push("/");
            window.location.reload();
            return {...state, user: false, session_token: false, is_verifying_user: false};
        case "connected":
            return {...state, is_connected: action.value}
        default:
            return state;
    };
};


function add_channel_listeners(state, dispatch) {
    state.channel.onClose(() => dispatch({type: "connected", value: false}));

    state.channel.join()
         .receive("ok", (payload) => {
             dispatch({type: "connected", value: true});
             console.log("admin channel joined", payload);
         })
         .receive("error", (payload) => {
             if (payload === "unauthorized") {
                 let error = create_error_element({msg: "Your session has expired"});
                 state.ctx_dispatch({type: "show_error", value: error, dispatcher: state.ctx_dispatch});
                 dispatch({type: "signout"});
                 dispatch({type: "connected", value: false});
             }
         });

    return {...state};
};


function set_token(token_key, token, remember_me) {
    sessionStorage.setItem(token_key, token);
    if (remember_me) {
        localStorage.setItem(token_key, token);
    }
}

function delete_token(token_key) {
    sessionStorage.removeItem(token_key);
    localStorage.removeItem(token_key);
}

import React from "react";

// STYLES

// UTILITY

// COMPONENTS

export default function CarousselButton({ classes, callback }) {
    return <button className={classes} onClick={() => callback()}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.19 22.13"><g><g><polyline points="1.06 1.06 11.06 11.06 1.06 21.07"/></g></g></svg></button>
};

import React from "react";
import { NavLink } from "react-router-dom";

// STYLING
import "./profile_card.scss";

// UTILITY

// COMPONENTS

export default function ProfileCard({label, title, subtitle, btn_text, btn_callback}) {

    return (
        <div className="profile-view-card">
            <p className="profile-view-card-label">{label}</p>
            <div className="profile-view-card-inner">
                <p className="profile-view-card-title">{title ? title : ""}</p>
                <p className="profile-view-card-subtitle">{subtitle ? subtitle : ""}</p>
                {btn_text && btn_callback &&
                  <NavLink className="mx-auto mt-4" to={btn_callback}>
                      <button className="btn font-size-600 primary-gradient">{btn_text} ></button>
                  </NavLink>
                }
            </div>
        </div>
    );
};

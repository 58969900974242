import React, { useState, useEffect } from "react";
import axios from "axios";

// STYLES

// UTILITY
// import { BLOG_BACKEND } from "../../env/env.js"; // Hardcoded Temporarily
import { HIGHLIGHTS } from "../../env/highlights.js";
import { image_url } from "../../env/env.js";
import { parseString } from "../../js-utilities/parse-codes.js";
import { useCtxProvider } from "../../context/context.js";

// COMPONENTS
import DefaultLayout from "../../layouts/default.js";
import Banner from "../../components/sections/banners/banner.js";
import ItemFeed from "../../components/sections/feeds/item_feed.js";
import BoxedComponents from "../../components/lightboxes/boxed_components.js";

export default function Landing(props) {

    const [box_items, set_box_items] = useState([]);
    const { categories } = useCtxProvider();

    useEffect(() => {
        axios.get("https://simplyshopsmall.com/wp-json/wp/v2/posts?per_page=3&_fields=jetpack_featured_media_url,id,title").then(res => {
            set_box_items(map_blogs_to_schema(res.data));
        });
    }, []);

    return (
        <DefaultLayout>
            <div className="main-landing">
                <Banner title="Find a Unique Craft" linkText="How It Works" link="/how-it-works" background="header-background-orange.jpg"/>
                <ItemFeed icon="/icon-popular.png" title="New Arrivals" slug="popular" />
                {
                    categories ?
                    categories.map((cat) => <ItemFeed icon={image_url(cat.image)} title={cat.title} slug={cat.slug} key={cat.slug} />) :
                    ""
                }
                <BoxedComponents title="Explore the World of Handmade" elements={box_items} />
            </div>
        </DefaultLayout>
    )
};


function map_blogs_to_schema(blogs) {
    return blogs.map((blog, index) => {
        return {
            picture: blog.jetpack_featured_media_url,
            title: parseString(blog.title.rendered),
            highlight: HIGHLIGHTS[index],
            action: { text: "Browse", to: `/blog/${encodeURI( parseString(blog.title.rendered) )}/${blog.id}`}
        }
    });
}

import React from "react";

// STYLING

// UTILITY

// COMPONENTS

export default function VerifiedBadge({ styles }) {
    
    styles = Object.assign((styles || {width: "20px", height: "20px", marginLeft: "auto"}), {enableBackground: "new 0 0 28.3 27.3"}); 
    
    return (
        <svg className="verified-badge" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 28.3 27.3" style={styles}>
            <g>
	        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="9.228" y1="0.4964" x2="18.8124" y2="26.0937">
		    <stop  offset="0" style={{stopColor: "#31B4DB"}}/>
		    <stop  offset="1" style={{stopColor: "#00A0C2"}}/>
	        </linearGradient>
	        <path style={{fill: "url(#SVGID_1_)"}} d="M15.8,0.4L15.8,0.4c1.8-0.9,3.9-0.2,4.9,1.6l0,0c0.5,1,1.5,1.7,2.6,1.9l0,0c2,0.3,3.3,2.2,3,4.1l0,0
	        c-0.2,1.1,0.2,2.3,1,3.1l0,0c1.4,1.4,1.4,3.7,0,5.1l0,0c-0.8,0.8-1.2,2-1,3.1l0,0c0.3,2-1.1,3.8-3,4.1l0,0
	        c-1.1,0.2-2.1,0.9-2.6,1.9l0,0c-0.9,1.8-3.1,2.5-4.9,1.6l0,0c-1-0.5-2.2-0.5-3.2,0l0,0c-1.8,0.9-3.9,0.2-4.9-1.6l0,0
	        c-0.5-1-1.5-1.7-2.6-1.9l0,0c-2-0.3-3.3-2.2-3-4.1l0,0c0.2-1.1-0.2-2.3-1-3.1l0,0c-1.4-1.4-1.4-3.7,0-5.1l0,0c0.8-0.8,1.2-2,1-3.1
	        l0,0C1.8,6,3.1,4.2,5,3.9l0,0C6.2,3.7,7.1,3,7.7,2l0,0c0.9-1.8,3.1-2.5,4.9-1.6l0,0C13.6,0.9,14.7,0.9,15.8,0.4z"/>
	        <g>
		    <g>
		        <path style={{fill: "#FFFFFF"}} d="M12.9,17.1L12.9,17.1c-0.3,0-0.5-0.1-0.7-0.3l-2.4-2.4c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l1.7,1.7
		        l4.3-4.3c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4l-5,5C13.4,17,13.1,17.1,12.9,17.1z"/>
		    </g>
	        </g>
            </g>
        </svg>
    );
};

import React from "react";

export default function SignupPlanCard({plan_name, plan_price_display, plan_description, plan_icon, plan_disabled, select_plan, no_select_plan, existing_plan, classes}) {

    return (
        <div className={`signup-plan-card ${classes ? classes : ""}`}>
            {/*<div className="signup-plan-card-icon">*/}
            {/*    {plan_icon}*/}
            {/*</div>*/}
            <div className="signup-plan-card-title">
                <img src='/the-locals.png' width='300px'/>
            </div>
            <div className="signup-plan-card-description">
                <p className="signup-plan-card-price" dangerouslySetInnerHTML={plan_price_display()} />
                {
                    plan_description ?
                    <ul>
                        {plan_description.map(([line, classes], index) => {
                            return <li style={{fontSize: '20px'}} key={`line_${plan_name}_${index}`} className={classes}>{line}</li>
                        })}
                    </ul> :
                    ""
                }
            </div>
            {
                no_select_plan ?
                "" :
                <div className="signup-plan-card-button-container">
                    {
                        existing_plan ?
                        <button type="button" className="btn btn-size-large primary-gradient mx-auto mt-auto" disabled={true} style={{alignSelf: "flex-end"}}>Current Plan</button> :
                        <button type="button" className="btn btn-size-large primary-gradient mx-auto mt-auto" disabled={plan_disabled} style={{alignSelf: "flex-end"}} onClick={() => select_plan(plan_name)}>Select</button>
                    }
                </div>
            }
        </div>
    );
};

import React, { useState, useEffect, useReducer } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

// STYLES
import "./edit_listing.scss";

// UTILITY
import {
    empty_start_ctx,
    listing_reducer,
    maybe_upload_image,
    valid_fields
} from "./edit_listing/shared.js";

import { convert_file } from "../../../js-utilities/image_helpers.js";

import { useCtxProvider, get_category, get_subcategory } from "../../../context/context.js";
import { create_error_element }  from "../../../js-utilities/error_arrays.js";
import { create_notice_element } from "../../../js-utilities/notice_arrays.js";

// COMPONENTS
import ProfileAvatar from "../shared/ProfileAvatar.js";
import ProductImageUploader from "../../../components/upload/ProductImageUploader.js";

export default function EditListingMobile(props) {
    const [step, set_step] = useState(1);
    const [loading, set_loading] = useState(false);


    const { ctx_dispatch, user_channel, session_token, history, categories, subcategories } = useCtxProvider();

    const [ctx, dispatch] = useReducer(listing_reducer, {...empty_start_ctx, images: false});
    
    return (
        <CreateListing
            step={step}
            set_step={set_step}
            props={props}
            ctx={ctx}
            dispatch={dispatch}
            user_channel={user_channel}
            session_token={session_token}
            set_loading={set_loading}
            ctx_dispatch={ctx_dispatch}
            history={history}
            categories={categories}
            subcategories={subcategories}
        />
    )
};
        
function CreateListing(props) {
    let component = null;
    
    switch(props.step) {
        case 1:
            component = <CreateListingStep1 {...props} />;
            break;
        case 2:
            component = <CreateListingStep2 {...props} />;
            break;
        case 3:
            component = <CreateListingStep3 {...props} />;
            break;
        default:
            component = <CreateListingStep1 {...props} />;
    }
    return component
}

function CreateListingStep1({step, set_step, props, ctx, dispatch, ctx_dispatch}) {
    
    const subtitle = "Add a Photo";
    const tips_header = "Photo Tips";
    const section_one_title = "1. Light it well";
    const section_one_text = "If you don't have a photo light kit, that's ok. The outdoors is full of light! Go outside and shoot with indirect sunlight for best results.";
    const section_two_title = "2. Check for blur";
    const section_two_text = "Make sure your photo isn't blurry or low-resolution. Hold your camera steady when shooting. Images 1000px by 1000px are best.";

    

    const { main_image } = ctx;
    
    function image_upload(type, file) {

        let quality = 0.80;
        let file_type = "image/jpeg";
        let max_side = 1000;

        convert_file(file, quality, file_type, max_side)
            .then((_) => dispatch({type: "set", field: "main_image", value: file}))
            .catch((_) => {
                let error = create_error_element({msg: "Please choose an appropriate file type for your product image"});
                ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
            });
    };
    
    return (
        <div className="create-listing">
            <ScrollToTop />
            <ListingHeader props={props} />
            <TitleAndSubtitle subtitle={subtitle} step={step} />
            <ProductImageUploader
                classes="listing__gallery__main"
                file_type="main_image"
                file={main_image}
                upload_callback={image_upload}
                multiple={false}
            >
                <button className="btn btn-size-normal primary-gradient">
                    + Add Photo
                </button>
            </ProductImageUploader>
            <TipsWithTwoSections
                header={tips_header}
                section_one_title={section_one_title}
                section_two_title={section_two_title}
                section_one_text={section_one_text}
                section_two_text={section_two_text}
            />
            <FooterAndButtons step={step} set_step={set_step} />
        </div>
    )
}

function CreateListingStep2({step, set_step, props, ctx, dispatch}) {
    const subtitle = "Add a Title";
    const tips_header = "A Good Caption Includes:";
    const section_one_title = "1. Keywords";
    const section_one_text = "Include multiple names and phrases for the product you're selling so that you capture all the searches!";
    const section_two_title = "2. Use Cases";
    const section_two_text = 'Include some hints about how your product is used. For example "Perfect new mother gift!" or "...for home gardens".';
    const { title } = ctx;
    return (
        <div className="create-listing">
            <ScrollToTop />
            <ListingHeader props={props} />
            <TitleAndSubtitle subtitle={subtitle} step={step} />
            <textarea
                className="create-listing__input-with-placeholder"
                rows="10"
                style={{width: "80%"}}
                name="product-long-description"
                placeholder="Ex: “Hand-Crafted Local Artisan Made Leather Tote just in time for Christmas!”"
                value={title}
                onChange={(event) => dispatch({type: "set", field: "title", value: event.target.value})}
            />
            <TipsWithTwoSections
                header={tips_header}
                section_one_title={section_one_title}
                section_two_title={section_two_title}
                section_one_text={section_one_text}
                section_two_text={section_two_text}
            />
            <FooterAndButtons step={step} set_step={set_step} />
        </div>
    )
}

function CreateListingStep3({step, set_step, props, ctx, dispatch, user_channel, session_token, set_loading, ctx_dispatch, history, categories, subcategories}) {

    const [subcategories_select, set_subcategories_select] = useState(false);

    const { price, categories: category, subcategories: subcategory } = ctx;

    useEffect(() => {
        if (ctx.categories && subcategories) {
            set_subcategories_select(subcategories[ctx.categories.slug]);
        }
    }, [ctx, ctx.categories, subcategories]);
    
    const subtitle = "Set a Price";
    const tips_header = "How to Price Smart:";
    const tips_subtitle = "Do your Research";
    const tips_text = "Look at other products on the market that are similar and see what kind of quality vs. pricing they have. You may decide to be competitive or stand firm with premium quality! It’s up to you!";

    function submit() {
        try_create_listing(user_channel, session_token, set_loading, ctx, dispatch, ctx_dispatch, history);
    };
    
    return (
        <div className="create-listing">
            <ScrollToTop />
            <ListingHeader props={props} />
            <TitleAndSubtitle subtitle={subtitle} step={step} />
            <input
                type="text"
                name="product-price"
                placeholder="Ex: “95.5“ "
                value={price}
                className="large-text create-listing__price-input create-listing_input-with-placeholder"
                onChange={(event) => dispatch({type: "set", field: "price", value: event.target.value})}
            />
            <hr />
            <label>Main Category (required)</label>
            <div className="select-dropdown-wrapper">
                <select
                    name="categories"
                    id="categories"
                    className="select-dropdown mx-auto"
                    value={category ? category.slug : ""}
                    onChange={(event) => dispatch({type: "set_primary_category", value: get_category(event.target.value, categories)})}
                >
                    <option>Choose One</option>
                    {(categories || []).map(({title, slug}) => <option key={`category-${slug}`} value={slug}>{title}</option> )}
                </select>
            </div>
            <br/>
            <label>Sub Category (optional)</label>
            <div className="select-dropdown-wrapper">
                <select
                    name="subcategories"
                    id="subcategories"
                    className="select-dropdown mx-auto"
                    value={subcategory ? subcategory.slug : ""}
                    onChange={(event) => dispatch({type: "set_subcategory", value: get_subcategory(event.target.value, category, subcategories)})}
                >
                    <option>Choose One</option>
                    {(subcategories_select || []).map(({title, slug, category_id}) => <option key={`subcategory-${slug}-${category_id}`} value={slug}>{title}</option> )}
                </select>
            </div>
            <TipsWithOneSection tips_header={tips_header} tips_subtitle={tips_subtitle} tips_text={tips_text} />
            <FooterAndButtons step={step} set_step={set_step} publish callback={submit}/>
        </div>
    )
}

function ListingHeader({props}) {
  return (
    <div className="create-listing__header">
      <div className="create-listing__avatar">
        <ProfileAvatar user={props.user} />
      </div>
      <h1 className="create-listing__company-name">{props.user.company_name}</h1>
    </div>
  )
}

function BackButton({firstStepBool, step, set_step}) {
  if(firstStepBool) {
    return <Link className="btn primary-gradient create-listing__nav-button" to="/account/account">Back</Link>;
  }
  return <button onClick={() => goBack(step, set_step)} className="btn primary-gradient create-listing__nav-button">Back</button>;
}

function NextButton({publish, step, set_step, callback}) {
    if (publish) {
        return <button
                   className="btn primary-gradient create-listing__nav-button"
                   onClick={(_) => callback()}
               >Publish!</button>;
    }
    return <button onClick={() => set_step(step + 1)} className="btn primary-gradient create-listing__nav-button">Next</button>;
}
        
function FooterAndButtons({step, set_step, publish, callback}) {
  const firstStepBool = step === 1 ? true : false;
  return (
    <div className="create-listing__footer">
      <BackButton firstStepBool={firstStepBool} step={step} set_step={set_step} />
      <NextButton publish={publish} step={step} set_step={set_step} callback={callback} />
  </div>
  )
}

function goBack(step, set_step) {
  set_step(step - 1);
}

function TitleAndSubtitle({step, subtitle}) {
  return (
    <React.Fragment>
      <h2 className="create-listing__title">Create A New Listing</h2>
      <h3 className="create-listing__subtitle"><span className="create-listing__step-number">{step}</span> {subtitle}</h3>
    </React.Fragment>
  )
}

function TipsWithTwoSections({
  header,
  section_one_title,
  section_one_text,
  section_two_title,
  section_two_text
}) {
  return (
    <React.Fragment>
      <h4 className="create-listing__tips__header">{header}</h4>
      <div className="create-listing__tips">
        <div>
          <h5 className="create-listing__tips__subheader">{section_one_title}</h5>
          <p className="create-listing__tips__text">{section_one_text}</p>
        </div>
        <div>
          <h5 className="create-listing__tips__subheader">{section_two_title}</h5>
          <p className="create-listing__tips__text">{section_two_text}</p>
        </div>
      </div>
    </React.Fragment>
  )
}

function TipsWithOneSection({
  tips_header,
  tips_subtitle,
  tips_text
}) {
  return (
    <React.Fragment>
      <h4 className="create-listing__tips__header">{tips_header}</h4>
      <div className="create-listing__tips">
        <div>
          <h5 className="create-listing__tips__subheader">{tips_subtitle}</h5>
          <p className="create-listing__tips__text">{tips_text}</p>
        </div>
      </div>
    </React.Fragment>
  )
}

function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

function try_create_listing(user_channel, session_token, set_loading, ctx, dispatch, ctx_dispatch, history, retries) {
    retries =  retries || 0;
    var error;
    set_loading(true);
    if (user_channel.state === "joined") {

        if (!(ctx.main_image instanceof File)) {
            error = create_error_element({msg: "You need to choose an image", field: "Product Image"});
            ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
            return false;
        }
        
        let data = valid_fields.reduce((acc, key) => {
            acc[key] = ctx[key];
            return acc;
        }, {});

        const main_image = ctx.main_image;
        
        user_channel.push("create_product", data, 10000)
                    .receive("ok", ({product}) => {
                        dispatch({type: "set_product", value: product});
                        maybe_upload_image("main_image", main_image, ctx, dispatch, session_token, set_loading)
                            .then((payload) => {
                                if (payload.success) {
                                    let notice = create_notice_element({msg: "Product Created!"});
                                    ctx_dispatch({type: "show_notice", value: notice, dispatcher: ctx_dispatch});
                                    set_loading(false);
                                    history.push("/account/listings");
                                    
                                } else if (payload.errors) {
                                    payload.errors.forEach((e) => {
                                        e = create_error_element(e);
                                        ctx_dispatch({type: "show_error", value: e, dispatcher: ctx_dispatch});
                                    });
                                }
                            });
                    })
                    .receive("error", ({error}) => {
                        if (error) {
                            error.forEach((e) => {
                                e = create_error_element(e);
                                ctx_dispatch({type: "show_error", value: e, dispatcher: ctx_dispatch});
                            });
                        }
                        set_loading(false);
                    })
                    .receive("timeout", () => {
                        error = create_error_element({msg: "Unable to contact server, check your internet connection and refresh this page"});
                        ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
                        set_loading(false);
                    });
    } else if (retries < 25) {
        setTimeout(() => {
            try_create_listing(user_channel, session_token, set_loading, ctx, dispatch, ctx_dispatch, retries + 1);
        }, 250);
    }
};

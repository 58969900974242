import React, { useState, useEffect } from "react";

// STYLING
import "./profile_favorites.scss";

// UTILITY
import { useCtxProvider } from "../../context/context.js";

import { PopularItems } from "../../just_for_mock/items.js";

// COMPONENTS
import Spinner        from "../../components/global/Spinner.js";
import ItemCard       from "../../components/sections/feeds/item_card.js";



export default function ProfileFavorites({ user }) {

    const [loading, set_loading] = useState(true);
    const [favorites, set_favorites] = useState([]);

    const { ctx_dispatch, session_token } = useCtxProvider();

    useEffect(() => {
        get_user_favorites(user, favorites, set_favorites, set_loading);
    }, []);
    

    function unfavorite_callback(id) {
        console.log("unfavorite", id);
    };

    return (
        <div className="profile-self-content">
            <div className="profile-view-title-row">
                <h3>Favorites <span>Items you've saved for later or to share with friends</span></h3>
            </div>
            <div className="profile-view-favorites-container">
                {
                    loading ?
                    <Spinner classes="helper-card-dimensions mx-auto" /> :
                    favorites.map(({seller, picture, product_name, product_id, display_price}) => {
                        return <ItemCard
                                   product={{
                                       seller: seller,
                                       main_image: picture,
                                       title: product_name,
                                       id: product_id,
                                       price: display_price
                                   }}
                                   key={`${product_id}_${Math.random()}`}
                                   card_type="favorite"
                                   unfavorite_callback={unfavorite_callback}
                        />
                    })
                }
            </div>
        </div>
    );
};

function get_user_favorites(user, favorites, set_favorites, set_loading) {
    if (user.id) {
        
        set_favorites(PopularItems);
    } else {
        set_favorites([]);
    }
    set_loading(false);
};

import React from "react";

// STYLING

// UTILITY

// COMPONENTS

export default function AdminDashboard(_) {

    return (
        <div className="admin-cards fx-row justify-content-center align-items-center pt-6">
            <p>Navigate to the Users tab for management accounts.</p>
        </div>
    );
};

import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

// STYLES
import "./banner.scss";

// UTILITY
import { image_url } from "../../../env/env.js";
import { useCtxProvider } from "../../../context/context.js";

// COMPONENTS

export default function Banner({ title, subtitle, linkText, link, background }) {

    const { categories } = useCtxProvider();

    const [display_categories, set_categories] = useState(categories);

    useEffect(() => set_categories(categories), [categories]);

    return (
        <div className="banner" style={{backgroundImage: `url(${background})`}}>
            <div className="banner-title-section" style={{ flexDirection: 'column' }}>
                <h4 className="banner-title">
                    {title}
                    {subtitle ? <span className="banner-subtitle"> {subtitle}</span> : ""}
                </h4>
                <br />
                <Link className="banner-action btn btn-white" to={ link }>
                  { linkText } <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.42 12.7"><g><g><polyline className="arrow-primary" points="6.17 0.71 11.81 6.35 6.17 11.99"/><polyline className="arrow-primary" points="14.37 0.71 20.01 6.35 14.37 11.99"/><line className="arrow-primary" y1="6.35" x2="19.34" y2="6.35"/></g></g></svg>
                </Link>
            </div>
            <div className="banner-icons-section">
                <ul className="banner-categories-list">
                    {
                        display_categories ? display_categories.map(({title, slug}) => {
                            return (
                                <Link to={`/categories/${slug}`} key={slug}>
                                    <li className="banner-icon">
                                        <div className="banner-icon-item">
                                            <img alt={title} src={image_url(`categories/icon-${slug}.png`)} />
                                        </div>
                                        <p>{title}</p>
                                    </li>
                                </Link>
                            );
                        }) :
                        ""
                    }
                </ul>
            </div>
        </div>
    );
};

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// STYLES

// UTILITY
import { BACKEND_URL } from "../../../env/env.js";

import { create_error_element, response_error } from "../../../js-utilities/error_arrays.js";
import { useCtxProvider } from "../../../context/context.js";

import { phone_regex, check_requirements, requirements } from "../shared_account_details.js";
import { accept_file } from "./shared_functions.js";

// COMPONENTS
import AvatarUploader from "../../../components/upload/avatar-uploader.js";
const placeholder_image = "main/empty-avatar.jpg";

export const required_fields_from_previous = ["type", "plan"];

const axios = require("axios");

export default function Step3Seller({ dispatch, state }) {

    const [loading, set_loading] = useState(false);

    const { ctx_dispatch } = useCtxProvider();

    useEffect(() => {

        let valid = required_fields_from_previous.reduce((acc, field) => {
            return acc && state[field] ? true : false;
        }, true);

        if (valid) {
            dispatch({type: "set_step", step: 3, account_type: "seller"});
        } else {
            dispatch({type: "reset"});
        }

    }, [dispatch]);

    function handle_submit(event) {
        event.preventDefault();
        set_loading(true);

        valid_submission(state, dispatch, ctx_dispatch, set_loading);
    };

    function maybe_accept_file(accepted_files) {
        accept_file(accepted_files, 600)
          .then(({blob, file}) => {
              dispatch({type: "set_picture", field: "profile_image", value: file});
          })
          .catch((error) => {
              if (error.error === "min_size") {
                  error = create_error_element({msg: `The image you chose needs to have a minimum of ${error.minimum}px ${error.side}.`, ttl: 7500, field: "Profile Image"});
              } else {
                  error = create_error_element({msg: `The image you chose seems to be invalid. Please choose a valid JPG or PNG file.`, ttl: 7500, field: "Profile Image"});
                  ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
              }
          });
    };

    return (
      <div className="signup-container-step bg-white">
          <div className="signup-step-title">
              <h2><span>Step 3</span> Tell us about you.</h2>
          </div>
          <form className="signup-container-form" onSubmit={handle_submit}>
              <div className="signup-optional-buyer">
                  <div className="signup-avatar-upload-section">
                      <AvatarUploader upload_callback={maybe_accept_file} file={state.profile_image} multiple={false} placeholder={placeholder_image}/>
                      <p className="signup-label mt-1">User Photo</p>
                      <p className="signup-note mt-1">Minimum size 300px by 300px</p>
                  </div>
                  <div className="signup-optional-fields mb-4">
                      <input
                        type="text"
                        id="given-name"
                        name="given-name"
                        className="text-center mx-auto mt-5"
                        style={{width: "479px", maxWidth: "90%"}}
                        autoFocus={true}
                        autoComplete="given-name"
                        value={state.first_name}
                        onChange={(event) => dispatch({type: "set_field", field: "first_name", value: event.target.value})}
                        placeholder="First Name"
                        required
                        disabled={loading}
                      />
                      <input
                        type="text"
                        id="family-name"
                        name="family-name"
                        className="text-center mx-auto mt-5"
                        style={{width: "479px", maxWidth: "90%"}}
                        autoComplete="family-name"
                        value={state.last_name}
                        onChange={(event) => dispatch({type: "set_field", field: "last_name", value: event.target.value})}
                        placeholder="Last Name"
                        required
                        disabled={loading}
                      />
                      <input
                        type="text"
                        id="email"
                        name="email"
                        className="text-center mx-auto mt-5"
                        autoComplete="email"
                        value={state.email}
                        onChange={(event) => dispatch({type: "set_field", field: "email", value: event.target.value})}
                        pattern=".+?[@].+?"
                        title="Your email should contain at least a @"
                        placeholder="Email"
                        required
                        disabled={loading}
                      />
                      <input
                        type="password"
                        id="password"
                        name="password"
                        className="text-center mx-auto mt-5"
                        value={state.password}
                        onChange={(event) => dispatch({type: "set_field", field: "password", value: event.target.value})}
                        required
                        disabled={loading}
                        placeholder="Password"
                      />
                      <input
                        type="tel"
                        id="telephone"
                        name="telephone"
                        className="text-center mx-auto mt-5"
                        style={{width: "278px", maxWidth: "70%"}}
                        autoComplete="tel"
                        value={state.phone_number}
                        onChange={(event) => dispatch({type: "set_field", field: "phone_number", value: event.target.value})}
                        placeholder="Mobile Phone Number"
                        pattern="^[0-9]{3}[-\s]?[0-9]{3}[-\s]?[0-9]{4}$"
                        title="Valid 10 digit USA number with or without spaces or hyphens, e.g.: XXX XXX XXXX"
                        required
                        disabled={loading}
                      />
                  </div>
                  <div className="fx-column justify-content-center align-items-center mt-4">
                      <label style={{fontSize: "14px"}}>
                          I confirm I've read and agree to Simply Made Local's <Link to="/seller-agreement" target="_blank" className="color-primary">Terms of Service</Link> and <Link to="/privacy-policy" target="_blank" className="color-primary">Privacy Policy</Link>:</label>
                      <input
                        name="tos_pp"
                        type="checkbox"
                        className="mx-auto"
                        checked={state.tos_pp}
                        required
                        onChange={(event) => dispatch({type: "set_field", field: "tos_pp", value: event.target.checked})}
                        disabled={loading}
                      />
                  </div>
              </div>
              <div className="fx-row justify-content-end align-items-center">
                  <hr className="mt-5" />
                  <button type="submit" className="btn btn-size-large btn-len-normal primary-gradient mr-5" disabled={loading}>Next</button>
              </div>
          </form>
      </div>
    );
};


function valid_submission(state, dispatch, ctx_dispatch, set_loading) {
    let { phone_number, profile_image } = state;

    let valid_phone_number = phone_regex.test(phone_number);

    if (!valid_phone_number) {
        let error = create_error_element({msg: `Expected a valid USA phone number, in the form of XXX-XXX-XXXX (with or without hyphens or spaces), got ${phone_number}`, ttl: 7500, field: "Phone Number"});
        ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
        set_loading(false);
        return false;
    }

    if (!profile_image) {
        let error = create_error_element({msg: `You need to pick an image for your profile`, ttl: 7500, field: "Profile Image"});
        ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
        set_loading(false);
        return false;
    }

    let [valid, errors] = check_requirements(requirements().required_fields.seller, state, false);

    if (valid) {
        axios({
            method: "POST",
            url: `${BACKEND_URL()}/api/v1/verifications/seller-required`,
            data: {phone_number: state.phone_number, email: state.email}
        })
          .then(({data}) => {
              if (data.success) {
                  dispatch({type: "set_step", step: "verification", account_type: "seller"});
              } else {
                  data.errors.map((error) => {
                      error = response_error(error);
                      ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
                      return null;
                  });
                  set_loading(false);
              }

          });


    } else {
        errors.forEach((error) => {
            ctx_dispatch({type: "show_error", value: error, dispatcher: ctx_dispatch});
            set_loading(false);
        });
    }
};

import React, { useState, useEffect } from "react";

// STYLING
import "./option_section.scss";

// UTILITY
import { useCtxProvider } from "../../../../context/context.js";

// COMPONENTS
import DotsButton from "../../../../components/svg_elements/DotsButton.js";

export default function OptionSection({ section, options, index, callback, sections_length }) {

    const [dropdown_opened, set_dropdown_opened] = useState(false);
    const [prevent_closing, set_prevent_closing] = useState(false);
    const [is_first, set_is_first] = useState(index === 0);
    const [is_last, set_is_last] = useState(sections_length === (index + 1));
    

    const { click } = useCtxProvider();


    function open_option_dropdown(event) {
        set_prevent_closing(true);
        
        set_dropdown_opened(!dropdown_opened);
    };

    useEffect(() => {
        if (!prevent_closing) {
            set_dropdown_opened(false);
        } else {
            set_prevent_closing(false);
        }
    }, [click]);

    useEffect(() => {
        set_is_first(index === 0);
        set_is_last(sections_length === (index + 1));
    }, [index, sections_length]);

    return (
        <div className="listing__option__section">
            <div className="listing__option__headline">
                <p>Options Section #{index + 1}</p>
                <div className="listing__option__headline__actions">
                    <DotsButton callback={open_option_dropdown} />
                    {
                        dropdown_opened ?
                        <div className="listing__option__dropdown">
                            
                            <button
                                onClick={(event) => callback("move_section", -1)}
                                disabled={is_first}
                            >Move Up</button>
                            
                            <button
                                onClick={(event) => callback("move_section", 1)}
                                disabled={is_last}
                            >Move Down</button>
                            
                            <button
                                onClick={(event) => callback("delete_section", null)}
                            >Delete</button>
                        </div> :
                        ""
                    }
                </div>
            </div>
            <input
                type="text"
                name="option-section-title"
                className="option-section-title"
                value={section}
                onChange={(event) => callback("section", event.target.value) }
            />
            <div className="listing__option_section__options">
                {options.map(({ title }, o_index) => {
                    return (
                        <div className="listing__option__section__option" key={`option-${o_index}`}>
                            <input
                                type="text"
                                name="option-title"
                                className="option-section-title"
                                value={title}
                                onChange={(event) => callback("option_edit", [o_index, event.target.value])}
                            />
                            <button className="input-clear" onClick={(_) => callback("option_delete", o_index)} />
                        </div>
                    );
                })}
                <div className="listing__option__section__option">
                    <button type="button" className="btn primary-gradient" onClick={(_) => callback("option", "create")}>Add Option</button>
                </div>
            </div>
        </div>
    );
};

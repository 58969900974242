import { BACKEND_URL } from "../env/env.js";

const axios = require("axios");

export function verify(token) {
    return axios({
        method: "POST",
        url: `${BACKEND_URL()}/api/v1/verifications/session`,
        data: {token: token}
    });
};

export function categories() {
    return axios({
        method: "GET",
        url: `${BACKEND_URL()}/api/v1/categories`
    });
};

export function request_with_token(request, token) {
    let headers = request.headers ? request.headers : {};
    
    return axios({
        ...request,
        headers: {
            ...headers,
            authorization: `Bearer ${token}`
        }
    });
};

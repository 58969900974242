import React from "react";
import { Route, Switch } from "react-router-dom";

// STYLING
import "./index.scss";

// UTILITY

// COMPONENTS
import AdminLayout from "../../layouts/AdminLayout.js";
import AdminSignin from "./Signin.js";
import AdminDashboard   from "./Dashboard.js";
import AdminUsers  from "./Users.js";
import AdminUserView from "./users/UserView.js";
import AdminSettings from "./Settings.js";

export default function AdminView(_) {

    return (
        <AdminLayout>
            <Switch>
                <Route path="/admin/user/:id" component={AdminUserView} />
                <Route path="/admin/signin" component={AdminSignin} />
                <Route path="/admin/dashboard" component={AdminDashboard} />
                <Route path="/admin/settings" component={AdminSettings} />
                <Route path="/admin/users" component={AdminUsers} /> 
            </Switch>
        </AdminLayout>
    )
};

import React from "react";
import { Route, Switch } from "react-router-dom";

// STYLING
import "./index.scss";

// UTILITY

// COMPONENTS
import DefaultLayout from "../../layouts/default.js";
import EventsLanding from "./EventsLanding.js";
import EditEvent     from "./EditEvent.js";
import ShowEvent     from "./ShowEvent.js";


export default function Events(_) {
    
    return (
        <DefaultLayout>
            <Switch>
                <Route path="/events/create" component={EditEvent} />
                <Route path="/events/edit/:edit_id" component={EditEvent} />
                <Route path="/events/:id" component={ShowEvent} />
                <Route path="/events" component={EventsLanding} />
            </Switch>
        </DefaultLayout>
    );
};

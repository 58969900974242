import React from "react";

// STYLES
import "./index.scss";

// UTILITY

// COMPONENTS
import DefaultLayout from "../../layouts/default.js";

export default function About(_props) {

    return (
        <DefaultLayout>
            <div className="legal-content">
            <h1>The Simply Team</h1>
            <p>&nbsp;</p>
            <p>We are the dream team behind Simply Made Local! We're thrilled to bring you the future of handmade eCommerce with an authentic local spin. The three of us co-founders have been crafters, makers, and creators our entire lives. We love sharing our art, and each run our own crafting side-businesses. As small business owners, we saw the need for a better online marketplace for local crafters, and Simply Made Local was born. Thank you for supporting Simply Made Local and the sustainability of the handmade industry, as a whole.</p>
            <p>&nbsp;</p>
            <h3>Abby Jacobs</h3>
            <h3>Chief Executive Officer</h3>
            <div class="profile-image">
                <img  className="about__headshot" alt="Abby Jacobs" src={"/AbbyHeadshot.jpg"} />
            </div>
            <p>Co-founder of Simply Made Local, Abby Jacobs is a multitasker extraordinaire, balancing motherhood, business, and crafting. In 2019, Abby was inspired to trade her role as a nurse to become a full-time entrepreneur. She co-founded Simply Made Local as a way to improve the small business crafting market as it stands today. She believes that art is one's connection to their most authentic self. Because of that, Abby wants to create a platform that will provide a way for small business crafters to collaborate and grow their businesses successfully while helping their local market thrive.</p>
            <p>&nbsp;</p>
            <h3>Ryan French</h3>
            <div class="profile-image">
                <img  className="about__headshot" alt="Ryan French" src={"/RyanHeadshot.jpg"} />
            </div>
            <h3>Chief Technology Officer &amp; Director of Advertising</h3>
            <p>Avid crafter, notorious DIYer, and lover of all things sparkly and colorful is co-founder Ryan French. With a life-long passion for crafting that was passed down from her grandmothers, Ryan brings a wealth of experience when it comes to creating. A registered nurse with a background in IT while serving in the Navy, Ryan is an asset to the innovation of Simply Made Local. Supported by her daughters and husband, Ryan is determined and committed to creating an online platform that will bring your local craft marketplace home.</p>
            <p>&nbsp;</p>
            <h3>Taylor Bedetti</h3>
            <div class="profile-image">
                <img  className="about__headshot" alt="Taylor Bedetti" src={"/TaylorHeadshot.jpg"} />
            </div>
            <h3>Chief Operating Officer &amp; Director of Marketing</h3>
            <p>As an artist and crafter at an early age, co-founder Taylor Bedetti changed course and followed in her mother's footsteps to become a Registered Nurse. However, crafting and design have always been her first love. Taylor always felt compelled to be an advocate for others and dreamed of creating something significant. At Simply Made Local, Taylor uses her artistic vision to oversee daily operations and heads all marketing campaigns. Taylor uses her skills, both artistically and professionally, to help give crafters, like herself, more opportunities for success and growth through networking.</p>
            <p>&nbsp;</p>
            <h3>Cari Shagena</h3>
            <div class="profile-image">
                <img  className="about__headshot" alt="Cari Shagena" src={"/CariHeadshot.jpg"} />
            </div>
            <h3>Director of Social Media &amp; Executive Editor</h3>
            <p>A true Jill of all trades, Cari Shagena excels as a featured content writer for Simply Made Local. Joining the company in July of 2019, her social media savviness proved an asset by promoting the brand and services provided. Balancing business and motherhood, Cari uses her talent for creative writing, and meticulous editing skills to give Simply Made Local's expansive blog the same attention to detail as given to crafting. Cari's practical knowledge continues to have an impact on Simply Made Local's success and dedication to the sustainability of the handmade industry.</p>
            <p>&nbsp;</p>
            </div>
        </DefaultLayout>
    );
};

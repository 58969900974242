import React from "react";

// STYLING
import "./dots_button.scss";

export default function DotsButton({ callback }) {
    
    return (
        <button className="dots-button" onClick={(event) => callback(event) }>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.52 5.72"><g><g><circle cx="2.86" cy="2.86" r="2.86"/><circle cx="12.26" cy="2.86" r="2.86"/><circle cx="21.66" cy="2.86" r="2.86"/></g></g></svg>
        </button>
    );
};

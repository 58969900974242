import React, { Component } from "react";
import axios from "axios";
import moment from "moment";

// STYLES
import "./index.scss";

// UTILITY
import { parseString } from "../../js-utilities/parse-codes.js";

// COMPONENTS
import DefaultLayout from "../../layouts/default.js";
import Spinner       from "../../components/global/Spinner.js";

export default class BlogShow extends Component {
  constructor(props) {
    super(props);

    this.state = { blog: [], loading: true }
  }

  componentDidMount() {
    const { id } = this.props.match.params;

    axios.get(`https://simplyshopsmall.com/wp-json/wp/v2/posts/${id}`).then(res => {
      this.setState({ blog: res.data, loading: false });
    })
  }

  render() {
    const { blog, loading } = this.state;

    return (
        <DefaultLayout>
          {
            loading ?
            <Spinner classes="spinner-full" /> :
            <div className="blog">
              <h1 className="blog-title">{ parseString(blog.title.rendered) }</h1>
              <p className="blog-meta">{ moment( blog.date ).format('LL') }</p>
                  <div className="blog-featured-image"><img alt={parseString(blog.title.rendered)} src={ blog.jetpack_featured_media_url }/></div>
              <div className="blog-content" dangerouslySetInnerHTML={{ __html: blog.content.rendered }}></div>
            </div>
          }
        </DefaultLayout>
    );
  }
};

import React from "react";

// STYLING
import "./profile_settings.scss";

// UTILITY

// COMPONENTS
import ProfileCard        from "./profile_cards/ProfileCard.js";

export default function ProfileSettings({ user }) {
    return (
        <div className="profile-self-content">
            <div className="profile-view-title-row">
                <h3>Settings</h3>
            </div>
            <div className="section-divider full-divider mt-2" style={{height: "40px"}} />
            <div className="profile-view-settings-container">
                {/*<ProfileCard
                    label="Local Area"
                    title="Fayeteville"
                    subtitle="Arkansas"
                    btn_text="Change"
                    btn_callback={change_location}
                />*/}
                <ProfileCard
                    label="Your Account Type"
                    title={user.type}
                    btn_text={user.type === "buyer" ? "Become a Maker" : "Manage your Subscription"}
                    btn_callback="/upgrade"
                />
            </div>
        </div>
    );
};

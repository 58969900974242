import React, { useState, useEffect } from "react";

// STYLING
import "./profile_avatar.scss";

// UTILITY
import { image_url } from "../../../env/env.js";

// COMPONENTS
import Spinner from "../../../components/global/Spinner.js";

const placeholder_image = "main/empty-avatar.jpg";

export default function ProfileAvatar({ user, customClassName }) {
    
    let [image_field, set_image_field] = useState(define_image_field({...user, type: "seller"}));
    let [image, set_image] = useState(define_image(image_field, user, customClassName));

    useEffect(() => {
        set_image_field(define_image_field({...user, type: "seller"}));
    }, [user]);

    useEffect(() => {
        set_image(define_image(image_field, user, customClassName));
    }, [image_field, user]);
    
    return <div className="profile-avatar">{image}</div>
};

function define_image_field(user) {
    switch(user && user.type) {
    case "seller":
        return user.company_logo ? "company_logo" : (user.profile_image ? "profile_image" : "image");
    default:
        return "profile_image";
    }
};

function define_image(image_field, user, customClassName) {
    if (user) {
        switch(user[image_field]) {
            case "uploading":
                return image_uploading(image_field);
            case null:
            case undefined:
            case "failed":
                return image_empty_placeholder(image_field);
            default:
                return image_existing(image_field, user[image_field], customClassName);
        };
    } else {
        return image_empty_placeholder(image_field);
    }
};

function image_uploading(image_field) {
    return <Spinner />
};

function image_empty_placeholder(image_field) {
    switch(image_field) {
        case "profile_image":
            let url = image_url(placeholder_image)
            return <div className="profile-image-element" style={{backgroundImage: `url(${url})`}} />
        default:
            return "";
    };
};

function image_existing(image_field, image, customClassName) {
    const className = customClassName ? customClassName : "profile-image-element"
    return <div className={className} style={{backgroundImage: `url(${image_url(image)}`}} />
}

import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

// STYLING
import "./message_element.scss";

// UTILITY
import { useCtxProvider } from "../../../context/context.js";

// COMPONENTS
import MessageElementDropdown from "./MessageElementDropdown.js";

function MessageElement({ history }) {

    const { mobile, unread_messages, click } = useCtxProvider();

    const [notifications_count, set_notifications_count] = useState(unread_messages.total);
    const [dropdown_opened, set_dropdown_opened] = useState(false);
    const [prevent_closing, set_prevent_closing] = useState(false);

    useEffect(() => set_notifications_count(unread_messages.total), [unread_messages.total]);

    useEffect(() => {
        set_dropdown_opened(false)
    }, [history.location]);

    
    useEffect(() => {
        if (!prevent_closing) {
            set_dropdown_opened(false);
        } else {
            set_prevent_closing(false);
        }
    }, [click]);


    function decide_click() {
        if (!mobile) {
            set_prevent_closing(true);
            set_dropdown_opened(!dropdown_opened);
        } else {
            history.push("/account/messages");
        }
    }
    
    return (
        <div className="navigation-session-messages">
            <button
                type="button"
                className="svg-messages-button"
                style={{width: "30px"}}
                onClick={(event) => decide_click()}
            >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.16 21.4"><g><g><path d="M31.41,20.14V2.71a2,2,0,0,0-2-2H2.71a2,2,0,0,0-2,2V20.14C.75,20.81,31.41,20.83,31.41,20.14Z"/><line x1="31.13" y1="18.8" x2="21.34" y2="11.68"/><line x1="1.18" y1="18.8" x2="10.86" y2="11.75"/><path d="M1.59,5l13.2,9.47a2.21,2.21,0,0,0,2.53,0L30.51,5.05a1.94,1.94,0,0,0,.9-1.65V2.94a2,2,0,0,0-2-2H2.71a2,2,0,0,0-2,2V3.4A1.94,1.94,0,0,0,1.59,5Z"/></g></g></svg>
                {
                    (notifications_count > 0) ?
                    <div className="unread-messages-icon">
                        {notifications_count}
                    </div> :
                    ""
                }
            </button>
            {
                !mobile && dropdown_opened ?
                <MessageElementDropdown /> :
                ""
            }
        </div>
    );
};

export default withRouter(MessageElement);

import React, { Component } from "react";
import AliceCarousel from 'react-alice-carousel'

// STYLES
import "react-alice-carousel/lib/alice-carousel.css";

// UTILITY
import { image_url } from "../../env/env.js";

export default class ImageCarousel extends Component {
    
    constructor(props) {
        super(props);

        const { product } = props;
        const extra_images = product.images.map(({path}) => path);
        const images = [product.main_image, ...extra_images].map((img) => image_url(img));
        const gallery_items = images.map((i) => <img
                                                   alt={`Product ${product.tile} #${i}`}
                                                   src={i} onDragStart={(e) => e.preventDefault()}
        />);
        
        this.state = {product: product, gallery_items: gallery_items, images: images};
    }
    
    render () {


        const thumb_item = (item, i) => (
            <img
                key={item}
                src={item}
                onClick={() => this.Carousel.slideTo(i)}
                alt={`Product ${this.state.product.tile} thumbnail #${i}`}
                className="product__gallery__thumbnails__item"
            />
        )

        return (
            <div className="product__gallery">
                <AliceCarousel
                    mouseTrackingEnabled
                    buttonsDisabled
                    dotsDisabled
                    items={this.state.gallery_items}
                    ref={(el) => (this.Carousel = el)}
                />
                <nav className="product__gallery__thumbnails">{this.state.images.map(thumb_item)}</nav>
            </div>
        );
    }
};

import React from "react";

import { BrowserRouter, Route, Switch } from "react-router-dom";

// STYLES
import "./styles/app.scss";

// UTILITY
import CtxProvider from "./context/context.js";

// COMPONENTS
import ScrollTop from "./components/global/ScrollTop.js";
import WideNotifications from "./components/global/WideNotifications.js";

import Landing from "./scenes/landing/index.js";

import Login from "./scenes/login/index.js";
import ForgetPassword from "./scenes/forget_password/index.js";
import ResetPassword from "./scenes/reset_password/index.js";
import Signup from "./scenes/signup/index.js";

import About from "./scenes/about/index.js";
import Contact from "./scenes/contact/index.js";
import Support from "./scenes/support/index.js";
import Faq from "./scenes/faq/index.js";
import TermsOfService from "./scenes/terms-of-service";
import PrivacyPolicy from "./scenes/privacy-policy";
import SellerAgreement from "./scenes/seller-agreement";
import shippingAndSafety from "./scenes/shipping-and-safety";
import HowItWorks from "./scenes/how-it-works/index.js";

import Category from "./scenes/category/index.js";
import Profile from "./scenes/profile/index.js";
import Upgrade from "./scenes/upgrade/index.js";
import SellerPublic from "./scenes/seller/public/index.js";
import Product from "./scenes/product";
import BlogShow from "./scenes/blog/show";
import BlogIndex from "./scenes/blog/index";

import Events from "./scenes/events/index.js";

import AdminView from "./scenes/admin/index.js";


function App() {

    return (
        <BrowserRouter>
            <ScrollTop />
            <CtxProvider>
                <WideNotifications />
                <Switch>
                    <Route path="/upgrade" component={Upgrade} />
                    <Route path="/admin" component={AdminView} />
                    <Route path="/account" component={Profile} />
                    <Route path="/categories/:category" component={Category} />
                    <Route path="/blog/:slug/:id" component={BlogShow} />
                    <Route path="/blog" component={BlogIndex} />
                    <Route path="/seller/:slug" component={SellerPublic} />
                    <Route path="/product/:slug" component={Product} />
                    <Route path="/login" component={Login} />
                    <Route path="/forget-password" component={ForgetPassword} />
                    <Route path="/resetpassword/:token" component={ResetPassword} />
                    <Route path="/signup" component={Signup} />
                    <Route path="/about" component={About} />
                    <Route path="/contact" component={Contact} />
                    <Route path="/support" component={Support} />
                    <Route path="/faqs" component={Faq} />
                    <Route path="/how-it-works" component={HowItWorks} />
                    <Route path="/events" component={Events} />

                    <Route path="/terms-of-service" component={TermsOfService} />
                    <Route path="/privacy-policy" component={PrivacyPolicy} />
                    <Route path="/seller-agreement" component={SellerAgreement} />
                    <Route path="/shipping-and-safety" component={shippingAndSafety} />

                    <Route path="/" component={Landing} />
                </Switch>
            </CtxProvider>
        </BrowserRouter>
    );
};

export default App;
